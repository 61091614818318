import React, {useEffect, useRef, useState} from 'react';
import {
  CircularProgress,
  Table, TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {withStyles} from "@material-ui/core/styles";
import {generateReport} from "../../utils/api";
import Typography from "@material-ui/core/Typography";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
});


function BeneficiaryInputStatistics(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [statistics, setStatistics] = useState('');

  const cancelRequest = useRef();

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await generateReport({
            'fromDate':'2029-01-01',
            'toDate':'2030-01-01',
            'reportType':'INPUT_REPORT'
          });
          const statisticsData = result.data;

          if (!cancelRequest.current) {
            if (statisticsData && Object.keys(statisticsData).length > 0) {
              setStatistics(statisticsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            Access denied!
          </>
      );
    }

    if (isLoading || !statistics) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <Paper variant="outlined" className={classes.paper}>
            <Typography variant="h4" color="textPrimary">
              Zambia
            </Typography>
            <br/>
            <Typography variant="h6" color="textPrimary">
              Number of people having received agricultural inputs
            </Typography>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>District</TableCell>
                    {statistics.availableGoodTypes.map(type => (
                        <>
                          <TableCell>{type.name}</TableCell>
                        </>
                    ))}
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {statistics.beneficiaryInputDistricts.map((d) => (
                  <TableRow key={d.name}>
                    <TableCell component="th" scope="row">
                      {d.name}
                    </TableCell>
                    {
                      statistics.availableGoodTypes.map(goodType => {
                        let amount = 0;

                        d.beneficiaryInputs.forEach(receivedInput => {
                          if (goodType.name === receivedInput.name) {
                            amount = receivedInput.cumAmount;
                          }
                        });

                        return <TableCell>{amount}</TableCell>
                    })}
                    <TableCell>{d.cumNumWithInput}</TableCell>
                  </TableRow>
                ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>

    );
  };

  return renderData();
}

export default withStyles(styles)(BeneficiaryInputStatistics);