import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  searchCamps, searchFbsModules, searchGizIntermediaries,
} from "../../utils/api";
import {UserContext} from "../auth/UserProvider";
import {Autocomplete} from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddFbsSessionDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [additionalInfo, setAdditionalInfo] = React.useState('');
  const [modules, setModules] = React.useState([]);
  const [intermediary, setIntermediary] = React.useState(undefined);
  const [camp, setCamp] = React.useState(undefined);
  const [dateConducted, setDateConducted] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const authState = useContext(UserContext);

  React.useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchFbsModules('');

      if (active) {
        if (response.data) {
          setOptions(response.data);
        } else {
          setOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAdditionalInfo('');
    setModules([]);
    setIntermediary(undefined);
    setCamp(undefined);
    setDateConducted(undefined);
  };

  const handleConfirm = () => {
    confirmationHandler(dateConducted, intermediary, camp, modules, additionalInfo);
    handleClose();
  };

  const handleAdditionalInfoChange = (event) => {
    setAdditionalInfo(event.target.value);
  };

  const onChangeTrainer = value => {
    setIntermediary(value);
  };

  const onChangeCamp = value => {
    setCamp(value);
  };

  const handleDateConductedChange = (event) => {
    setDateConducted(event.target.value);
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add Session
          </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
            <Tooltip title="Edit">
              <IconButton aria-label="edit"
                          onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} FBS Module Session</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the session
            </DialogContentText>
            <Autocomplete
                multiple
                disableCloseOnSelect
                id="modules-multi-select"
                options={options}
                getOptionLabel={(option) =>  `${option.name}`}
                value={modules}
                onChange={(event, value) => setModules(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Modules..."
                    />
                )}
            />
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="dateConducted"
                  label="Date of lesson"
                  type="date"
                  value={dateConducted}
                  onChange={handleDateConductedChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
            <BasicAutocomplete handleChange={onChangeTrainer} searchFct={searchGizIntermediaries} label={'Trainer'} property={'intermediary'} currentValue={intermediary} />
            <BasicAutocomplete handleChange={onChangeCamp} searchFct={searchCamps} label={'Camp'} property={'campName'} />
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="training-lesson-additional-info"
                    label="PSP"
                    type="text"
                    value={additionalInfo}
                    onChange={handleAdditionalInfoChange}
                    fullWidth
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddFbsSessionDialog)