import React, {useRef, useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import MergeIcon from "@material-ui/icons/MergeType";
import {
  getDuplicateIntermediaries, mergeIntermediaries,
} from "../../utils/api";
import {
  CircularProgress,
  List, ListItem, ListItemText,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";

const styles = () => ({
  heading: {
    marginTop: '1rem',
    marginBottom: '1rem',
  }
});

function MergeIntermediariesDialog(props) {
  const { classes,  intermediaryId, reloadItems } = props;
  const [open, setOpen] = React.useState(false);
  const [intermediaries, setIntermediaries] = React.useState([]);
  const [selectedRoot, setSelectedRoot] = React.useState(null);
  const [selectedMerge, setSelectedMerge] = React.useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    fetchData(false, intermediaryId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const cancelRequest = useRef();

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const merge = async () => {
        if (selectedRoot && selectedMerge) {
          setIsLoading(true);
          await mergeIntermediaries({ rootIntermediaryId: selectedRoot.id, toBeMergedIntermediaryId: selectedMerge.id });
          await delay(2000).then(_ => {
            reloadItems();
            setIsLoading(false);
            handleClose();
          });
        }

      };

  const fetchData =
      async (loading, intId) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getDuplicateIntermediaries(intId);
          const duplicateData = result.data;

          if (!cancelRequest.current) {
            if (duplicateData && Object.keys(duplicateData).length > 0) {
              setIntermediaries(duplicateData);
              setSelectedRoot(duplicateData.filter(i => i.id === intermediaryId)[0]);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  return (
      <div>
        <Tooltip title="Inspect">
          <IconButton aria-label="edit"
                      onClick={handleClickOpen}>
            <MergeIcon />
          </IconButton>
        </Tooltip>
        <Dialog maxWidth="lg" fullWidth className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Merge intermediaries</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={6}>
                <Typography color="inherit" variant="body1" component="div">
                  <strong>Root intermediary</strong>
                </Typography>
                <Select
                    autoFocus
                    labelId="root-select"
                    id="root-select"
                    value={selectedRoot}
                    variant="outlined"
                    className={classes.selectEmpty}
                    onChange={event => {
                      let intermediary = event.target.value;
                      setSelectedRoot(intermediary);
                      if (selectedMerge && intermediary.id === selectedMerge.id) {
                        setSelectedMerge(null);
                      }
                    }}
                    style={{width: 250}}
                >
                  { intermediaries.map(i => {
                    return <MenuItem value={i}>{`${i.person.personalData.firstName} ${i.person.personalData.lastName} (${i.id})`}</MenuItem>
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <Typography color="inherit" variant="body1" component="div">
                  <strong>Intermediary to be merged</strong>
                </Typography>
                <Select
                    autoFocus
                    labelId="root-select"
                    id="root-select"
                    value={selectedMerge}
                    variant="outlined"
                    className={classes.selectEmpty}
                    onChange={event => {
                      let intermediary = event.target.value;
                      setSelectedMerge(intermediary);
                      if (selectedRoot && intermediary.id === selectedRoot.id) {
                        setSelectedRoot(null);
                      }
                    }}
                    style={{width: 250}}
                >
                  { intermediaries.map(i => {
                    return <MenuItem value={i}>{`${i.person.personalData.firstName} ${i.person.personalData.lastName} (${i.id})`}</MenuItem>
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <List dense>
                  <ListItem>
                    <ListItemText
                        primary="Type"
                        secondary={`${selectedRoot && selectedRoot.intermediaryType ? selectedRoot.intermediaryType.nameIntermediaryType : ''}`}
                    />
                    <ListItemText
                        primary="Location"
                        secondary={`${selectedRoot && selectedRoot.ward ? selectedRoot.ward.wardName + ', ' + selectedRoot.ward.district.districtName : ''}`}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <List dense>
                  <ListItem>
                    <ListItemText
                        primary="Type"
                        secondary={`${selectedMerge && selectedMerge.intermediaryType ? selectedMerge.intermediaryType.nameIntermediaryType : ''}`}
                    />
                    <ListItemText
                        primary="Location"
                        secondary={`${selectedMerge && selectedMerge.ward ? selectedMerge.ward.wardName + ', ' + selectedMerge.ward.district.districtName : ''}`}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            { isLoading &&
              <CircularProgress color="primary" />
            }
            { !isLoading &&
              <>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button disabled={!selectedMerge || !selectedRoot} onClick={merge} color="primary">
                  Merge
                </Button>
              </>
            }
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(MergeIntermediariesDialog)