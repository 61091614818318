export function formatGender(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'FEMALE':
      return 'female';
    case 'MALE':
      return 'male';
    default:
      return '';
  }
}