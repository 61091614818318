import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createHousehold, createBeneficiary, deleteHousehold,
  getHouseholds, getHouseholdsDump, updateHousehold
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import AddHouseholdDialog from "../dialogs/AddHouseholdDialog";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/GetApp";
import FileDownload from "js-file-download";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";
import {UserContext} from "../auth/UserProvider";
import CreationSnackbar from "../dialogs/CreationSnackbar";
import debounce from "lodash.debounce";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white',
    height: '88%',
  },
  fab: {
    position: 'absolute',
    bottom: '1rem',
    right: '15rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function Households(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedHouseholds, setPaginatedHouseholds] = useState('');
  const [householdBuffer, setHouseholdBuffer] = useState('');
  const [successBarText, setSuccessBarText] = useState('');
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const authState = useContext(UserContext);

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getHouseholdsDump().then((response) => {
            FileDownload(response.data, 'households.csv');
            setIsLoadingDump(false);
          });
        } catch (error) {
          console.log(error);
        }
      };

  const addConfirmationHandler =
      async (values) => {
        await createHousehold({
          'fbNumber': values.fbNumber,
          'dateRegistered': values.registrationDate,
          'agent': values.nv,
          'addressData': {
            'village': values.village
          },
        }).then((entity) => {
          setSuccessBarText(entity.data ? `Household ${entity.data.fbNumber}` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const addBeneficiaryConfirmationHandler =
      async (values, household, gender) => {

        let body = {
          'person': {
            'dateRegistered': values.dateRegistered,
            'personalData': {
              'firstName': values.firstName,
              'lastName': values.lastName,
              'dateOfBirth': values.dateOfBirth,
              'gender': gender,
            },
            'household': household,
          },
          'status': values.status,
          'relation': values.relation,
        }

        await createBeneficiary(body).then((entity) => {
          setSuccessBarText(entity.data ? `Beneficiary ${entity.data.person.personalData.firstName} ${entity.data.person.personalData.lastName}` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, values) => {
        await updateHouseholdAndDispatch(entityId, {
          'fbNumber': values.fbNumber,
          'dateRegistered': values.registrationDate,
          'agent': values.nv,
          'addressData': {
            'village': values.village
          }
        });
        await fetchData();
      };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (householdId, deleteType = 'SOFT') => {
    await deleteHousehold(householdId, deleteType);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getHouseholds(page, size, searchTerm);
          const householdsData = result.data;

          if (!cancelRequest.current) {
            if (householdsData && Object.keys(householdsData).length > 0) {
              setPaginatedHouseholds(householdsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const updateHouseholdAndDispatch = async (householdId, fields) => {
    try {
      let buffer = {
        ...householdBuffer
      };

      setHouseholdBuffer(buffer);

      const { data: data } = await updateHousehold(
          householdId,
          fields
      );

      if (!cancelRequest.current) {
        setHouseholdBuffer(data);
      }
    } catch (error) {
      setHouseholdBuffer(householdBuffer);
    }
  };

  const handleSuccessBarState = (open) => {
    setShowSuccessBar(open);
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedHouseholds) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="FB number"
                id="household-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={paginatedHouseholds.content}
              getRowClassName={(params) => `row-${params.row.deleted === true ? 'deleted' : 'active'}`}
              columns={[
                { field: 'fbNumber', headerName: 'FB Number', width: 200 },
                { field: 'dateRegistered', headerName: 'Date Registered', flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateRegistered))
                },
                { field: 'addressData', headerName: 'Village', flex: 1,
                  valueFormatter: (params) =>
                      (params.value.village ?
                          (params.value.village.villageName + (params.value.additionalAddressLine ? ' (' + params.value.additionalAddressLine + ')' : '')) : ''), },
                { field: 'householdHead', headerName: 'Household head', flex: 1,
                  valueFormatter: (params) =>
                      (params.row.householdHead && params.row.householdHead.person ?
                          (params.row.householdHead.person.personalData.firstName + ' ' + params.row.householdHead.person.personalData.lastName) : ' ' ) },
                { field: 'agent', headerName: 'Nutr. volunteer', flex: 1,
                  valueFormatter: (params) =>
                      (params.row.agent && params.row.agent.person ?
                          (params.row.agent.person.personalData.firstName + ' ' + params.row.agent.person.personalData.lastName) : ' ' ) },
                { field: 'dateCreated', headerName: 'Date created',
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated))
                },
                {
                  field: 'actions',
                  headerName: 'Action',
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <>
                      <AddHouseholdDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                      <DataGridActionBar
                          data={params.row}
                          type={'Household'}
                          deleteHandler={removeHandler}
                          relations={[
                          ]}
                      >
                      </DataGridActionBar>
                    </>;
                  }
                }
              ]}
              density="compact"
              pagination
              pageSize={paginatedHouseholds.size}
              rowCount={paginatedHouseholds.totalElements}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
          <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
            <Fab
                hidden={true}
                size="small"
                color="primary"
                aria-label="add"
                onClick={() => fetchDump()}
                className={classes.fab}
                style={{position: 'fixed', marginBottom: '1rem'}}
            >
              <DownloadIcon/>
            </Fab>
          </div>
          <AddHouseholdDialog confirmationHandler={addConfirmationHandler} beneficiaryConfirmationHandler={addBeneficiaryConfirmationHandler} />
          <CreationSnackbar successBarOpen={showSuccessBar} successBarHandler={handleSuccessBarState} successText={successBarText} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Households);