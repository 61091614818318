import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import {
  searchSeniorLeadFarmers
} from "../../utils/api";
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import Typography from "@material-ui/core/Typography";
import {UserContext} from "../auth/UserProvider";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddUserDialog(props) {
  const { classes, confirmationHandler } = props;
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [role, setRole] = React.useState('ROLE_STANDARD');
  const [seniorLeadFarmer, setSeniorLeadFarmer] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
    setEmail('');
    setRole('ROLE_STANDARD');
    setSeniorLeadFarmer(undefined);
  };

  const handleConfirm = () => {
    confirmationHandler(name, email, role, seniorLeadFarmer);
    handleClose();
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onChangeSlf = value => {
    setSeniorLeadFarmer(value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
    if (role !== 'ROLE_SLF') {
      setSeniorLeadFarmer(undefined);
    }
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
            style={{position: 'fixed', marginBottom: '1rem'}}
        >
          <AddIcon/>
          Add user
        </Fab>
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add user</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the new user
            </DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="name"
                    label="Name"
                    variant="outlined"
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="email"
                    variant="outlined"
                    label="Email"
                    type="text"
                    value={email}
                    onChange={handleEmailChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <DescriptionList>
              <DescriptionTerm>Type</DescriptionTerm>
              <Description>
                <Select
                    labelId="role-select"
                    id="role-select"
                    value={role}
                    variant="outlined"
                    className={classes.selectEmpty}
                    onChange={handleRoleChange}
                >
                  <MenuItem value={'ROLE_SUPER'}>Super User (Admin)</MenuItem>
                  <MenuItem value={'ROLE_STANDARD'}>Standard</MenuItem>
                  <MenuItem value={'ROLE_READER'}>Read-only</MenuItem>
                  <MenuItem value={'ROLE_SLF'}>SLF (Mobile app)</MenuItem>
                  <MenuItem value={'ROLE_CEO'}>CEO (Camp Extension Officer)</MenuItem>
                  <MenuItem value={'ROLE_PSP'}>PSP (Private Service Provider)</MenuItem>
                </Select>
              </Description>
            </DescriptionList>
            <div hidden={role !== 'ROLE_SLF'}>
              <Typography style={{ marginTop: '1rem' }} variant="subtitle1">Please connect the mobile app user to a Senior Lead Farmer. This way reports submitted through the mobile app can be linked the correct person.</Typography>
              <BasicAutocomplete handleChange={onChangeSlf} searchFct={searchSeniorLeadFarmers} label={'Senior lead farmer'} property={'person'} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddUserDialog)