import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {UserContext} from "../auth/UserProvider";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddFbsCourseDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [name, setName] = React.useState('');
  const [additionalInfo, setAdditionalInfo] = React.useState('');
  const [startDate, setStartDate] = React.useState(undefined);
  const [endDate, setEndDate] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const handleClickOpen = () => {
    setName(entity && entity.name ? entity.name : '');
    setAdditionalInfo(entity && entity.additionalInfo ?
        entity.additionalInfo : '');
    setStartDate(entity && entity.dateStart ? entity.dateStart : '');
    setEndDate(entity && entity.dateEnd ? entity.dateEnd : '');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
    setAdditionalInfo('');
    setStartDate(undefined);
    setEndDate(undefined);
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(name, additionalInfo, startDate, endDate) :
        confirmationHandler(entity.id, name, additionalInfo, startDate, endDate);
    handleClose();
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleAdditionalInfoChange = (event) => {
    setAdditionalInfo(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add course
          </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} FBS course</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the course
            </DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="name"
                    label="Name"
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="additional-info"
                    label="Additional info"
                    type="text"
                    value={additionalInfo}
                    onChange={handleAdditionalInfoChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="start-date"
                  label="Start date"
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="end-date"
                  label="End date"
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddFbsCourseDialog)