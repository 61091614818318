import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  searchBeneficiaries, searchGizIntermediaries, searchTrainingLessons,
  searchTrainingSessions
} from "../../utils/api";
import {UserContext} from "../auth/UserProvider";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddTrainingSessionParticipantDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [intermediary, setIntermediary] = React.useState(null);
  const [lesson, setLesson] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [sessionOptions, setSessionOptions] = React.useState([]);
  const [personOptions, setPersonOptions] = React.useState([]);

  const authState = useContext(UserContext);

  const ParticipationSchema = Yup.object().shape({
    trainingSessions: Yup.array().min(1, 'Select at least one').required('Required'),
    persons: Yup.array().min(1, 'Select at least one').required('Required'),
  });

  const participationFormik = useFormik({
    initialValues: {
      trainingSessions: [],
      persons: [],
    },
    validationSchema: ParticipationSchema,
  });

  const handleClickOpen = async () => {
    if (entity) {
      await participationFormik.setFieldValue('trainingSessions', [ entity.trainingSession ]);
      await participationFormik.setFieldValue('persons', [ entity.person ]);
      await setFormFieldsTouched();
    }
    setOpen(true);
  };

  const setFormFieldsTouched = async () => {
    await participationFormik.setFieldTouched('trainingSessions', true);
    await participationFormik.setFieldTouched('persons', true);
  }

  const handleClose = async () => {
    setOpen(false);
    await participationFormik.resetForm();
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(participationFormik.values) :
        confirmationHandler(entity.id, participationFormik.values);
    handleClose();
  };

  const onIntermediaryChange = value => {
    setIntermediary(value);
  };

  const onLessonChange = value => {
    setLesson(value);
  };

  React.useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchTrainingSessions('', intermediary ? intermediary.id : '', lesson ? lesson.id : '');

      if (active) {
        if (response.data) {
          setSessionOptions(response.data);
        } else {
          setSessionOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [intermediary, lesson]);



  React.useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchBeneficiaries('');

      if (active) {
        if (response.data) {
          setPersonOptions(response.data);
        } else {
          setPersonOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        {action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add Participants
          </Fab>
        }
        {action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
            <Tooltip title="Edit">
              <IconButton aria-label="edit"
                          onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} Participant(s)</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of participant(s)
            </DialogContentText>
            <Typography color="inherit" variant="body1" component="div">
              To filter the lessons shown, select Trainer and Lesson beforehand
            </Typography>
            <BasicAutocomplete
                handleChange={onIntermediaryChange}
                searchFct={searchGizIntermediaries}
                label={'Trainer'}
                property={'intermediary'} />
            <BasicAutocomplete
                handleChange={onLessonChange}
                searchFct={searchTrainingLessons}
                label={'Lesson'}
                property={'name'} />
            <br/>
            { action === 'create' &&
              <>
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="sessions-multi-select"
                    options={sessionOptions}
                    getOptionLabel={(option) =>  `${option['trainingLesson'].name} (Date: ${option.dateConducted})`}
                    value={participationFormik.values.trainingSessions}
                    variant={'outlined'}
                    name="sessions"
                    onChange={(event, value) => participationFormik.setFieldValue("trainingSessions", value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Sessions..."
                            error={Boolean(participationFormik.errors.trainingSessions)}
                            helperText={participationFormik.errors.trainingSessions}
                        />
                    )}
                    onInputChange={async (event) => {
                      if (event) {
                        await searchTrainingSessions(event.target.value, intermediary ? intermediary.id : '', lesson ? lesson.id : '')
                            .then((response) => setSessionOptions(response.data));
                      }}
                    }
                />
                <br/>
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="persons-multi-select"
                    options={personOptions}
                    getOptionLabel={(option) =>  `${option.personalData.firstName} ${option.personalData.lastName} ${option.household ? '(' + option.household.fbNumber + ')' : ''}`}
                    value={participationFormik.values.persons}
                    variant={'outlined'}
                    name="persons"
                    onChange={(event, value) => participationFormik.setFieldValue("persons", value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Participants..."
                            error={Boolean(participationFormik.errors.persons)}
                            helperText={participationFormik.errors.persons}
                        />
                    )}
                    onInputChange={async (event) => {
                      if (event) {
                        await searchBeneficiaries(event.target.value)
                            .then((response) => setPersonOptions(response.data));
                      }}
                    }
                />
              </>
            }
            { action === 'edit' &&
                <>
                  <Autocomplete
                      id="sessions-single-select"
                      options={sessionOptions}
                      getOptionLabel={(option) =>  `${option['trainingLesson'].name} (Date: ${option.dateConducted})`}
                      value={participationFormik.values.trainingSessions.length > 0 ?participationFormik.values.trainingSessions[0] : undefined}
                      variant={'outlined'}
                      name="sessions"
                      onChange={(event, value) => participationFormik.setFieldValue("trainingSessions", [ value ])}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              variant="outlined"
                              label="Session"
                              error={Boolean(participationFormik.errors.trainingSessions)}
                              helperText={participationFormik.errors.trainingSessions}
                          />
                      )}
                      onInputChange={async (event) => {
                        if (event) {
                          await searchTrainingSessions(event.target.value, intermediary ? intermediary.id : '', lesson ? lesson.id : '')
                              .then((response) => setSessionOptions(response.data));
                        }}
                      }
                  />
                  <br/>
                  <Autocomplete
                      id="persons-single-select"
                      options={personOptions}
                      getOptionLabel={(option) =>  `${option.personalData.firstName} ${option.personalData.lastName} ${option.household ? '(' + option.household.fbNumber + ')' : ''}`}
                      value={participationFormik.values.persons.length > 0 ? participationFormik.values.persons[0] : undefined}
                      variant={'outlined'}
                      name="persons"
                      onChange={(event, value) => participationFormik.setFieldValue("persons", [ value ])}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              variant="outlined"
                              label="Participant"
                              error={Boolean(participationFormik.errors.persons)}
                              helperText={participationFormik.errors.persons}
                          />
                      )}
                      onInputChange={async (event) => {
                        if (event) {
                          await searchBeneficiaries(event.target.value)
                              .then((response) => setPersonOptions(response.data));
                        }}
                      }
                  />
                </>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
                disabled={!participationFormik.dirty || Object.keys(participationFormik.errors).length !== 0}
                onClick={handleConfirm} 
                color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddTrainingSessionParticipantDialog)