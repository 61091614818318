import React, {useEffect, useRef, useState} from 'react';
import { CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  getDuplicateBeneficiaries
} from "../../../utils/api";
import {formatGender} from "../../../utils/genderHelper";
import {formatTime} from "../../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});


function BeneficiaryDuplicates(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedDuplicates, setPaginatedDuplicates] = useState('');

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getDuplicateBeneficiaries(page, size);
          const fetchedData = result.data;

          if (!cancelRequest.current) {
            if (fetchedData && Object.keys(fetchedData).length > 0) {
              setPaginatedDuplicates(fetchedData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedDuplicates) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedDuplicates.content}
                getRowClassName={(params) => `row-${params.row.deleted === true ? 'deleted' : 'active'}`}
                columns={[{
                  field: "firstNames",
                  headerName: "First name(s)",
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.person.personalData.firstName)

                },
                  {
                    field: "lastName",
                    headerName: "Last name",
                    flex: 1,
                    renderCell: (params) =>
                        (params.row.person.personalData.lastName)
                  },
                  {
                    field: "gender",
                    headerName: "Gender",
                    flex: 1,
                    renderCell: (params) => (
                        formatGender(params.row.person.personalData.gender)
                    )
                  },
                  {
                    field: "dateOfBirth",
                    headerName: "Date of Birth",
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.person.personalData.dateOfBirth))
                  },
                  {
                    field: "yearOfBirth",
                    headerName: "Year of Birth",
                    flex: 1,
                    renderCell: (params) =>
                        (params.row.person.personalData.yearOfBirth)
                  },
                  { field: 'person', headerName: 'Household FB number', flex: 1,
                    valueFormatter: (params) =>
                        (params.value.household ? params.value.household.fbNumber : ' '),
                  },
                  { field: 'relation', headerName: 'Role', flex: 1.5,
                    valueFormatter: (params) =>
                        (params.value ? params.value.nameRelation : ' '),
                  },
                  { field: 'status', headerName: 'Status', flex: 1.5,
                    valueFormatter: (params) =>
                        (params.value ? params.value.nameStatus : ' '),
                  },
                  {
                    field: "dateRegistered",
                    headerName: "Date registered",
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.person.dateRegistered))
                  },
                  {
                    field: 'actions',
                    headerName: 'Actions',
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: () => {
                      return <>
                      </>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedDuplicates.size}
                rowCount={paginatedDuplicates.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(BeneficiaryDuplicates);