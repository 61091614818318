import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createGardenInput,
  getGardenInputDump,
  getGardenInputs,
  updateGardenInput
} from "../../../utils/api";
import DataGridActionBar from "../../parts/DataGridActionBar";
import {UserContext} from "../../auth/UserProvider";
import FileDownload from "js-file-download";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/GetApp";
import AddGardenInputDialog from "../../dialogs/AddGardenInputDialog";
import DumpLoadingIndicator from "../../parts/DumpLoadingIndicator";
import CreationSnackbar from "../../dialogs/CreationSnackbar";
import debounce from "lodash.debounce";
import {formatTime} from "../../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '12rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function GardenInputs(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedGardenInputs, setPaginatedGardenInputs] = useState('');
  const [inputBuffer, setInputBuffer] = useState('');
  const [successBarText, setSuccessBarText] = useState('');
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const authState = useContext(UserContext);

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getGardenInputDump().then((response) => {
            FileDownload(response.data, 'garden_inputs.csv');
            setIsLoadingDump(false);
          });
        } catch (error) {
          console.log(error);
        }
      };

  const addConfirmationHandler =
      async (values, beneficiary) => {
        let body = {
          'gardenInputType': values.gardenInputType,
          'dateDistributed': values.dateDistributed,
          'household': values.household
        };

        if (beneficiary) {
          body['beneficiary'] = beneficiary;
        }
        await createGardenInput(body).then((entity) => {
          setSuccessBarText(entity.data ? `Garden input ${entity.data.gardenInputType.nameGardenInputType} for household ${entity.data.household.fbNumber}` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, values, beneficiary) => {
        let body = {
          'gardenInputType': values.gardenInputType,
          'dateDistributed': values.dateDistributed,
          'household': values.household
        };

        if (beneficiary) {
          body['beneficiary'] = beneficiary;
        }

        await updateInputAndDispatch(entityId, body);
        await fetchData();
      };

  const updateInputAndDispatch = async (inputId, fields) => {
    try {
      let buffer = {
        ...inputBuffer
      };

      setInputBuffer(buffer);

      const { data: data } = await updateGardenInput(
          inputId,
          fields
      );

      if (!cancelRequest.current) {
        setInputBuffer(data);
      }
    } catch (error) {
      setInputBuffer(inputBuffer);
    }
  };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getGardenInputs(page, size, searchTerm);
          const gardenInputsData = result.data;

          if (!cancelRequest.current) {
            if (gardenInputsData && Object.keys(gardenInputsData).length > 0) {
              setPaginatedGardenInputs(gardenInputsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const handleSuccessBarState = (open) => {
    setShowSuccessBar(open);
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedGardenInputs) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
      <>
        <div className={classes.contentWrapper} style={{}}>
          <TextField
              label="FB number"
              id="input-filter"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleSearchTermChanged}
          />
        </div>
        <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={paginatedGardenInputs.content}
              columns={[
                { field: 'monthDistributed', headerName: 'Month distributed' },
                { field: 'yearDistributed', headerName: 'Year distributed' },
                { field: 'gardenInputType', headerName: 'Garden Input Type',
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.gardenInputType.nameGardenInputType)
                },
                {
                  field: "fbNumber",
                  headerName: "Household FB number",
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.household.fbNumber)

                },
                {
                  field: "beneficiary",
                  headerName: "Beneficiary", flex: 1,
                  renderCell: (params) => (
                      params.value ? params.value.person.personalData.firstName + ' ' + params.value.person.personalData.lastName : ''
                  )
                },
                {
                  field: "agent",
                  headerName: "Agent",
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.crsAgent ?
                              params.row.crsAgent.person.personalData.firstName +
                              ' ' + params.row.crsAgent.person.personalData.lastName
                              : ''
                      )
                },
                { field: 'dateCreated', headerName: 'Date created',
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated))
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <>
                      <AddGardenInputDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                      <DataGridActionBar data={params.row}>
                      </DataGridActionBar>
                    </>;
                  }
                }
              ]}
              density="compact"
              pagination
              pageSize={paginatedGardenInputs.size}
              rowCount={paginatedGardenInputs.totalElements}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
        </div>
        <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
          <Fab
              size="small"
              color="primary"
              hidden={!isLoadingDump}
              aria-label="add"
              onClick={() => fetchDump()}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <DownloadIcon/>
          </Fab>
        </div>
        <AddGardenInputDialog
            confirmationHandler={addConfirmationHandler} />
        <CreationSnackbar successBarOpen={showSuccessBar} successBarHandler={handleSuccessBarState} successText={successBarText} />
      </>

    );
  };

  return renderData();
}

export default withStyles(styles)(GardenInputs);