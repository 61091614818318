import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext
} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createGizIntermediary, deleteIntermediary,
  getGizIntermediaries, getGizIntermediariesDump, updateIntermediary
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatGender} from "../../utils/genderHelper";
import {formatTime} from "../../utils/timeHelper";
import AddGizIntermediaryDialog from "../dialogs/AddGizIntermediaryDialog";
import debounce from 'lodash.debounce';
import FileDownload from "js-file-download";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/GetApp";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";
import {UserContext} from "../auth/UserProvider";
import CreationSnackbar from "../dialogs/CreationSnackbar";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white',
    height: '88%',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
  fab: {
    position: 'absolute',
    bottom: '1rem',
    right: '15rem',
  },
});

function GizIntermediaries(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedIntermediaries, setPaginatedIntermediaries] = useState('');
  const [intermediaryBuffer, setIntermediaryBuffer] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [successBarText, setSuccessBarText] = useState('');
  const [showSuccessBar, setShowSuccessBar] = useState(false);

  const authState = useContext(UserContext);

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const cancelRequest = useRef();

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getGizIntermediariesDump()
          .then((response) => {
            FileDownload(response.data, 'giz_intermediaries.csv');
            setIsLoadingDump(false);
          });
        } catch (error) {
          console.log(error);
        }
      };

  const addConfirmationHandler =
      async (firstName, lastName, gender, dateOfBirth, phoneNumber, intermediaryType, ward, observer) => {
        await createGizIntermediary({
          'person': {
            'personalData': {
              'firstName': firstName,
              'lastName': lastName,
              'dateOfBirth': dateOfBirth,
              'phoneNumber': phoneNumber,
              'gender': gender,
            }
          },
          'intermediaryType': intermediaryType,
          'ward': ward,
          'observingIntermediary': observer,
        }).then((entity) => {
          setSuccessBarText(entity.data ? `Intermediary ${entity.data.person.personalData.firstName} ${entity.data.person.personalData.lastName}` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, firstName, lastName, gender, dateOfBirth, phoneNumber, intermediaryType, ward, observer) => {
        await updateIntermediaryDataAndDispatch(entityId, {
          'person': {
            'personalData': {
              'firstName': firstName,
              'lastName': lastName,
              'dateOfBirth': dateOfBirth,
              'phoneNumber': phoneNumber,
              'gender': gender,
            }
          },
          'intermediaryType': intermediaryType,
          'ward': ward,
          'observingIntermediary': observer,
        });
        await fetchData();
      };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  useEffect(() => {
    fetchData()
  }, [searchTerm]);

  const removeHandler = async (intermediaryId, deleteType = 'SOFT') => {
    await deleteIntermediary(intermediaryId, deleteType);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getGizIntermediaries(page, size, searchTerm);
          const intermediariesData = result.data;

          if (!cancelRequest.current) {
            if (intermediariesData && Object.keys(intermediariesData).length > 0) {
              setPaginatedIntermediaries(intermediariesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  const updateIntermediaryDataAndDispatch = async (intermediaryId, fields) => {
    try {
      let buffer = {
        ...intermediaryBuffer
      };

      setIntermediaryBuffer(buffer);

      const { data: data } = await updateIntermediary(
          intermediaryId,
          fields
      );

      if (!cancelRequest.current) {
        setIntermediaryBuffer(data);
      }
    } catch (error) {
      setIntermediaryBuffer(intermediaryBuffer);
    }
  };

  const handleSuccessBarState = (open) => {
    setShowSuccessBar(open);
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedIntermediaries) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
      <>
        <div className={classes.contentWrapper} style={{}}>
          <TextField
              label="Name, gender"
              id="intermediary-filter"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleSearchTermChanged}
          />
        </div>
        <DataGrid
            sortingOrder={['asc', 'desc', null]}
            scrollbarSize={15}
            columnTypes={['string', 'number', 'date', 'dateTime']}
            columnBuffer={2}
            headerHeight={56}
            localeText={'enUS'}
            rowHeight={52}
            icons={[]}
            className={classes.dataGrid}
            rows={paginatedIntermediaries.content}
            getRowClassName={(params) => `row-${params.row.deleted === true ? 'deleted' : 'active'}`}
            columns={[
              {
                field: "agentType",
                headerName: "Intermediary type",
                flex: 1,
                renderCell: (params) =>
                    (params.row.intermediaryType.nameIntermediaryType)
              },
              {
                field: "firstNames",
                headerName: "First name(s)",
                flex: 1,
                renderCell: (params) => (
                    params.row.person.personalData.firstName
                )
              },
              {
                field: "lastName",
                headerName: "Last name",
                flex: 1,
                renderCell: (params) => (
                    params.row.person.personalData.lastName
                )
              },
              {
                field: "gender",
                headerName: "Gender",
                flex: 1,
                renderCell: (params) => (
                    formatGender(params.row.person.personalData.gender)
                )
              },
              {
                field: "dateOfBirth",
                headerName: "Date of Birth",
                flex: 1,
                renderCell: (params) => (
                    formatTime(params.row.person.personalData.dateOfBirth)
                )
              },
              {
                field: "phoneNumber",
                headerName: "Phone number",
                flex: 1,
                renderCell: (params) => (
                    params.row.person.personalData.phoneNumber
                )
              },
              {
                field: "district",
                headerName: "District",
                flex: 1,
                renderCell: (params) => (
                    params.row.ward && params.row.ward.district ? params.row.ward.district.districtName : ''
                )
              },
              {
                field: "ward1",
                headerName: "Ward",
                flex: 1,
                renderCell: (params) => (
                    params.row.ward ? params.row.ward.wardName : ''
                )
              },
              {
                field: "observingIntermediary",
                headerName: "Observed by",
                flex: 1,
                renderCell: (params) => (
                    params.value ? params.value.person.personalData.firstName + ' ' + params.value.person.personalData.lastName : ''
                )
              },
              { field: 'dateCreated', headerName: 'Date created',
                flex: 1,
                renderCell: (params) =>
                    (formatTime(params.row.dateCreated))
              },
              {
                field: 'actions',
                headerName: 'Actions',
                disableClickEventBubbling: true,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  return <>
                    <AddGizIntermediaryDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                    <DataGridActionBar
                        data={params.row}
                        type={'Intermediary'}
                        deleteHandler={removeHandler}
                        relations={[
                          'Observed intermediaries',
                          'Farming groups',
                          'Lead Farmer Reports',
                          'Senior Lead Farmer Reports',
                          'Site visit',
                          'PSP visit',
                          'Good distribution',
                          'IEC material distribution',
                          'Working material distribution',
                        ]}
                    >
                    </DataGridActionBar>
                  </>;
                }
              }
            ]}
            density="compact"
            pagination
            pageSize={paginatedIntermediaries.size}
            rowCount={paginatedIntermediaries.totalElements}
            paginationMode="server"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
        />
        <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
          <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={() => fetchDump()}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <DownloadIcon/>
          </Fab>
        </div>
        <AddGizIntermediaryDialog confirmationHandler={addConfirmationHandler} />
        <CreationSnackbar successBarOpen={showSuccessBar} successBarHandler={handleSuccessBarState} successText={successBarText} />
      </>
    );
  };

  return renderData();
}

export default withStyles(styles)(GizIntermediaries);