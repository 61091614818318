import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import {searchGoodTypes} from "../../utils/api";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {UserContext} from "../auth/UserProvider";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddGoodsPurchaseDialog(props) {
  const { classes, confirmationHandler } = props;
  const [quantity, setQuantity] = React.useState(0);
  const [type, setType] = React.useState(undefined);
  const [purchaseDate, setPurchaseDate] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setQuantity(0);
    setPurchaseDate(undefined);
  };

  const handleConfirm = () => {
    confirmationHandler(quantity, type, purchaseDate);
    handleClose();
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const onChangeType = value => {
    setType(value);
  };

  const handlePurchaseDateChange = (event) => {
    setPurchaseDate(event.target.value);
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
            style={{position: 'fixed', marginBottom: '1rem'}}
        >
          <AddIcon/>
          Add Purchase
        </Fab>
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add Goods Purchase</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the goods purchase
            </DialogContentText>
            <BasicAutocomplete handleChange={onChangeType} searchFct={searchGoodTypes} label={'Type'} property={'name'} />
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="quantity"
                    label="Quantity"
                    type="number"
                    inputProps={{
                      step: ".1"
                    }}
                    value={quantity}
                    onChange={handleQuantityChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="purchaseDate"
                  label="Date of purchase"
                  type="date"
                  value={purchaseDate}
                  onChange={handlePurchaseDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddGoodsPurchaseDialog)