import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyBPgl3Uck58Gf1k7ELYfT6UY3tG8l8dZRM",
  authDomain: "fanserdev.firebaseapp.com",
  projectId: "fanserdev",
  storageBucket: "fanserdev.appspot.com",
  messagingSenderId: "21660265619",
  appId: "1:21660265619:web:3e9c0c44d917a749b5b166"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();