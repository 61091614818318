import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  searchGizIntermediaries,
  searchTrainingBlocks,
  searchVillages
} from "../../utils/api";
import {UserContext} from "../auth/UserProvider";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddTrainingSessionDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [facilitator, setFacilitator] = React.useState(undefined);
  const [trainingBlock, setTrainingBlock] = React.useState(undefined);
  const [village, setVillage] = React.useState(undefined);
  const [dateConducted, setDateConducted] = React.useState(undefined);
  const [additionalInfo, setAdditionalInfo] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const handleClickOpen = () => {
    setFacilitator(entity && entity.facilitator ? entity.facilitator : undefined);
    setTrainingBlock(entity && entity.trainingBlock ? entity.trainingBlock : undefined);
    setVillage(entity && entity.village ? entity.village : undefined);
    setDateConducted(entity && entity.dateConducted ? entity.dateConducted : undefined);
    setAdditionalInfo(entity && entity.additionalInfo ? entity.additionalInfo : '');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFacilitator(undefined);
    setTrainingBlock(undefined);
    setVillage(undefined);
    setDateConducted(undefined);
    setAdditionalInfo('');
  };

  const handleConfirm = () => {
    action === 'create' ?
      confirmationHandler(facilitator, trainingBlock, village, dateConducted, additionalInfo) :
        confirmationHandler(entity.id, facilitator, trainingBlock, village, dateConducted, additionalInfo);
    handleClose();
  };

  const handleAdditionalInfoChange = (event) => {
    setAdditionalInfo(event.target.value);
  };

  const onChangeFacilitator = value => {
    setFacilitator(value);
  };

  const onChangeTrainingBlock = value => {
    setTrainingBlock(value);
  };

  const onChangeVillage = value => {
    setVillage(value);
  };

  const handleDateConductedChange = (event) => {
    setDateConducted(event.target.value);
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add Session
          </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
            <Tooltip title="Edit">
              <IconButton aria-label="edit"
                          onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} Training Session</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the session
            </DialogContentText>
              <BasicAutocomplete handleChange={onChangeFacilitator} searchFct={searchGizIntermediaries} label={'Faciliator'} property={'intermediary'} currentValue={facilitator} /><div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="dateConducted"
                  label="Date of lesson"
                  type="date"
                  value={dateConducted}
                  onChange={handleDateConductedChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
            <BasicAutocomplete handleChange={onChangeTrainingBlock} searchFct={searchTrainingBlocks} label={'Training block'} property={'name'} currentValue={trainingBlock} />
            <BasicAutocomplete handleChange={onChangeVillage} searchFct={searchVillages} label={'Village'} property={'villageName'} currentValue={village} />
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="training-sesion-additional-info"
                    label="Additional info"
                    type="text"
                    value={additionalInfo}
                    onChange={handleAdditionalInfoChange}
                    fullWidth
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddTrainingSessionDialog)