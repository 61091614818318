import axios from 'axios';
import { auth } from "../components/auth/firebase";

let token = null;

async function setupAuthorizationHeaders() {
  token = await auth.currentUser.getIdToken();

  return {
    Authorization: `Bearer ${token}`,
  };
}

const api = axios.create({
  baseURL: process.env.REACT_APP_FANSER_BACKEND,
  headers: {
    accept: 'application/json',
  },
});

api.interceptors.request.use(
    async config => {
      const newConfig = config;
      const newHeaders = await setupAuthorizationHeaders();
      newConfig.headers = {
        ...config.headers,
        ...newHeaders,
      };
      return newConfig;
    },
    error => {
      Promise.reject(error);
    }
);

export default api;

/* --- GET requests --- */
export async function getUsers(page, size, searchTerm = '') {
  return api.get(`/users?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getAppUsers() {
  return api.get(`/app-users`);
}

export async function getStatistics() {
  return api.get('/statistics');
}

export async function getCoreLessonStatistics() {
  return api.get('/core-lesson-statistics');
}

export async function getBeneficiaryStatistics() {
  return api.get('/beneficiary-statistics');
}

export async function getIntermediaryStatistics() {
  return api.get('/intermediary-statistics');
}

export async function getSynchData() {
  return api.get(`/synch-data`);
}

export async function getTargets() {
  return api.get(`/targets`);
}

export async function getProvinces(page, size, searchTerm = '') {
  return api.get(`/provinces?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getHouseholdBeneficiaries(householdId) {
  return api.get(`/households/${householdId}/beneficiaries`);
}

export async function searchProvinces(searchTerm) {
  return api.get(`/provinces-search?searchTerm=${searchTerm}`);
}

export async function getDistricts(page, size, searchTerm = '') {
  return api.get(`/districts?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchDistricts(searchTerm) {
  return api.get(`/districts-search?searchTerm=${searchTerm}`);
}

export async function getWards(page, size, searchTerm = '') {
  return api.get(`/wards?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchWards(searchTerm, districtId = '') {
  return api.get(`/wards-search?searchTerm=${searchTerm}&districtId=${districtId}`);
}

export async function getCamps(page, size, searchTerm = '') {
  return api.get(`/camps?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchCamps(searchTerm, wardId = '') {
  return api.get(`/camps-search?searchTerm=${searchTerm}&wardId=${wardId}`);
}

export async function getVillages(page, size, searchTerm = '') {
  return api.get(`/villages?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchVillages(searchTerm, campId = '') {
  return api.get(`/villages-search?searchTerm=${searchTerm}&campId=${campId}`);
}

export async function getHouseholds(page, size, searchTerm = '') {
  return api.get(`/households?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getHouseholdsDump() {
  return api.get('/households-dump', {
    responseType: 'blob',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getBeneficiaries(page, size, searchTerm = '') {
  return api.get(`/beneficiaries?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getBeneficiariesByNv(nvId) {
  return api.get(`/beneficiaries-by-nv?nvId=${nvId}`);
}

export async function searchBeneficiaries(searchTerm) {
  return api.get(`/beneficiaries-search?searchTerm=${searchTerm}`);
}

export async function getBeneficiariesDump() {
  return api.get('/beneficiaries-dump', {
    responseType: 'stream',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getBeneficiaryDropoutsDump() {
  return api.get('/beneficiary-dropouts-dump', {
    responseType: 'blob',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getCrsIntermediaries(page, size, searchTerm = '') {
  return api.get(`/crs-intermediaries?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getCrsIntermediariesDump() {
  return api.get('/crs-intermediaries-dump', {
    responseType: 'blob',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getGizIntermediaries(page, size, searchTerm = '') {
  return api.get(`/giz-intermediaries?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getGizIntermediariesDump() {
  return api.get('/giz-intermediaries-dump', {
    responseType: 'blob',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getSlfReport(reportId) {
  return api.get(`/slf-reports/${reportId}`);
}

export async function getDeliveredReports(page, size) {
  return api.get(`/delivered-slf-reports?page=${page}&size=${size}`);
}

export async function getValidatedReports(page, size) {
  return api.get(`/validated-slf-reports?page=${page}&size=${size}`);
}

export async function getInvalidReports(page, size) {
  return api.get(`/invalid-slf-reports?page=${page}&size=${size}`);
}

export async function getCropsGrownBeforeTraining(page, size, searchTerm = '') {
  return api.get(`/crops-grown-before-training?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getCropsGrownBeforeTrainingDump() {
  return api.get('/crops-grown-before-training-dump', {
    responseType: 'stream',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getCropsGrownAfterTraining(page, size, searchTerm = '') {
  return api.get(`/crops-grown-after-training?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getCropsGrownAfterTrainingDump() {
  return api.get('/crops-grown-after-training-dump', {
    responseType: 'stream',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getKeyOutputsEstablished(page, size) {
  return api.get(`/key-outputs-established?page=${page}&size=${size}`);
}

export async function getKeyOutputsUsed(page, size, searchTerm = '') {
  return api.get(`/key-outputs-used?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getKeyOutputsUsedDump() {
  return api.get('/key-outputs-used-dump', {
    responseType: 'stream',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function searchKeyOutputs(searchTerm) {
  return api.get(`/key-outputs-search?searchTerm=${searchTerm}`);
}

export async function searchSiteVisits(searchTerm) {
  return api.get(`/site-visits-search?searchTerm=${searchTerm}`);
}

export async function getKeyOutputsAbolished(page, size) {
  return api.get(`/key-outputs-abolished?page=${page}&size=${size}`);
}

export async function getTechniquesUsed(page, size, searchTerm = '') {
  return api.get(`/techniques-used?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getTechniquesUsedDump() {
  return api.get('/techniques-used-dump', {
    responseType: 'stream',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getPracticesUsed(page, size, searchTerm = '') {
  return api.get(`/practices-used?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getPracticesUsedDump() {
  return api.get('/practices-used-dump', {
    responseType: 'stream',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getTrainers(page, size, searchTerm = '') {
  return api.get(`/trainers?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getVisits(page, size, searchTerm = '') {
  return api.get(`/visits?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getProjectOutputs(page, size, searchTerm = '') {
  return api.get(`/project-outputs?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getFarmingGroups(page, size, searchTerm = '') {
  return api.get(`/farming-groups?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getFarmingGroupsDump() {
  return api.get('/farming-groups-dump', {
    responseType: 'stream',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getFarmingGroupMembers(page, size, searchTerm = '') {
  return api.get(`/farming-group-members?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getFarmingGroupMembersDump() {
  return api.get('/farming-group-members-dump', {
    responseType: 'stream',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getBeneficiaryDropouts(page, size, searchTerm = '') {
  return api.get(`/beneficiary-dropouts?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getIntermediaryDropouts(page, size, searchTerm = '') {
  return api.get(`/intermediary-dropouts?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getAvailableFbNumber() {
  return api.get(`/available-fb-number`);
}

export async function getFarmingGroupMemberDropouts(page, size, searchTerm = '') {
  return api.get(`/farming-group-member-dropouts?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getFbsCourseMemberDropouts(page, size) {
  return api.get(`/fbs-course-member-dropouts?page=${page}&size=${size}`);
}

export async function getIecMaterialDistributions(page, size, searchTerm = '') {
  return api.get(`/iec-material-distributions?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getReferrals(page, size, searchTerm = '') {
  return api.get(`/referrals?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getGardenInputs(page, size, searchTerm = '') {
  return api.get(`/garden-inputs?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getLessons(page, size, searchTerm = '') {
  return api.get(`/lessons?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getDistributedIecMaterials(page, size, searchTerm = '') {
  return api.get(`/distributed-iec-materials?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getCrsBeneficiaryGroups(page, size, searchTerm = '') {
  return api.get(`/beneficiary-groups-crs?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getGoodCategories(page, size, searchTerm = '') {
  return api.get(`/good-categories?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchGoodCategories(searchTerm) {
  return api.get(`/good-categories-search?searchTerm=${searchTerm}`);
}

export async function searchLeavingReasons(searchTerm) {
  return api.get(`/leaving-reasons-search?searchTerm=${searchTerm}`);
}

export async function searchTrainingLessons(searchTerm) {
  return api.get(`/training-lesson-search?searchTerm=${searchTerm}`);
}

export async function searchLessons(searchTerm) {
  return api.get(`/lessons-search?searchTerm=${searchTerm}`);
}

export async function searchLessonTypes(searchTerm) {
  return api.get(`/lesson-type-search?searchTerm=${searchTerm}`);
}

export async function searchProjectOutputTypes(searchTerm) {
  return api.get(`/output-type-search?searchTerm=${searchTerm}`);
}

export async function searchGardenInputTypes(searchTerm) {
  return api.get(`/garden-input-type-search?searchTerm=${searchTerm}`);
}

export async function searchCrsIecTypes(searchTerm) {
  return api.get(`/crs-iec-material-types-search?searchTerm=${searchTerm}`);
}

export async function searchUnifiedTrainingLessons(searchTerm) {
  return api.get(`/unified-training-lesson-search?searchTerm=${searchTerm}`);
}

export async function searchTrainingSessions(searchTerm, intermediaryId = '', lessonId = '') {
  return api.get(`/training-session-search?searchTerm=${searchTerm}&intermediaryId=${intermediaryId}&lessonId=${lessonId}`);
}

export async function getIecMaterialCategories(page, size, searchTerm = '') {
  return api.get(`/iec-material-categories?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchIecMaterialCategories(searchTerm) {
  return api.get(`/iec-material-categories-search?searchTerm=${searchTerm}`);
}

export async function getTrainingFacilitators(page, size, searchTerm = '') {
  return api.get(`/training-facilitators?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchGizPersons(searchTerm) {
  return api.get(`/giz-persons-search?searchTerm=${searchTerm}`);
}

export async function searchIntermediaryTypes(searchTerm) {
  return api.get(`/giz-intermediary-types-search?searchTerm=${searchTerm}`);
}

export async function getTrainingBlocks(page, size, searchTerm = '') {
  return api.get(`/training-blocks?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchTrainingBlocks(searchTerm) {
  return api.get(`/training-blocks-search?searchTerm=${searchTerm}`);
}

export async function getSiteVisits(page, size, searchTerm = '') {
  return api.get(`/site-visits?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getSiteVisitsDump() {
  return api.get('/site-visits-dump', {
    responseType: 'stream',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getMonitoringVisits(page, size) {
  return api.get(`/monitoring-visits?page=${page}&size=${size}`);
}

export async function getMonitoringVisitsDump() {
  return api.get('/monitoring-visits-dump', {
    responseType: 'stream',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getSupervisions(page, size, searchTerm = '') {
  return api.get(`/lead-farmer-supervisions?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getMeetings(page, size, searchTerm = '') {
  return api.get(`/meetings?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchMeetings(searchTerm) {
  return api.get(`/meetings-search?searchTerm=${searchTerm}`);
}

export async function getKeyTopics(page, size, searchTerm = '') {
  return api.get(`/key-topics?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getFbsCourses(page, size, searchTerm = '') {
  return api.get(`/fbs-courses?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchFbsCourses(searchTerm) {
  return api.get(`/fbs-courses-search?searchTerm=${searchTerm}`);
}

export async function searchFarmingGroups(searchTerm) {
  return api.get(`/farming-groups-search?searchTerm=${searchTerm}`);
}

export async function getFbsModules(page, size, searchTerm = '') {
  return api.get(`/fbs-modules?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getFbsModuleSessions(page, size, searchTerm = '') {
  return api.get(`/fbs-module-sessions?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchFbsModules(searchTerm) {
  return api.get(`/fbs-modules-search?searchTerm=${searchTerm}`);
}

export async function searchFbsModuleSessions(searchTerm) {
  return api.get(`/fbs-module-sessions-search?searchTerm=${searchTerm}`);
}

export async function searchTrainingFacilitators(searchTerm) {
  return api.get(`/training-facilitators-search?searchTerm=${searchTerm}`);
}

export async function getFbsModuleSessionParticipants(page, size, searchTerm = '') {
  return api.get(`/fbs-module-session-participants?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getPspVisits(page, size, searchTerm = '') {
  return api.get(`/psp-visits?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getPractices(page, size, searchTerm = '') {
  return api.get(`/practices?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getKeyElements(page, size, searchTerm = '') {
  return api.get(`/key-elements?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getTechniques(page, size, searchTerm = '') {
  return api.get(`/techniques?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getCrops(page, size, searchTerm = '') {
  return api.get(`/crops?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchCrops(searchTerm) {
  return api.get(`/crops-search?searchTerm=${searchTerm}`);
}

export async function searchPractices(searchTerm) {
  return api.get(`/practices-search?searchTerm=${searchTerm}`);
}

export async function searchTechniques(searchTerm) {
  return api.get(`/techniques-search?searchTerm=${searchTerm}`);
}

export async function getLeavingReasons(page, size, searchTerm = '') {
  return api.get(`/leaving-reasons?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getAbolishmentReasons(page, size, searchTerm = '') {
  return api.get(`/abolishment-reasons?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getBeneficiaryStatuses(page, size, searchTerm = '') {
  return api.get(`/beneficiary-statuses?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchBeneficiaryStatuses(searchTerm) {
  return api.get(`/beneficiary-statuses-search?searchTerm=${searchTerm}`);
}

export async function getBeneficiaryRoles(page, size, searchTerm = '') {
  return api.get(`/beneficiary-roles?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchBeneficiaryRoles(searchTerm) {
  return api.get(`/beneficiary-roles-search?searchTerm=${searchTerm}`);
}

export async function getGoodTypes(page, size, searchTerm = '') {
  return api.get(`/good-types?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getCrsIecMaterialTypes(page, size, searchTerm = '') {
  return api.get(`/crs-iec-material-types?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getCrsOutputTypes(page, size, searchTerm = '') {
  return api.get(`/output-types?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getCrsGardenInputTypes(page, size, searchTerm = '') {
  return api.get(`/garden-input-types?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getCrsLessonTypes(page, size, searchTerm = '') {
  return api.get(`/lesson-types?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchGoodTypes(searchTerm) {
  return api.get(`/good-types-search?searchTerm=${searchTerm}`);
}

export async function searchWorkingMaterialTypes(searchTerm) {
  return api.get(`/working-material-search?searchTerm=${searchTerm}`);
}

export async function searchGizIntermediaries(searchTerm) {
  return api.get(`/giz-intermediaries-search?searchTerm=${searchTerm}`);
}

export async function searchLeadFarmers(searchTerm) {
  return api.get(`/lead-farmers-search?searchTerm=${searchTerm}`);
}

export async function searchCrsIntermediaries(searchTerm) {
  return api.get(`/crs-intermediaries-search?searchTerm=${searchTerm}`);
}

export async function searchNutritionVolunteers(searchTerm) {
  return api.get(`/crs-nv-search?searchTerm=${searchTerm}`);
}

export async function searchNutritionVolunteersWithHouseholds(searchTerm) {
  return api.get(`/crs-nv-hh-search?searchTerm=${searchTerm}`);
}

export async function searchNonNvAgents(searchTerm) {
  return api.get(`/crs-non-nv-search?searchTerm=${searchTerm}`);
}

export async function searchSeniorLeadFarmers(searchTerm) {
  return api.get(`/senior-lead-farmer-search?searchTerm=${searchTerm}`);
}

export async function searchGizIntermediaryPersons(searchTerm) {
  return api.get(`/giz-intermediary-persons-search?searchTerm=${searchTerm}`);
}

export async function searchFarmingGroupMembers(searchTerm) {
  return api.get(`/farming-group-members-search?searchTerm=${searchTerm}`);
}

export async function searchHouseholds(searchTerm) {
  return api.get(`/households-search?searchTerm=${searchTerm}`);
}

export async function getIecMaterialTypes(page, size, searchTerm = '') {
  return api.get(`/iec-material-types?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchIecMaterialTypes(searchTerm) {
  return api.get(`/iec-material-types-search?searchTerm=${searchTerm}`);
}

export async function getGoodPurchases(page, size, searchTerm = '') {
  return api.get(`/good-purchases?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getGoodDistributions(page, size, searchTerm = '') {
  return api.get(`/good-distributions?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getGoodDistributionsDump() {
  return api.get('/good-distributions-dump', {
    responseType: 'blob',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getWorkingMaterialPurchases(page, size, searchTerm = '') {
  return api.get(`/working-material-purchases?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getWorkingMaterialDistributions(page, size, searchTerm = '') {
  return api.get(`/working-material-distributions?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getTrainingLessons(page, size, searchTerm = '') {
  return api.get(`/training-lessons?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getTrainingSessions(page, size, searchTerm = '') {
  return api.get(`/training-sessions?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getTrainingSessionParticipants(page, size, searchTerm = '') {
  return api.get(`/training-session-participants?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getTrainingSessionParticipantsDump() {
  return api.get('/training-session-participants-dump', {
    responseType: 'blob',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getFbsModuleSessionParticipantsDump() {
  return api.get('/fbs-module-session-participants-dump', {
    responseType: 'blob',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getLessonsDump() {
  return api.get('/lessons-dump', {
    responseType: 'blob',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getIecDistributionDump() {
  return api.get('/distributed-iec-materials-dump', {
    responseType: 'blob',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getGardenInputDump() {
  return api.get('/garden-inputs-dump', {
    responseType: 'blob',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getProjectOutputDump() {
  return api.get('/project-outputs-dump', {
    responseType: 'blob',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function getTotTrainingSessions(page, size, searchTerm = '') {
  return api.get(`/tot-sessions?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function searchTotTrainingSessions(searchTerm, intermediaryId = '', trainingBlockId = '') {
  return api.get(`/tot-sessions-search?searchTerm=${searchTerm}&intermediaryId=${intermediaryId}&trainingBlockId=${trainingBlockId}`);
}

export async function getTotTrainingSessionParticipants(page, size, searchTerm = '') {
  return api.get(`/tot-participants?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getWorkingMaterialTypes(page, size, searchTerm = '') {
  return api.get(`/working-material-types?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getIntermediaryTypes(page, size, searchTerm = '') {
  return api.get(`/giz-intermediary-types?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getDuplicateSeniorLeadFarmers(page, size, searchTerm = '') {
  return api.get(`/duplicate-senior-lead-farmers?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getDuplicateLeadFarmers(page, size, searchTerm = '') {
  return api.get(`/duplicate-lead-farmers?page=${page}&size=${size}&searchTerm=${searchTerm}`);
}

export async function getDuplicateBeneficiaries(page, size) {
  return api.get(`/duplicate-beneficiaries?page=${page}&size=${size}`);
}

export async function getDuplicateIntermediaries(intermediaryId) {
  return api.get(`/duplicate-intermediaries/${intermediaryId}`);
}

/* --- POST requests ---*/

export async function getBackwardSynch(body) {
  return api.post('/backward-synch', body, {
    responseType: 'blob',
    headers: {
      accept: 'text/csv'
    }
  });
}

export async function generateReport(body) {
  return api.post(`/reports`, body);
}

export async function generateKeyOuputs(body) {
  return api.post(`/key-outputs`, body);
}

export async function createUser(body) {
  return api.post(`/users`, body);
}

export async function createProvince(body) {
  return api.post(`/provinces`, body);
}

export async function createDistrict(body) {
  return api.post(`/districts`, body);
}

export async function createWard(body) {
  return api.post(`/wards`, body);
}

export async function createCamp(body) {
  return api.post(`/camps`, body);
}

export async function createVillage(body) {
  return api.post(`/villages`, body);
}

export async function createHousehold(body) {
  return api.post(`/households`, body);
}

export async function createBeneficiary(body) {
  return api.post(`/beneficiaries`, body);
}

export async function createBeneficiaryDropout(body) {
  return api.post(`/beneficiary-dropouts`, body);
}

export async function createFarmingGroupMemberDropout(body) {
  return api.post(`/farming-group-member-dropouts`, body);
}

export async function createGizIntermediary(body) {
  return api.post(`/giz-intermediaries`, body);
}

export async function createCrsIntermediary(body) {
  return api.post(`/crs-intermediaries`, body);
}

export async function createIntermediaryDropout(body) {
  return api.post(`/intermediary-dropouts`, body);
}

export async function createTrainer(body) {
  return api.post(`/trainers`, body);
}

export async function createGoodCategory(body) {
  return api.post(`/good-categories`, body);
}

export async function createCrsIecType(body) {
  return api.post(`/crs-iec-material-types`, body);
}

export async function createCrsLessonType(body) {
  return api.post(`/lesson-types`, body);
}

export async function createGardenInputType(body) {
  return api.post(`/garden-input-types`, body);
}

export async function createProjectOutputType(body) {
  return api.post(`/output-types`, body);
}

export async function createGoodType(body) {
  return api.post(`/good-types`, body);
}

export async function createIecMaterialCategory(body) {
  return api.post(`/iec-material-categories`, body);
}

export async function createKeyOutputsUsed(body) {
  return api.post(`/key-outputs-used`, body);
}

export async function createMultipleKeyOutputsUsed(body) {
  return api.post(`/multiple-key-outputs-used`, body);
}

export async function createSiteVist(body) {
  return api.post(`/site-visits`, body);
}

export async function createSupervision(body) {
  return api.post(`/lead-farmer-supervisions`, body);
}

export async function createIecMaterialType(body) {
  return api.post(`/iec-material-types`, body);
}

export async function createIecMaterialDistribution(body) {
  return api.post(`/iec-material-distributions`, body);
}

export async function createWorkingMaterialPurchase(body) {
  return api.post(`/working-material-purchases`, body);
}

export async function createWorkingMaterialDistribution(body) {
  return api.post(`/working-material-distributions`, body);
}

export async function createWorkingMaterialType(body) {
  return api.post(`/working-material-types`, body);
}

export async function createIntermediaryType(body) {
  return api.post(`/giz-intermediary-types`, body);
}

export async function createGoodPurchase(body) {
  return api.post(`/good-purchases`, body);
}

export async function createGoodDistribution(body) {
  return api.post(`/good-distributions`, body);
}

export async function createMultipleGoodDistributions(body) {
  return api.post(`/multiple-good-distributions`, body);
}

export async function createTrainingLesson(body) {
  return api.post(`/training-lessons`, body);
}

export async function createTrainingSession(body) {
  return api.post(`/training-sessions`, body);
}

export async function createLesson(body) {
  return api.post(`/lessons`, body);
}

export async function createMultipleLessons(body) {
  return api.post(`/multiple-lessons`, body);
}

export async function createCrsIecDistribution(body) {
  return api.post(`/distributed-iec-materials`, body);
}

export async function createGardenInput(body) {
  return api.post(`/garden-inputs`, body);
}

export async function createProjectOutput(body) {
  return api.post(`/project-outputs`, body);
}

export async function createMultipleProjectOutputs(body) {
  return api.post(`/multiple-project-outputs`, body);
}

export async function createMultipleTrainingSessions(body) {
  return api.post(`/multiple-training-sessions`, body);
}

export async function createTrainingSessionParticipant(body) {
  return api.post(`/training-session-participants`, body);
}

export async function createMultipleTrainingSessionParticipants(body) {
  return api.post(`/multiple-training-session-participants`, body);
}

export async function createTotTrainingSessionParticipant(body) {
  return api.post(`/tot-participants`, body);
}

export async function createTotTrainingSession(body) {
  return api.post(`/tot-sessions`, body);
}

export async function createTrainingFacilitator(body) {
  return api.post(`/training-facilitators`, body);
}

export async function createTrainingBlock(body) {
  return api.post(`/training-blocks`, body);
}

export async function createMeeting(body) {
  return api.post(`/meetings`, body);
}

export async function createKeyTopic(body) {
  return api.post(`/key-topics`, body);
}

export async function createFbsCourse(body) {
  return api.post(`/fbs-courses`, body);
}

export async function createFbsModule(body) {
  return api.post(`/fbs-modules`, body);
}

export async function createFarmingGroup(body) {
  return api.post(`/farming-groups`, body);
}

export async function createFarmingGroupMember(body) {
  return api.post(`/farming-group-members`, body);
}

export async function createMultipleFarmingGroupMembers(body) {
  return api.post(`/multiple-farming-group-members`, body);
}

export async function createMultipleFarmingGroupMembersByNvs(body) {
  return api.post(`/multiple-farming-group-members-by-nvs`, body);
}

export async function createFbsModuleSession(body) {
  return api.post(`/fbs-module-sessions`, body);
}

export async function createMultipleFbsModuleSessions(body) {
  return api.post(`/multiple-fbs-module-sessions`, body);
}

export async function createFbsModuleSessionParticipant(body) {
  return api.post(`/fbs-module-session-participants`, body);
}

export async function createMultipleFbsModuleSessionParticipant(body) {
  return api.post(`/multiple-fbs-module-session-participants`, body);
}

export async function createPractice(body) {
  return api.post(`/practices`, body);
}

export async function createKeyElement(body) {
  return api.post(`/key-elements`, body);
}

export async function createTechnique(body) {
  return api.post(`/techniques`, body);
}

export async function createCrop(body) {
  return api.post(`/crops`, body);
}

export async function createPracticeUsed(body) {
  return api.post(`/practices-used`, body);
}

export async function createMultiplePracticesUsed(body) {
  return api.post(`/multiple-practices-used`, body);
}

export async function createTechniqueUsed(body) {
  return api.post(`/techniques-used`, body);
}

export async function createMultipleTechniqueUsed(body) {
  return api.post(`/multiple-techniques-used`, body);
}

export async function createCropsGrownBeforeTraining(body) {
  return api.post(`/crops-grown-before-training`, body);
}

export async function createMultipleCropsGrownBeforeTraining(body) {
  return api.post(`/multiple-crops-grown-before-training`, body);
}

export async function createCropsGrownAfterTraining(body) {
  return api.post(`/crops-grown-after-training`, body);
}

export async function createMultipleCropsGrownAfterTraining(body) {
  return api.post(`/multiple-crops-grown-after-training`, body);
}

export async function createLeavingReason(body) {
  return api.post(`/leaving-reasons`, body);
}

export async function createAbolishmentReason(body) {
  return api.post(`/abolishment-reasons`, body);
}

export async function createBeneficiaryStatus(body) {
  return api.post(`/beneficiary-statuses`, body);
}

export async function createBeneficiaryRole(body) {
  return api.post(`/beneficiary-roles`, body);
}

export async function createPspVisit(body) {
  return api.post(`/psp-visits`, body);
}

export async function validateSlfReport(body) {
  return api.post(`/validate-slf-report`, body);
}

export async function invalidateSlfReport(body) {
  return api.post(`/invalidate-slf-report`, body);
}

export async function reactivateSlfReport(body) {
  return api.post(`/reactivate-slf-report`, body);
}

export async function createFarmingGroupWithMultipleMembers(body) {
  return api.post(`/farming-group-multiple-members`, body);
}

export async function mergeIntermediaries(body) {
  return api.post(`/merge-intermediaries`, body);
}

/* --- DELETE requests ---*/

export async function deleteProvince(provinceId, deleteType = 'SOFT') {
  return api.delete(`/provinces/${provinceId}?deleteType=${deleteType}`);
}

export async function deleteDistrict(districtId, deleteType = 'SOFT') {
  return api.delete(`/districts/${districtId}?deleteType=${deleteType}`);
}

export async function deleteSiteVisit(visitId) {
  return api.delete(`/site-visits/${visitId}`);
}

export async function deleteMonitoringVisit(visitId) {
  return api.delete(`/lead-farmer-supervisions/${visitId}`);
}

export async function deleteCropsGrownBeforeTraining(cropId) {
  return api.delete(`/crops-grown-before-training/${cropId}`);
}

export async function deleteCropsGrownAfterTraining(cropId) {
  return api.delete(`/crops-grown-after-training/${cropId}`);
}

export async function deleteKeyOutputUsage(cropId) {
  return api.delete(`/key-outputs-used/${cropId}`);
}

export async function deleteTechniqueUsage(cropId) {
  return api.delete(`/techniques-used/${cropId}`);
}

export async function deletePracticeUsage(cropId) {
  return api.delete(`/practices-used/${cropId}`);
}

export async function deleteFbsSessions(cropId) {
  return api.delete(`/fbs-module-sessions/${cropId}`);
}

export async function deleteFbsSessionParticipants(cropId) {
  return api.delete(`/fbs-module-session-participants/${cropId}`);
}

export async function deleteWard(wardId, deleteType = 'SOFT') {
  return api.delete(`/wards/${wardId}?deleteType=${deleteType}`);
}

export async function deleteUser(userId) {
  return api.delete(`/users/${userId}`);
}

export async function deleteCamp(campId, deleteType = 'SOFT') {
  return api.delete(`/camps/${campId}?deleteType=${deleteType}`);
}

export async function deleteVillage(villageId, deleteType = 'SOFT') {
  return api.delete(`/villages/${villageId}?deleteType=${deleteType}`);
}

export async function deleteHousehold(householdId, deleteType = 'SOFT') {
  return api.delete(`/households/${householdId}?deleteType=${deleteType}`);
}

export async function deleteBeneficiary(beneficiaryId, deleteType = 'SOFT') {
  return api.delete(`/beneficiaries/${beneficiaryId}?deleteType=${deleteType}`);
}

export async function deleteBeneficiaryDropout(dropoutId, deleteType = 'SOFT') {
  return api.delete(`/beneficiary-dropouts/${dropoutId}?deleteType=${deleteType}`);
}

export async function deleteCrop(cropId, deleteType = 'SOFT') {
  return api.delete(`/crops/${cropId}?deleteType=${deleteType}`);
}

export async function deleteFarmingGroup(groupId) {
  return api.delete(`/farming-groups/${groupId}`);
}

export async function deleteFarmingGroupMember(memberId) {
  return api.delete(`/farming-group-members/${memberId}`);
}

export async function deleteGoodCateogory(categoryId, deleteType = 'SOFT') {
  return api.delete(`/good-categories/${categoryId}?deleteType=${deleteType}`);
}

export async function deleteGoodType(typeId, deleteType = 'SOFT') {
  return api.delete(`/good-types/${typeId}?deleteType=${deleteType}`);
}

export async function deleteGoodDistribution(distributionId, deleteType = 'SOFT') {
  return api.delete(`/good-distributions/${distributionId}?deleteType=${deleteType}`);
}

export async function deleteIecMaterialCateogory(categoryId, deleteType = 'SOFT') {
  return api.delete(`/iec-material-categories/${categoryId}?deleteType=${deleteType}`);
}

export async function deleteIecMaterialType(typeId, deleteType = 'SOFT') {
  return api.delete(`/iec-material-types/${typeId}?deleteType=${deleteType}`);
}

export async function deleteBeneficiaryStatus(statusId, deleteType = 'SOFT') {
  return api.delete(`/beneficiary-statuses/${statusId}?deleteType=${deleteType}`);
}

export async function deleteBeneficiaryRole(roleId, deleteType = 'SOFT') {
  return api.delete(`/beneficiary-roles/${roleId}?deleteType=${deleteType}`);
}

export async function deleteAbolishmentReason(reasonId, deleteType = 'SOFT') {
  return api.delete(`/abolishment-reasons/${reasonId}?deleteType=${deleteType}`);
}

export async function deleteLeavingReason(reasonId, deleteType = 'SOFT') {
  return api.delete(`/leaving-reasons/${reasonId}?deleteType=${deleteType}`);
}

export async function deleteFbsCourse(courseId, deleteType = 'SOFT') {
  return api.delete(`/fbs-courses/${courseId}?deleteType=${deleteType}`);
}

export async function deleteFbsModule(moduleId, deleteType = 'SOFT') {
  return api.delete(`/fbs-modules/${moduleId}?deleteType=${deleteType}`);
}

export async function deleteFacilitator(facilitatorId, deleteType = 'SOFT') {
  return api.delete(`/training-facilitators/${facilitatorId}?deleteType=${deleteType}`);
}

export async function deleteMeeting(meetingId, deleteType = 'SOFT') {
  return api.delete(`/meetings/${meetingId}?deleteType=${deleteType}`);
}

export async function deleteKeyTopic(keyTopicId, deleteType = 'SOFT') {
  return api.delete(`/key-topics/${keyTopicId}?deleteType=${deleteType}`);
}

export async function deleteTrainingBlock(trainingBlockId, deleteType = 'SOFT') {
  return api.delete(`/training-blocks/${trainingBlockId}?deleteType=${deleteType}`);
}

export async function deleteTotParticipant(participantId) {
  return api.delete(`/tot-participants/${participantId}`);
}

export async function deleteTotSession(sessionId) {
  return api.delete(`/tot-sessions/${sessionId}`);
}

export async function deleteTrainingLesson(trainingLessonId, deleteType = 'SOFT') {
  return api.delete(`/training-lessons/${trainingLessonId}?deleteType=${deleteType}`);
}

export async function deleteTrainingSession(sessionId) {
  return api.delete(`/training-sessions/${sessionId}`);
}

export async function deleteTrainingSessionParticipant(participantId) {
  return api.delete(`/training-session-participants/${participantId}`);
}

export async function deleteTechnique(techniqueId, deleteType = 'SOFT') {
  return api.delete(`/techniques/${techniqueId}?deleteType=${deleteType}`);
}

export async function deleteKeyOutput(outputId, deleteType = 'SOFT') {
  return api.delete(`/key-elements/${outputId}?deleteType=${deleteType}`);
}

export async function deleteIntermediaryType(typeId, deleteType = 'SOFT') {
  return api.delete(`/giz-intermediary-types/${typeId}?deleteType=${deleteType}`);
}

export async function deleteIntermediary(intermediaryId, deleteType = 'SOFT') {
  return api.delete(`/giz-intermediaries/${intermediaryId}?deleteType=${deleteType}`);
}

export async function deleteCrsAgent(intermediaryId, deleteType = 'SOFT') {
  return api.delete(`/crs-intermediaries/${intermediaryId}?deleteType=${deleteType}`);
}

export async function deleteWorkingMaterialType(typeId, deleteType = 'SOFT') {
  return api.delete(`/working-material-types/${typeId}?deleteType=${deleteType}`);
}

export async function deleteCrsIecMaterialType(typeId, deleteType = 'SOFT') {
  return api.delete(`/crs-iec-material-types/${typeId}?deleteType=${deleteType}`);
}

export async function deleteCrsLessonType(typeId, deleteType = 'SOFT') {
  return api.delete(`/lesson-types/${typeId}?deleteType=${deleteType}`);
}

export async function deleteGardenInputType(typeId, deleteType = 'SOFT') {
  return api.delete(`/garden-input-types/${typeId}?deleteType=${deleteType}`);
}

export async function deleteProjectOutputType(typeId, deleteType = 'SOFT') {
  return api.delete(`/output-types/${typeId}?deleteType=${deleteType}`);
}

export async function deleteWorkingMaterialPurchase(purchaseId, deleteType = 'SOFT') {
  return api.delete(`/working-material-purchases/${purchaseId}?deleteType=${deleteType}`);
}

export async function deleteWorkingMaterialDistribution(distributionId, deleteType = 'SOFT') {
  return api.delete(`/working-material-distributions/${distributionId}?deleteType=${deleteType}`);
}

export async function deletePractice(practiceId, deleteType = 'SOFT') {
  return api.delete(`/practices/${practiceId}?deleteType=${deleteType}`);
}

export async function deletePspVisit(visitId, deleteType = 'SOFT') {
  return api.delete(`/psp-visits/${visitId}?deleteType=${deleteType}`);
}

/* --- PUT requests ---*/

export async function updateProvince(provinceId, body) {
  return api.put(`/provinces/${provinceId}`, body);
}

export async function updateDistrict(districtId, body) {
  return api.put(`/districts/${districtId}`, body);
}

export async function updateSiteVisit(visitId, body) {
  return api.put(`/site-visits/${visitId}`, body);
}

export async function updateMonitoringVisit(visitId, body) {
  return api.put(`/lead-farmer-supervisions/${visitId}`, body);
}

export async function updateCropsGrownBeforeTraining(cropId, body) {
  return api.put(`/crops-grown-before-training/${cropId}`, body);
}

export async function updateCropsGrownAfterTraining(cropId, body) {
  return api.put(`/crops-grown-after-training/${cropId}`, body);
}

export async function updateFarmingGroup(groupId, body) {
  return api.put(`/farming-groups/${groupId}`, body);
}

export async function updateFarmingGroupMember(memberId, body) {
  return api.put(`/farming-group-members/${memberId}`, body);
}

export async function updateKeyElementUsage(usageId, body) {
  return api.put(`/key-outputs-used/${usageId}`, body);
}

export async function updateTechniqueUsage(usageId, body) {
  return api.put(`/techniques-used/${usageId}`, body);
}

export async function updatePracticeUsage(usageId, body) {
  return api.put(`/practices-used/${usageId}`, body);
}

export async function updateFbsModuleSession(sessionId, body) {
  return api.put(`/fbs-module-sessions/${sessionId}`, body);
}

export async function updateFbsModuleSessionParticipant(participantId, body) {
  return api.put(`/fbs-module-session-participants/${participantId}`, body);
}

export async function updateWard(wardId, body) {
  return api.put(`/wards/${wardId}`, body);
}

export async function updateCamp(campId, body) {
  return api.put(`/camps/${campId}`, body);
}

export async function updateVillage(villageId, body) {
  return api.put(`/villages/${villageId}`, body);
}

export async function updateHousehold(householdId, body) {
  return api.put(`/households/${householdId}`, body);
}

export async function updateBeneficiary(beneficiaryId, body) {
  return api.put(`/beneficiaries/${beneficiaryId}`, body);
}

export async function updateBeneficiaryDropout(dropoutId, body) {
  return api.put(`/beneficiary-dropouts/${dropoutId}`, body);
}

export async function updateLesson(lessonId, body) {
  return api.put(`/lessons/${lessonId}`, body);
}

export async function updateProjectOutput(outputId, body) {
  return api.put(`/project-outputs/${outputId}`, body);
}

export async function updateGardenInput(inputId, body) {
  return api.put(`/garden-inputs/${inputId}`, body);
}

export async function updateCrsIecDistribution(distributionId, body) {
  return api.put(`/distributed-iec-materials/${distributionId}`, body);
}

export async function updateCrop(cropId, body) {
  return api.put(`/crops/${cropId}`, body);
}

export async function updateGoodCateogory(categoryId, body) {
  return api.put(`/good-categories/${categoryId}`, body);
}

export async function updateGoodType(typeId, body) {
  return api.put(`/good-types/${typeId}`, body);
}

export async function updateGoodDistribution(distributionId, body) {
  return api.put(`/good-distributions/${distributionId}`, body);
}

export async function updateFbsCourse(courseId, body) {
  return api.put(`/fbs-courses/${courseId}`, body);
}

export async function updateFbsModule(moduleId, body) {
  return api.put(`/fbs-modules/${moduleId}`, body);
}

export async function updateMeeting(meetingId, body) {
  return api.put(`/meetings/${meetingId}`, body);
}

export async function updateFacilitator(facilitatorId, body) {
  return api.put(`/training-facilitators/${facilitatorId}`, body);
}

export async function updateTrainingBlock(trainingBlockId, body) {
  return api.put(`/training-blocks/${trainingBlockId}`, body);
}

export async function updateKeyTopic(topicId, body) {
  return api.put(`/key-topics/${topicId}`, body);
}

export async function updateIecMaterialCateogory(categoryId, body) {
  return api.put(`/iec-material-categories/${categoryId}`, body);
}

export async function updateIecMaterialType(typeId, body) {
  return api.put(`/iec-material-types/${typeId}`, body);
}

export async function updateBeneficiaryStatus(statusId, body) {
  return api.put(`/beneficiary-statuses/${statusId}`, body);
}

export async function updateBeneficiaryRole(roleId, body) {
  return api.put(`/beneficiary-roles/${roleId}`, body);
}

export async function updateAbolishmentReason(reasonId, body) {
  return api.put(`/abolishment-reasons/${reasonId}`, body);
}

export async function updateLeavingReason(reasonId, body) {
  return api.put(`/leaving-reasons/${reasonId}`, body);
}

export async function updateTrainingLesson(lessonId, body) {
  return api.put(`/training-lessons/${lessonId}`, body);
}

export async function updateTrainingSession(sessionId, body) {
  return api.put(`/training-sessions/${sessionId}`, body);
}

export async function updateTrainingSessionParticipant(participantId, body) {
  return api.put(`/training-session-participants/${participantId}`, body);
}

export async function updateTechnique(techniqueId, body) {
  return api.put(`/techniques/${techniqueId}`, body);
}

export async function updateKeyOutput(outputId, body) {
  return api.put(`/key-elements/${outputId}`, body);
}

export async function updateIntermediaryType(typeId, body) {
  return api.put(`/giz-intermediary-types/${typeId}`, body);
}

export async function updateIntermediary(intermediaryId, body) {
  return api.put(`/giz-intermediaries/${intermediaryId}`, body);
}

export async function updateCrsAgent(intermediaryId, body) {
  return api.put(`/crs-intermediaries/${intermediaryId}`, body);
}

export async function updateWorkingMaterialType(typeId, body) {
  return api.put(`/working-material-types/${typeId}`, body);
}

export async function updateCrsIecMaterialType(typeId, body) {
  return api.put(`/crs-iec-material-types/${typeId}`, body);
}

export async function updateCrsLessonType(typeId, body) {
  return api.put(`/lesson-types/${typeId}`, body);
}

export async function updateGardenInputType(typeId, body) {
  return api.put(`/garden-input-types/${typeId}`, body);
}

export async function updateProjectOutputType(typeId, body) {
  return api.put(`/output-types/${typeId}`, body);
}

export async function updateWorkingMaterialPurchase(purchaseId, body) {
  return api.put(`/working-material-purchases/${purchaseId}`, body);
}

export async function updateWorkingMaterialDistribution(distributionId, body) {
  return api.put(`/working-material-distributions/${distributionId}`, body);
}

export async function updatePractice(practiceId, body) {
  return api.put(`/practices/${practiceId}`, body);
}

export async function updateLeadFarmerObservations(seniorLeadFarmerId, body) {
  return api.put(`/update-intermediary-observations/${seniorLeadFarmerId}`, body);
}

export async function updateTotTrainingSession(sessionId, body) {
  return api.put(`/tot-sessions/${sessionId}`, body);
}

export async function updateTotParticipant(participantId, body) {
  return api.put(`/tot-participants/${participantId}`, body);
}