import React, {useCallback, useEffect, useRef, useState} from 'react';
import { CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createVillage,
  deleteVillage,
  getVillages, updateVillage
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import AddVillageDialog from "../dialogs/AddVillageDialog";
import debounce from "lodash.debounce";
import TextField from "@material-ui/core/TextField";
import {formatTime} from "../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function Villages(props) {
  const { classes, role } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedVillages, setPaginatedVillages] = useState('');
  const [villageBuffer, setVillageBuffer] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const addVillageConfirmationHandler =
      async (villageName, camp) => {
        await createVillage({
          'villageName': villageName,
          'camp': camp
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, villageName, camp) => {
        await updateGoodsTypeDataAndDispatch(entityId, {
          'villageName': villageName,
          'camp': camp
        });
        await fetchData();
      };

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (villageId, deleteType = 'SOFT') => {
    await deleteVillage(villageId, deleteType);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getVillages(page, size, searchTerm);
          const villagesData = result.data;

          if (!cancelRequest.current) {
            if (villagesData && Object.keys(villagesData).length > 0) {
              setPaginatedVillages(villagesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const updateGoodsTypeDataAndDispatch = async (villageId, fields) => {
    try {
      let buffer = {
        ...villageBuffer
      };

      setVillageBuffer(buffer);

      const { data: data } = await updateVillage(
          villageId,
          fields
      );

      if (!cancelRequest.current) {
        setVillageBuffer(data);
      }
    } catch (error) {
      setVillageBuffer(villageBuffer);
    }
  };

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedVillages) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Village name, camp name"
                id="village-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedVillages.content}
                getRowClassName={(params) => `row-${params.row.deleted === true ? 'deleted' : 'active'}`}
                columns={[
                  { field: 'villageName', headerName: 'Name', flex: 1 },
                  { field: 'camp', headerName: 'Camp Name', flex: 1,
                    valueFormatter: (params) =>
                        (params.value.campName), },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                      {(role === 'ROLE_SUPER' || role === 'ROLE_STANDARD') &&
                        <>
                          <AddVillageDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                          <DataGridActionBar
                              data={params.row}
                              type={'Village'}
                              deleteHandler={removeHandler}
                              relations={[
                                'Household',
                                'Training Session',
                                'ToT Training Session',
                                'Village Health Promoter',
                              ]}
                          >
                          </DataGridActionBar>
                        </>
                      }
                      </>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedVillages.size}
                rowCount={paginatedVillages.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <AddVillageDialog classes={classes} confirmationHandler={addVillageConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Villages);