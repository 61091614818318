import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createCrsLessonType, deleteCrsLessonType,
  getCrsLessonTypes, updateCrsLessonType

} from "../../../utils/api";
import DataGridActionBar from "../../parts/DataGridActionBar";
import AddCrsLessonTypeDialog from "../../dialogs/AddCrsLessonTypeDialog";
import CreationSnackbar from "../../dialogs/CreationSnackbar";
import debounce from "lodash.debounce";
import {formatTime} from "../../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function LessonTypes(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedLessonTypes, setpaginatedLessonTypes] = useState('');
  const [typeBuffer, setTypeBuffer] = useState('');
  const [successBarText, setSuccessBarText] = useState('');
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const addCrsIecTypeConfirmationHandler =
      async (values) => {
        await createCrsLessonType({
          'nameLessonType': values.nameLessonType,
        }).then((entity) => {
          setSuccessBarText(entity.data ? `Lesson type ${entity.data.nameLessonType}` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, values) => {
        await updateLessonTypeDataAndDispatch(entityId, {
          'nameLessonType': values.nameLessonType,
        });
        await fetchData();
      };

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (typeId, deleteType = 'SOFT') => {
    await deleteCrsLessonType(typeId, deleteType);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getCrsLessonTypes(page, size, searchTerm);
          const typessData = result.data;

          if (!cancelRequest.current) {
            if (typessData && Object.keys(typessData).length > 0) {
              setpaginatedLessonTypes(typessData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const updateLessonTypeDataAndDispatch = async (typeId, fields) => {
    try {
      let buffer = {
        ...typeBuffer
      };

      setTypeBuffer(buffer);

      const { data: data } = await updateCrsLessonType(
          typeId,
          fields
      );

      if (!cancelRequest.current) {
        setTypeBuffer(data);
      }
    } catch (error) {
      setTypeBuffer(typeBuffer);
    }
  };

  const handleSuccessBarState = (open) => {
    setShowSuccessBar(open);
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedLessonTypes) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Type name"
                id="type-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '95%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedLessonTypes.content}
                getRowClassName={(params) => `row-${params.row.deleted === true ? 'deleted' : 'active'}`}
                columns={[
                  { field: 'nameLessonType', headerName: 'Type', flex: 1 },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                        <AddCrsLessonTypeDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                        <DataGridActionBar
                            data={params.row}
                            type={'Lesson'}
                            deleteHandler={removeHandler}
                            relations={[
                              'Lessons conducted',
                            ]}
                        >
                        </DataGridActionBar>
                      </>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedLessonTypes.size}
                rowCount={paginatedLessonTypes.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <AddCrsLessonTypeDialog
              confirmationHandler={addCrsIecTypeConfirmationHandler}/>
          <CreationSnackbar successBarOpen={showSuccessBar} successBarHandler={handleSuccessBarState} successText={successBarText} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(LessonTypes);