import React, {useContext, useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {UserContext} from "../auth/UserProvider";


const styles = () => ({
  block: {
    display: 'block',
  },
});

function DataGridActionBar(props) {
  const { classes, data, type, deleteHandler, relations } = props;

  const authState = useContext(UserContext);

  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteConfirm = () => {
    deleteHandler(type === 'User' ? data.uid : data.id, 'HARD');
    handleDeleteClose();
  };

  return (
    <>
      {authState.role === 'ROLE_SUPER' &&
        <>
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={handleDeleteOpen}>
              <DeleteIcon/>
            </IconButton>
          </Tooltip>
          <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog}
                  open={deleteOpen} onClose={handleDeleteClose}
                  aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Delete {type
                ? type.toLowerCase() : ''}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <p>
                  Deletion {type ? 'of ' + type.toLowerCase() : ''} will fail if
                  it is used by other objects!
                </p>
                <p>
                  Are you sure?
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteConfirm} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    </>
  );
}

export default withStyles(styles)(DataGridActionBar)