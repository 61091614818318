import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  searchNonNvAgents, searchVillages
} from "../../utils/api";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {UserContext} from "../auth/UserProvider";
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as Yup from "yup";
import {useFormik} from "formik";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '1rem',
    right: '1rem',
  },
});

function AddCrsAgentDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [gender, setGender] = React.useState('FEMALE');
  const [intermediaryType, setIntermediaryType] = React.useState('NUTRITION_VOLUNTEER');
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const AgentSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    dateOfBirth: Yup.date().min(new Date("1901-01-01"), 'Date cannot be before 1901!').max(new Date(), 'Date cannot be in the future!').required('Required'),
    phoneNumber: Yup.string().required('Required'),
    village: Yup.object().nullable().required('Required'),
  });

  const agentFormik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phoneNumber: '',
      observer: null,
      village: null,
      nrcNumber: '',
    },
    validationSchema: AgentSchema,
  });

  const setFormFieldsTouched = async () => {
    await agentFormik.setFieldTouched('firstName', true);
    await agentFormik.setFieldTouched('lastName', true);
    await agentFormik.setFieldTouched('dateOfBirth', true);
    await agentFormik.setFieldTouched('phoneNumber', true);
    await agentFormik.setFieldTouched('village', true);
    await agentFormik.setFieldTouched('observer', true);
    await agentFormik.setFieldTouched('nrcNumber', true);
  }

  const handleClickOpen = async () => {
    if (entity) {
      await agentFormik.setFieldValue('firstName', entity.person.personalData.firstName);
      await agentFormik.setFieldValue('lastName', entity.person.personalData.lastName);
      setGender(entity && entity.person.personalData.gender ?
          entity.person.personalData.gender : 'FEMALE');
      setIntermediaryType(entity && entity.intermediaryType ?
          entity.intermediaryType : 'NUTRITION_VOLUNTEER');
      await agentFormik.setFieldValue('dateOfBirth', entity.person.personalData.dateOfBirth);
      await agentFormik.setFieldValue('phoneNumber', entity.person.personalData.phoneNumber);
      await agentFormik.setFieldValue('observer', entity.observer);
      await agentFormik.setFieldValue('village', entity.village);
      await agentFormik.setFieldValue('nrcNumber', entity.nrcNumber);
      await setFormFieldsTouched();
    }
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    await agentFormik.resetForm();
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(agentFormik.values, gender, intermediaryType) :
        confirmationHandler(entity.id, agentFormik.values, gender, intermediaryType);
    handleClose();
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const onChangeIntermediaryType = (event) => {
    setIntermediaryType(event.target.value);
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add agent
          </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'sm'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} Agent</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of agent
            </DialogContentText>

            <DescriptionList>
              <DescriptionTerm>Type</DescriptionTerm>
              <Description>
                <Select
                    autoFocus
                    labelId="role-select"
                    id="type-select"
                    value={intermediaryType}
                    variant="outlined"
                    className={classes.selectEmpty}
                    onChange={onChangeIntermediaryType}
                >
                  <MenuItem value={'NUTRITION_VOLUNTEER'}>Nutrition Volunteer</MenuItem>
                  <MenuItem value={'HEALTH_PROMOTER'}>Health Promoter</MenuItem>
                  <MenuItem value={'SUPERVISOR'}>Supervisor</MenuItem>
                </Select>
              </Description>
            </DescriptionList>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    margin="normal"
                    id="first-name"
                    label="First name"
                    type="text"
                    fullWidth
                    name="firstName"
                    variant={'outlined'}
                    value={agentFormik.values.firstName}
                    onChange={agentFormik.handleChange}
                    error={Boolean(agentFormik.errors.firstName)}
                    helperText={agentFormik.errors.firstName}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    margin="normal"
                    id="last-name"
                    label="Last name"
                    type="text"
                    fullWidth
                    name="lastName"
                    variant={'outlined'}
                    value={agentFormik.values.lastName}
                    onChange={agentFormik.handleChange}
                    error={Boolean(agentFormik.errors.lastName)}
                    helperText={agentFormik.errors.lastName}
                />
              </FormControl>
            </div>
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={gender} onChange={handleGenderChange}>
                  <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                  <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <TextField
                  margin="normal"
                  id="birthDate"
                  label="Date of birth"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="dateOfBirth"
                  variant={'outlined'}
                  value={agentFormik.values.dateOfBirth}
                  onChange={agentFormik.handleChange}
                  error={Boolean(agentFormik.errors.dateOfBirth)}
                  helperText={agentFormik.errors.dateOfBirth}
              />
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    margin="normal"
                    id="phone-number"
                    label="Phone number"
                    type="text"
                    fullWidth
                    name="phoneNumber"
                    variant={'outlined'}
                    value={agentFormik.values.phoneNumber}
                    onChange={agentFormik.handleChange}
                    error={Boolean(agentFormik.errors.phoneNumber)}
                    helperText={agentFormik.errors.phoneNumber}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    margin="normal"
                    id="nrc-number"
                    label="NRC number"
                    type="text"
                    fullWidth
                    name="nrcNumber"
                    variant={'outlined'}
                    value={agentFormik.values.nrcNumber}
                    onChange={agentFormik.handleChange}
                    error={Boolean(agentFormik.errors.nrcNumber)}
                    helperText={agentFormik.errors.nrcNumber}
                />
              </FormControl>
            </div>
            <BasicAutocomplete 
                handleChange={(value) => agentFormik.setFieldValue("village", value)} 
                searchFct={searchVillages} 
                label={'Village'} 
                property={'villageName'} 
                currentValue={agentFormik.values.village}
                error={Boolean(agentFormik.errors.village)}
                helperText={agentFormik.errors.village} />
            <BasicAutocomplete 
                handleChange={(value) => agentFormik.setFieldValue("observer", value)} 
                searchFct={searchNonNvAgents} 
                label={'Observing intermediary'} 
                property={'nutritionalVolunteer'} 
                currentValue={agentFormik.values.observer}
                error={Boolean(agentFormik.errors.observer)}
                helperText={agentFormik.errors.observer} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={!agentFormik.dirty || Object.keys(agentFormik.errors).length !== 0} onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddCrsAgentDialog)