import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  searchGizIntermediaries,
  searchTotTrainingSessions,
  searchTrainingBlocks,
} from "../../utils/api";
import {UserContext} from "../auth/UserProvider";
import Typography from "@material-ui/core/Typography";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddTrainingSessionParticipantDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [trainingSession, setTrainingSession] = React.useState(undefined);
  const [person, setPerson] = React.useState(undefined);
  const [intermediary, setIntermediary] = React.useState(null);
  const [trainingBlock, setTrainingBlock] = React.useState(null);
  const [sessionOptions, setSessionOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const handleClickOpen = () => {
    setTrainingSession(entity && entity.trainingSession ? entity.trainingSession : undefined);
    setPerson(entity && entity.person ? entity.person : undefined);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTrainingSession(undefined);
    setPerson('');
  };

  const handleConfirm = () => {
    action === 'create' ?
      confirmationHandler(trainingSession, person) : confirmationHandler(entity.id, trainingSession, person);
    handleClose();
  };

  const onChangeBeneficiary = value => {
    setPerson(value);
  };

  const onIntermediaryChange = value => {
    setIntermediary(value);
  };

  const onTrainingBlockChange = value => {
    setTrainingBlock(value);
  };

  React.useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchTotTrainingSessions('', intermediary ? intermediary.id : '', trainingBlock ? trainingBlock.id : '');

      if (active) {
        if (response.data) {
          setSessionOptions(response.data);
        } else {
          setSessionOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [intermediary, trainingBlock]);

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add Participant
          </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
            <Tooltip title="Edit">
              <IconButton aria-label="edit"
                          onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add Participant</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of participant
            </DialogContentText>
            <Typography color="inherit" variant="body1" component="div">
              To filter the sessions shown, select Trainer and Training Block beforehand
            </Typography>
            <BasicAutocomplete
                handleChange={onIntermediaryChange}
                searchFct={searchGizIntermediaries}
                label={'Trainer'}
                property={'intermediary'} />
            <BasicAutocomplete
                handleChange={onTrainingBlockChange}
                searchFct={searchTrainingBlocks}
                label={'Training Block'}
                property={'name'} />
            <br/>
            <Autocomplete
                disableCloseOnSelect
                id="sessions-multi-select"
                options={sessionOptions}
                getOptionLabel={(option) =>  `${option['trainingBlock'] ? option['trainingBlock'].name : ''} (Date: ${option.dateConducted})`}
                value={trainingSession}
                variant={'outlined'}
                name="trainingSession"
                onChange={(event, value) => setTrainingSession(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Session"
                    />
                )}
                onInputChange={async (event) => {
                  if (event) {
                    await searchTotTrainingSessions(event.target.value, intermediary ? intermediary.id : '', trainingBlock ? trainingBlock.id : '')
                        .then((response) => setSessionOptions(response.data));
                  }}
                }
            />
            <BasicAutocomplete handleChange={onChangeBeneficiary} searchFct={searchGizIntermediaries} label={'Participant'} property={'intermediary'} currentValue={entity ? entity.intermediary : undefined} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddTrainingSessionParticipantDialog)