export function formatAgentType(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'HEALTH_PROMOTER':
      return 'Health Promoter';
    case 'NUTRITION_VOLUNTEER':
      return 'Nutrition Volunteer';
    case 'SUPERVISOR':
      return 'Supervisor';
    default:
      return '';
  }
}