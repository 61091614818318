import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createTrainer,
  getTrainers
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import AddTrainerDialog from "../dialogs/AddTrainerDialog";
import {formatGender} from "../../utils/genderHelper";
import {formatTime} from "../../utils/timeHelper";
import CreationSnackbar from "../dialogs/CreationSnackbar";
import debounce from "lodash.debounce";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white',
    height: '88%',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function Trainers(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedTrainers, setPaginatedTrainers] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [successBarText, setSuccessBarText] = useState('');
  const [showSuccessBar, setShowSuccessBar] = useState(false);

  const cancelRequest = useRef();

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const addConfirmationHandler =
      async (firstName, lastName, gender, dateOfBirth, phoneNumber, additionalInfo) => {
        await createTrainer({
          'person': {
            'personalData': {
              'firstName': firstName,
              'lastName': lastName,
              'dateOfBirth': dateOfBirth,
              'phoneNumber': phoneNumber,
              'gender': gender,
            }
          },
          'additionalInfo': additionalInfo
        }).then((entity) => {
          setSuccessBarText(entity.data ? `Trainer ${entity.data.person.personalData.firstName} ${entity.data.person.personalData.lastName}` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getTrainers(page, size, searchTerm);
          const trainersData = result.data;

          if (!cancelRequest.current) {
            if (trainersData && Object.keys(trainersData).length > 0) {
              setPaginatedTrainers(trainersData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData()
  }, [searchTerm]);

  const handleSuccessBarState = (open) => {
    setShowSuccessBar(open);
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedTrainers) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Name, gender"
                id="trainer-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(event) => debouncedSetSearchTerm(event.target.value)}
            />
          </div>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={paginatedTrainers.content}
              columns={[
                {
                  field: "firstNames",
                  headerName: "First name(s)",
                  flex: 1,
                  renderCell: (params) => (
                      params.row.person.personalData.firstName
                  )
                },
                {
                  field: "lastName",
                  headerName: "Last name",
                  flex: 1,
                  renderCell: (params) => (
                      params.row.person.personalData.lastName
                  )
                },
                {
                  field: "gender",
                  headerName: "Gender",
                  flex: 1,
                  renderCell: (params) => (
                      formatGender(params.row.person.personalData.gender)
                  )
                },
                {
                  field: "dateOfBirth",
                  headerName: "Date of Birth",
                  flex: 1,
                  renderCell: (params) => (
                      formatTime(params.row.person.personalData.dateOfBirth)
                  )
                },
                {
                  field: "phoneNumber",
                  headerName: "Phone number",
                  flex: 1,
                  renderCell: (params) => (
                      params.row.person.personalData.phoneNumber
                  )
                },
                {
                  field: "additionalInfo",
                  headerName: "Additional info",
                  flex: 1.5,
                  renderCell: (params) =>
                      (params.row.additionalInfo)
                },
                {
                  field: 'actions',
                  headerName: 'Actions',
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <DataGridActionBar data={params.row}>
                    </DataGridActionBar>;
                  }
                }
              ]}
              density="compact"
              pagination
              pageSize={paginatedTrainers.size}
              rowCount={paginatedTrainers.totalElements}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
          <AddTrainerDialog confirmationHandler={addConfirmationHandler} />
          <CreationSnackbar successBarOpen={showSuccessBar} successBarHandler={handleSuccessBarState} successText={successBarText} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Trainers);