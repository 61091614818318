import React, {useEffect} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Modules from "../fbs/Modules";
import Courses from "../fbs/Courses";

const styles = (theme) => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  main: {
    flex: 1,
    height: '100%',
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          style={{height: '100%'}}
          {...other}
      >
        {value === index && (
            <div style={{height: '100%'}}>{children}</div>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function FarmerBusinessSchoolSettings(props) {
  const { classes, role } = props;
  const [value, setValue] = React.useState(0);

  let { tab } = useParams();
  let history = useHistory();

  const handleTabChange = (event, newValue) => {
    history.push(`/fbs-settings/${newValue}`);
    setValue(newValue);
  };

  const setTab = () => {
    setValue(parseInt(tab));
  }

  useEffect(() => {
    setTab();
  });

  const renderData = () => {
    return (
        <>
          <AppBar
              component="div"
              className={classes.appBar}
              color="primary"
              position="static"
              elevation={5}
          >
            <Tabs value={value} onChange={handleTabChange}>
              <Tab textColor="inherit" label="Modules" {...a11yProps(0)}/>
              <Tab textColor="inherit" label="Courses" {...a11yProps(1)}/>
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <main className={classes.main}>
              <Modules role={role} />
            </main>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <main className={classes.main}>
              <Courses role={role} />
            </main>
          </TabPanel>
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(FarmerBusinessSchoolSettings);