import React, {useContext, useEffect} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import {
  searchGizIntermediaries,
  searchGoodTypes,
  searchHouseholds
} from "../../utils/api";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {UserContext} from "../auth/UserProvider";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {Autocomplete} from "@material-ui/lab";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddGoodsDistributionDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [quantity, setQuantity] = React.useState(0);
  const [type, setType] = React.useState(undefined);
  const [types, setTypes] = React.useState([]);
  const [distributionDate, setDistributionDate] = React.useState(undefined);
  const [intermediary, setIntermediary] = React.useState(undefined);
  const [household, setHousehold] = React.useState(undefined);
  const [households, setHouseholds] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [goodTypeOptions, setGoodTypeOptions] = React.useState([]);
  const [householdOptions, setHouseholdOptions] = React.useState([]);

  const authState = useContext(UserContext);

  const handleClickOpen = () => {
    setQuantity(entity && entity.quantity ? entity.quantity : '');
    setType(entity && entity.goodType ?
        entity.goodType : '');
    setDistributionDate(entity && entity.dateDistributed ?
        entity.dateDistributed : '');
    setIntermediary(entity && entity.gizIntermediary ?
        entity.gizIntermediary : '');
    setHousehold(entity && entity.household ?
        entity.household : '');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setQuantity(0);
    setDistributionDate(undefined);
    setIntermediary(undefined);
    setTypes([]);
    setHousehold(undefined);
    setHouseholds([]);
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(quantity, types, intermediary, households, distributionDate) :
        confirmationHandler(entity.id, quantity, type, intermediary, household, distributionDate);
    handleClose();
  };

  useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchHouseholds('');

      if (active) {
        if (response.data) {
          setHouseholdOptions(response.data);
        } else {
          setHouseholdOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchGoodTypes('');

      if (active) {
        if (response.data) {
          setGoodTypeOptions(response.data);
        } else {
          setGoodTypeOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const onChangeType = value => {
    setType(value);
  };

  const onChangeIntermediary = value => {
    setIntermediary(value);
  };

  const onChangeHousehold = value => {
    setHousehold(value);
  };

  const handlePurchaseDateChange = (event) => {
    setDistributionDate(event.target.value);
  };

  const onInputValueChange = async (event) => {
    if (event) {
      await searchHouseholds(event.target.value)
          .then((response) => setHouseholdOptions(response.data));
    }
  }

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        {action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add Distribution
          </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} Goods Distribution</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the goods distribution
            </DialogContentText>
            { action === 'create' &&
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="type-multi-select"
                    options={goodTypeOptions}
                    getOptionLabel={(option) =>  `${option.name}`}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Types..."
                        />
                    )}
                    name="goodTypes"
                    variant={'outlined'}
                    value={types}
                    onChange={(event, value) => setTypes(value)}
                />
            }
            { action === 'edit' &&
                <BasicAutocomplete handleChange={onChangeType} searchFct={searchGoodTypes} label={'Type'} property={'name'} currentValue={type} />
            }
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="quantity"
                    label="Quantity"
                    type="number"
                    inputProps={{
                      step: ".1"
                    }}
                    value={quantity}
                    onChange={handleQuantityChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <BasicAutocomplete handleChange={onChangeIntermediary} searchFct={searchGizIntermediaries} label={'Intermediary'} property={'intermediary'} currentValue={intermediary} />
            { action === 'create' &&
              <Autocomplete
                  multiple
                disableCloseOnSelect
                  id="household-multi-select"
                  options={householdOptions}
                  getOptionLabel={(option) =>  `${option.fbNumber}`}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          variant="outlined"
                          label="Households..."
                      />
                  )}
                  name="households"
                  variant={'outlined'}
                  value={households}
                  onInputChange={onInputValueChange}
                  onChange={(event, value) => setHouseholds(value)}
                  style={{marginTop: 12}}
              />
            }
            { action === 'edit' &&
              <BasicAutocomplete handleChange={onChangeHousehold} searchFct={searchHouseholds} label={'Household'} property={'fbNumber'} currentValue={household} />
            }
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="purchaseDate"
                  label="Date distributed"
                  type="date"
                  value={distributionDate}
                  onChange={handlePurchaseDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddGoodsDistributionDialog)