export function formatReportingLevel(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'VILLAGE':
      return 'Village';
    case 'CAMP':
      return 'Camp';
    case 'WARD':
      return 'Ward';
    case 'DISTRICT':
      return 'District';
    default:
      return '';
  }
}