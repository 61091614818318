import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createUser, deleteUser, getUsers
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import AddUserDialog from "../dialogs/AddUserDialog";
import UserRoleChip from "../parts/UserRoleChip";
import { auth } from "../auth/firebase";
import ConfirmResetUserDialog from "../parts/ConfirmResetUserDialog";
import debounce from "lodash.debounce";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white',
    height: '88%',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function UserSettings(props) {
  const { classes, role } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [userList, setUserList] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const addConfirmationHandler =
      async (name, email, selectedRole, seniorLeadFarmer) => {
        let body = {
          'name': name,
          'email': email,
          'role': selectedRole
        };

        if (seniorLeadFarmer) {
          body.seniorLeadFarmer = seniorLeadFarmer;
        }

        await createUser(body).then(() => {
          auth.sendPasswordResetEmail(email);
        });
        await fetchData();
      };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getUsers(page, size, searchTerm);
          const rolesData = result.data;

          if (!cancelRequest.current) {
            if (rolesData && Object.keys(rolesData).length > 0) {
              setUserList(rolesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const removeUser = async (userId) => {
    await deleteUser(userId);
    await fetchData();
  };

  const resetUserPassword =
      async (email) => {
        await auth.sendPasswordResetEmail(email).then(() => {
        });
        await fetchData();
      };

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            Access denied!
          </>
      );
    }

    if (isLoading || !userList) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="User name or email"
                id="user-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={userList.content}
              columns={[
                { field: 'name', headerName: 'Name', flex: 1 },
                { field: 'email', headerName: 'Email', flex: 1 },
                { field: 'role', headerName: 'Role', flex: 1,
                  renderCell: (params) =>
                      <UserRoleChip role={params.row.role}/>
                },
                {
                  field: "seniorLeadFarmer",
                  headerName: "Senior Lead Farmer",
                  flex: 1,
                  renderCell: (params) =>
                      params.value ? `${params.value.person.personalData.firstName} ${params.value.person.personalData.lastName}` : ''
                },
                {
                  field: "dateRegistered",
                  headerName: "Creation date",
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated, 'dd.LL.yyyy HH:mm'))
                },
                {
                  field: 'actions',
                  headerName: 'Action',
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <>
                      {(role === 'ROLE_SUPER' || role === 'ROLE_STANDARD') &&
                        <>
                          <ConfirmResetUserDialog userEmail={params.row.email} confirmationHandler={resetUserPassword}/>
                          <DataGridActionBar
                              data={params.row}
                              type={'User'}
                              hideEdit={true}
                              deleteHandler={(userId) => removeUser(userId)}>
                          </DataGridActionBar>
                        </>
                      }
                      </>;
                  }
                }
              ]}
              density="compact"
              pagination
              rowCount={userList.totalElements}
              pageSize={userList.size}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
          <AddUserDialog
              confirmationHandler={addConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(UserSettings);