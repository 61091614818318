import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createCrsIecDistribution,
  getDistributedIecMaterials,
  getIecDistributionDump,
  updateCrsIecDistribution
} from "../../../utils/api";
import DataGridActionBar from "../../parts/DataGridActionBar";
import AddCrsIecDistributionDialog
  from "../../dialogs/AddCrsIecDistributionDialog";
import {UserContext} from "../../auth/UserProvider";
import FileDownload from "js-file-download";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/GetApp";
import DumpLoadingIndicator from "../../parts/DumpLoadingIndicator";
import CreationSnackbar from "../../dialogs/CreationSnackbar";
import debounce from "lodash.debounce";
import {formatTime} from "../../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '16rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function DistributedIECMaterials(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedDistributedIECMaterials, setPaginatedDistributedIECMaterials] = useState('');
  const [successBarText, setSuccessBarText] = useState('');
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [distributionBuffer, setDistributionBuffer] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const authState = useContext(UserContext);

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getIecDistributionDump().then((response) => {
            FileDownload(response.data, 'iec_distribution.csv');
            setIsLoadingDump(false);
          });
        } catch (error) {
          console.log(error);
        }
      };

  const addConfirmationHandler =
      async (values) => {
        let body = {
          'iecType': values.iecType,
          'household': values.household,
          'dateDistributed': values.dateDistributed,
          'crsAgent': values.agent
        };
        await createCrsIecDistribution(body).then((entity) => {
          setSuccessBarText(entity.data ? `IEC distribution of ${entity.data.iecType.nameIecType} for household ${entity.data.household.fbNumber}` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, values) => {
        let body = {
          'iecType': values.iecType,
          'household': values.household,
          'dateDistributed': values.dateDistributed,
          'crsAgent': values.agent
        };

        await updateDistributionAndDispatch(entityId, body);
        await fetchData();
      };

  const updateDistributionAndDispatch = async (distributionId, fields) => {
    try {
      let buffer = {
        ...distributionBuffer
      };

      setDistributionBuffer(buffer);

      const { data: data } = await updateCrsIecDistribution(
          distributionId,
          fields
      );

      if (!cancelRequest.current) {
        setDistributionBuffer(data);
      }
    } catch (error) {
      setDistributionBuffer(distributionBuffer);
    }
  };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getDistributedIecMaterials(page, size, searchTerm);
          const iecMaterialsData = result.data;

          if (!cancelRequest.current) {
            if (iecMaterialsData && Object.keys(iecMaterialsData).length > 0) {
              setPaginatedDistributedIECMaterials(iecMaterialsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const handleSuccessBarState = (open) => {
    setShowSuccessBar(open);
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedDistributedIECMaterials) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
      <>
        <div className={classes.contentWrapper} style={{}}>
          <TextField
              label="Type, FB number"
              id="iec-distribution-filter"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleSearchTermChanged}
          />
        </div>
        <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={paginatedDistributedIECMaterials.content}
              columns={[
                { field: 'monthDistributed', headerName: 'Month distributed' },
                { field: 'yearDistributed', headerName: 'Year distributed' },
                { field: 'iecType', headerName: 'IEC Type',
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.iecType.nameIecType)
                },
                {
                  field: "fbNumber",
                  headerName: "Household FB number",
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.household ? params.row.household.fbNumber : '')

                },
                {
                  field: "agent",
                  headerName: "Agent",
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.crsAgent ?
                              params.row.crsAgent.person.personalData.firstName +
                              ' ' + params.row.crsAgent.person.personalData.lastName
                              : ''
                      )
                },
                { field: 'dateCreated', headerName: 'Date created',
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated))
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <>
                      <AddCrsIecDistributionDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                      <DataGridActionBar data={params.row}>
                      </DataGridActionBar>
                    </>;
                  }
                }
              ]}
              density="compact"
              pagination
              pageSize={paginatedDistributedIECMaterials.size}
              rowCount={paginatedDistributedIECMaterials.totalElements}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
        </div>
        <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
          <Fab
              size="small"
              color="primary"
              hidden={!isLoadingDump}
              aria-label="add"
              onClick={() => fetchDump()}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <DownloadIcon/>
          </Fab>
        </div>
        <AddCrsIecDistributionDialog
            confirmationHandler={addConfirmationHandler} />
        <CreationSnackbar successBarOpen={showSuccessBar} successBarHandler={handleSuccessBarState} successText={successBarText} />
      </>
    );
  };

  return renderData();
}

export default withStyles(styles)(DistributedIECMaterials);