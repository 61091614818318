import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createGoodCategory, deleteGoodCateogory,
  getGoodCategories, updateGoodCateogory
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import AddGoodsCategoryDialog from "../dialogs/AddGoodsCategoryDialog";
import debounce from "lodash.debounce";
import {formatTime} from "../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function GoodsCategories(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedGoodsCategories, setPaginatedGoodsCategories] = useState('');
  const [goodsCategoryBuffer, setGoodsCategoryBuffer] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const addGoodsCategoryConfirmationHandler =
      async (categoryName) => {
        await createGoodCategory({
          'name': categoryName,
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (categoryId, categoryName) => {
        await updateGoodsCategoryDataAndDispatch(categoryId, {
          'name': categoryName,
        });
        await fetchData();
      };

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (categoryId, deleteType = 'SOFT') => {
    await deleteGoodCateogory(categoryId, deleteType);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getGoodCategories(page, size, searchTerm);
          const categoriesData = result.data;

          if (!cancelRequest.current) {
            if (categoriesData && Object.keys(categoriesData).length > 0) {
              setPaginatedGoodsCategories(categoriesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const updateGoodsCategoryDataAndDispatch = async (categoryId, fields) => {
    try {
      let buffer = {
        ...goodsCategoryBuffer
      };

      setGoodsCategoryBuffer(buffer);

      const { data: data } = await updateGoodCateogory(
          categoryId,
          fields
      );

      if (!cancelRequest.current) {
        setGoodsCategoryBuffer(data);
      }
    } catch (error) {
      setGoodsCategoryBuffer(goodsCategoryBuffer);
    }
  };

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedGoodsCategories) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Category name"
                id="category-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedGoodsCategories.content}
                getRowClassName={(params) => `row-${params.row.deleted === true ? 'deleted' : 'active'}`}
                columns={[
                  { field: 'name', headerName: 'Name', flex: 1 },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                        <AddGoodsCategoryDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                        <DataGridActionBar
                            data={params.row}
                            type={'Good category'}
                            deleteHandler={removeHandler}
                            relations={[
                              'Good type',
                            ]}>
                        </DataGridActionBar>
                      </>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedGoodsCategories.size}
                rowCount={paginatedGoodsCategories.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <AddGoodsCategoryDialog
              confirmationHandler={addGoodsCategoryConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(GoodsCategories);