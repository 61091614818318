import React, {useCallback, useEffect, useRef, useState} from 'react';
import { CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createTrainingLesson,
  deleteTrainingLesson,
  getTrainingLessons, updateTrainingLesson
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import AddTrainingLessonDialog from "../dialogs/AddTrainingLessonDialog";
import debounce from "lodash.debounce";
import TextField from "@material-ui/core/TextField";
import {formatTime} from "../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function TrainingLessonSettings(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedTrainingLessons, setPaginatedTrainingLessons] = useState('');
  const [lessonBuffer, setLessonBuffer] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const addTrainingLessonConfirmationHandler =
      async (name, additionalInfo) => {
        await createTrainingLesson({
          'name': name,
          'additionalInfo': additionalInfo,
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (cropId, name, additionalInfo) => {
        await updateCropDataAndDispatch(cropId, {
          'name': name,
          'additionalInfo': additionalInfo,
        });
        await fetchData();
      };

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (lessonId, deleteType = 'SOFT') => {
    await deleteTrainingLesson(lessonId, deleteType);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getTrainingLessons(page, size, searchTerm);
          const trainingLessonsData = result.data;

          if (!cancelRequest.current) {
            if (trainingLessonsData && Object.keys(trainingLessonsData).length > 0) {
              setPaginatedTrainingLessons(trainingLessonsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const updateCropDataAndDispatch = async (lessonId, fields) => {
    try {
      let buffer = {
        ...lessonBuffer
      };

      setLessonBuffer(buffer);

      const { data: lessonData } = await updateTrainingLesson(
          lessonId,
          fields
      );

      if (!cancelRequest.current) {
        setLessonBuffer(lessonData);
      }
    } catch (error) {
      setLessonBuffer(lessonBuffer);
    }
  };

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedTrainingLessons) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Lesson name"
                id="lesson-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={paginatedTrainingLessons.content}
              getRowClassName={(params) => `row-${params.row.deleted === true ? 'deleted' : 'active'}`}
              columns={[
                { field: 'name', headerName: 'Name', flex: 1 },
                { field: 'additionalInfo', headerName: 'Additional info', flex: 1 },
                { field: 'dateCreated', headerName: 'Date created',
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated))
                },
                {
                  field: 'actions',
                  headerName: 'Action',
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <>
                      <AddTrainingLessonDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                      <DataGridActionBar
                          data={params.row}
                          type={'Training Lesson'}
                          deleteHandler={removeHandler}
                          relations={[
                            'Training session',
                          ]}/>
                    </>;
                  }
                }
              ]}
              density="compact"
              pagination
              pageSize={paginatedTrainingLessons.size}
              rowCount={paginatedTrainingLessons.totalElements}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
          <AddTrainingLessonDialog
              confirmationHandler={addTrainingLessonConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(TrainingLessonSettings);