import React, {useEffect, useRef, useState} from 'react';
import {
  AppBar,
  CircularProgress, Collapse, IconButton, Paper,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead, TableRow, TextField, Typography
} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {
  createFarmingGroupWithMultipleMembers,
  getAppUsers, updateLeadFarmerObservations
} from "../../utils/api";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Toolbar from "@material-ui/core/Toolbar";
import AddLeadFarmerDialog from "../dialogs/AddLeadFarmerDialog";
import AddLeadFarmerHouseholdDialog
  from "../dialogs/AddLeadFarmerHouseholdDialog";

const styles = () => ({
  contentWrapper: {
    marginBottom: '1rem'
  },
  dataGrid: {
    backgroundColor: 'white',
    height: '88%',
  }
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  gridList: {
    paddingBottom: '1rem',
  },
  paper: {
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function HouseholdRow(props) {
  let {
    hh,
  } = props;
  return (
      <>
        <TableRow>
          <TableCell>{hh.fbNumber}</TableCell>
          <TableCell>
            <ul>
              { hh.beneficiaries && hh.beneficiaries.map(ben =>
                  <li>{ben.firstName} {ben.lastName}</li>
              )}
            </ul>
          </TableCell>
        </TableRow>
      </>
  );
}

function LeadFarmerRow(props) {
  let {
    shouldOpen,
    lf,
    keyId,
  } = props;
  const [open, setOpen] = useState(shouldOpen);
  const [households, setHouseholds] = useState(lf.households);
  const classes = useRowStyles();


  const addConfirmationHandler =
      async (households) => {
        let body = {
          'gizIntermediary': { id: lf.id },
          'households': households,
        }

        await createFarmingGroupWithMultipleMembers(body).then((entity ) => {
          entity.data.forEach(fgm => {
            setHouseholds(current => [...current, fgm.household])
          });
        });
      };

  return (
      <>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{lf.firstName} {lf.lastName}</TableCell>
          <TableCell>{households.length}</TableCell>
          <TableCell>
            <AddLeadFarmerHouseholdDialog confirmationHandler={addConfirmationHandler} />
          </TableCell>
        </TableRow>
        <TableRow key={`${keyId}-expanded-lf`} >
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
            <Collapse style={{marginBottom: '1rem'}} in={open} timeout="auto" unmountOnExit>
              <div className={classes.contentWrapper}>
                <Table aria-label="collapsible lead farmer table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell>FB number</TableCell>
                      <TableCell>Beneficiaries</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {households && households.map((hh) => (
                        <HouseholdRow key={hh.id} hh={hh} keyId={hh.id} />
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
  );
}

function Row(props) {
  let {
    shouldOpen,
    user,
    keyId,
  } = props;
  const [open, setOpen] = useState(shouldOpen);
  const [leadFarmers, setLeadFarmers] = useState(user.leadFarmers);
  const classes = useRowStyles();

  const addConfirmationHandler =
      async (leadFarmers) => {
        await updateLeadFarmerObservations(user.seniorLeadFarmer.id, leadFarmers.map(lf => lf.id)).then((entity ) => {
          entity.data.forEach(lf => {
            setLeadFarmers(current => [...current,
                {
                  firstName: lf.person.personalData.firstName,
                  lastName: lf.person.personalData.lastName,
                  households: [],
                }]
            )
          });
        });
      };

  return (
      <>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{user.name}</TableCell>
          <TableCell>{user.seniorLeadFarmer ? `${user.seniorLeadFarmer.person.personalData.firstName} ${user.seniorLeadFarmer.person.personalData.lastName}` : ''}</TableCell>
        </TableRow>
        <TableRow key={`${keyId}-expanded`} >
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse style={{marginBottom: '1rem'}} in={open} timeout="auto" unmountOnExit>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div" style={{ flexGrow: 1 }}>
                      {user.seniorLeadFarmer ? `${user.seniorLeadFarmer.person.personalData.firstName} ${user.seniorLeadFarmer.person.personalData.lastName}` : ''}
                    </Typography>
                    <AddLeadFarmerDialog confirmationHandler={addConfirmationHandler}/>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <Table aria-label="collapsible lead farmer table">
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell/>
                        <TableCell style={{ width: '45%'}}>Name</TableCell>
                        <TableCell style={{ width: '45%'}}>Households</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {leadFarmers && leadFarmers.map((lf) => (
                          <LeadFarmerRow key={lf.id} lf={lf} keyId={lf.id} />
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Paper>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
  );
}

function AppUserSettings(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [userList, setUserList] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  const cancelRequest = useRef();

  const fetchData =
      async (loading) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getAppUsers();
          const rolesData = result.data;

          if (!cancelRequest.current) {
            if (rolesData && Object.keys(rolesData).length > 0) {
              setUserList(rolesData);
              setFilteredUsers(rolesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            Access denied!
          </>
      );
    }

    if (isLoading || !userList) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    const handleSearchTermChanged = (event) => {
      const term = event.target.value;
      setFilteredUsers(userList.filter(user => {
        let nameTerm = false;

        if (user.name) {
          nameTerm = user.name.toLowerCase().includes(term.toLowerCase());
        }

        return nameTerm;
      }));
    };

    return (
        <>
          <div className={classes.contentWrapper} style={{width: '95%'}}>
            <TextField
                label="Name"
                id="activity-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <TableContainer style={{width: '95%'}} component={Paper} className={classes.tableContainer}>
            <Table aria-label="collapsible table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell/>
                  <TableCell>Name</TableCell>
                  <TableCell>SLF</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((user) => (
                    <Row key={user.id} user={user} keyId={user.id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(AppUserSettings);