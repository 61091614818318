import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import {withStyles} from "@material-ui/core/styles";
import {
  generateReport,
  searchCamps,
  searchDistricts, searchGizIntermediaries, searchVillages,
  searchWards
} from "../../utils/api";
import Button from "@material-ui/core/Button";
import {CircularProgress} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import XLSX from "xlsx";
import {formatTime, getAge} from "../../utils/timeHelper";
import Typography from "@material-ui/core/Typography";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {formatReportingLevel} from "../../utils/reportingLevelHelper";
import {formatGender} from "../../utils/genderHelper";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
});

function GizReport(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [reportType, setReportType] = useState('TRAINING_REPORT');
  const [observer, setObserver] = React.useState(null);
  const [district, setDistrict] = React.useState(null);
  const [ward, setWard] = React.useState(null);
  const [camp, setCamp] = React.useState(null);
  const [village, setVillage] = React.useState(null);

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleReportType = (event) => {
    setReportType(event.target.value);
  };

  const downloadTrainingReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Training Report');

    let ws_data = [
      ['Training Report'],
      [`From: ${data.fromDate}`],
      [`To: ${data.toDate}`],
      [],
    ];

    ws_data.push(['Number of people trained in good agricultural practices']);
    ws_data.push([]);
    ws_data.push(['District', 'Female', 'Male', 'Total', 'Cumulative Females', 'Cumulative Males', 'Cumulative Total',]);

    data.beneficiaryTrainingDistricts.map(d => {
      ws_data.push([d.name, d.females, d.males, d.males + d.females,
        d.cumulativeFemales, d.cumulativeMales,
        d.cumulativeFemales + d.cumulativeMales]);
    });
    ws_data.push(['', '', '', data.beneficiaryTrainingTotal, '', '', data.beneficiaryTrainingCumulativeTotal]);
    ws_data.push([]);

    wb.Sheets['Training Report'] = XLSX.utils.aoa_to_sheet(ws_data);

    XLSX.writeFile(wb,
        `Beneficiary_Training_Report_${formatTime(data.fromDate, 'yyyy-LL-dd')}-${formatTime(data.toDate, 'yyyy-LL-dd')}.xlsx`);
  }

  const downloadInputReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Input Report');

    let ws_data = [
      ['Input Report'],
      [`From: ${data.fromDate}`],
      [`To: ${data.toDate}`],
      [],
    ];

    ws_data.push(['Number of households having received agricultural inputs in timeframe']);
    ws_data.push([]);
    let headerRow = ['District'];
    data.availableGoodTypes.map(type => {
      headerRow.push(type.name);
    });
    headerRow.push('Total (at least one input)');
    ws_data.push(headerRow);

    data.beneficiaryInputDistricts.map(d => {
      let districtRow = [d.name];

      data.availableGoodTypes.forEach(goodType => {
        let amount = 0;

        d.beneficiaryInputs.forEach(receivedInput => {
          if (goodType.name === receivedInput.name) {
            amount = receivedInput.amount;
          }
        })

        districtRow.push(amount);
      });

      districtRow.push(d.numWithInput);

      ws_data.push(districtRow);
    });
    ws_data.push([]);

    ws_data.push(['Cumulative number of households having received agricultural inputs']);
    ws_data.push([]);

    ws_data.push(headerRow);

    data.beneficiaryInputDistricts.map(d => {
      let districtRow = [d.name];

      data.availableGoodTypes.forEach(goodType => {
        let amount = 0;

        d.beneficiaryInputs.forEach(receivedInput => {
          if (goodType.name === receivedInput.name) {
            amount = receivedInput.cumAmount;
          }
        });

        districtRow.push(amount);
      })

      districtRow.push(d.cumNumWithInput);

      ws_data.push(districtRow);
    });

    wb.Sheets['Input Report'] = XLSX.utils.aoa_to_sheet(ws_data);

    XLSX.writeFile(wb,
        `Beneficiary_Input_Report_${formatTime(data.fromDate, 'yyyy-LL-dd')}-${formatTime(data.toDate, 'yyyy-LL-dd')}.xlsx`);
  }

  const downloadKeyOutputReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Key Output Report');

    let ws_data = [
      ['Key Output Report'],
      [`From: ${data.fromDate}`],
      [`To: ${data.toDate}`],
      [],
    ];

    ws_data.push(['Number of  household key outputs in timeframe']);
    ws_data.push([]);
    let headerRow = ['District'];
    data.availableKeyElements.map(type => {
      headerRow.push(type.name);
    });
    ws_data.push(headerRow);

    data.householdKeyOutputDistricts.map(d => {
      let districtRow = [d.name];

      data.availableKeyElements.forEach(outputType => {
        let amount = 0;

        d.householdKeyOutputs.forEach(producedOutput => {
          if (outputType.name === producedOutput.name) {
            amount = producedOutput.amount;
          }
        });

        districtRow.push(amount);
      })

      ws_data.push(districtRow);
    });
    ws_data.push([]);

    ws_data.push(['Cumulative number of household key outputs']);
    ws_data.push([]);

    ws_data.push(headerRow);

    data.householdKeyOutputDistricts.map(d => {
      let districtRow = [d.name];

      data.availableKeyElements.forEach(outputType => {
        let amount = 0;

        d.householdKeyOutputs.forEach(producedOutput => {
          if (outputType.name === producedOutput.name) {
            amount = producedOutput.cumAmount;
          }
        });

        districtRow.push(amount);
      })

      ws_data.push(districtRow);
    });

    wb.Sheets['Key Output Report'] = XLSX.utils.aoa_to_sheet(ws_data);

    XLSX.writeFile(wb,
        `Key_Output_Report_${formatTime(data.fromDate, 'yyyy-LL-dd')}-${formatTime(data.toDate, 'yyyy-LL-dd')}.xlsx`);
  }

  const downloadDiverseTrainingReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Agri WASH and Nutrition Report');

    let ws_data = [
      ['Agri WASH and Nutrition Report'],
      [`From: ${data.fromDate}`],
      [`To: ${data.toDate}`],
      [],
    ];

    let headerRow = ['District', 'Female', 'Male', '', 'Cumulative Female', 'Cumulative Male'];

    ws_data.push(['AgriLight Training']);
    ws_data.push([]);
    ws_data.push(headerRow);

    data.beneficiaryDiverseTrainingDistricts.map(d => {
      ws_data.push([d.name, d.femalesAgrilight, d.malesAgrilight, '',
        d.cumulativeFemalesAgrilight, d.cumulativeMalesAgrilight]);
    });
    ws_data.push([]);

    ws_data.push(['AgriIntensive Training']);
    ws_data.push([]);
    ws_data.push(headerRow);

    data.beneficiaryDiverseTrainingDistricts.map(d => {
      ws_data.push([d.name, d.femalesAgriintensive, d.malesAgriintensive, '',
        d.cumulativeFemalesAgriintensive, d.cumulativeMalesAgriintensive]);
    });
    ws_data.push([]);

    ws_data.push(['WASH Training']);
    ws_data.push([]);
    ws_data.push(headerRow);

    data.beneficiaryDiverseTrainingDistricts.map(d => {
      ws_data.push([d.name, d.femalesWash, d.malesWash, '',
        d.cumulativeFemalesWash, d.cumulativeMalesWash]);
    });
    ws_data.push([]);

    ws_data.push(['Nutrition Training']);
    ws_data.push([]);
    ws_data.push(headerRow);

    data.beneficiaryDiverseTrainingDistricts.map(d => {
      ws_data.push([d.name, d.femalesNutrition, d.malesNutrition, '',
        d.cumulativeFemalesNutrition, d.cumulativeMalesNutrition]);
    });
    ws_data.push([]);

    wb.Sheets['Agri WASH and Nutrition Report'] = XLSX.utils.aoa_to_sheet(ws_data);

    XLSX.writeFile(wb,
        `AgriLight_Intensive_WASH_Nutrition_Report_${formatTime(data.fromDate, 'yyyy-LL-dd')}-${formatTime(data.toDate, 'yyyy-LL-dd')}.xlsx`);
  }

  const downloadTrainingOfTrainersReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Training of Trainers Report');

    let ws_data = [
      ['Training of Trainers Report'],
      [`From: ${data.fromDate}`],
      [`To: ${data.toDate}`],
      [],
    ];

    ws_data.push(['Number of trainers trained (ToT)']);
    ws_data.push([]);
    ws_data.push(['Senior Lead Farmers (SLF)']);
    ws_data.push(['District', 'Female', 'Male', 'Total', 'Cumulative Females', 'Cumulative Males', 'Cumulative Total',]);
    data.trainingOfTrainersDistricts.map(d => {
      ws_data.push([d.name, d.slfsFemales, d.slfsMales,
        d.slfsMales + d.slfsFemales,
        d.slfsCumulativeFemales, d.slfsCumulativeMales,
        d.slfsCumulativeFemales + d.slfsCumulativeMales]);
    });
    ws_data.push(['', data.trainingOfTrainersSlfsTotalFemales,
      data.trainingOfTrainersSlfsTotalMales,
      data.trainingOfTrainersSlfsTotalFemales + data.trainingOfTrainersSlfsTotalMales,
      data.trainingOfTrainersSlfsCumulativeTotalFemales,
      data.trainingOfTrainersSlfsCumulativeTotalMales, data.trainingOfTrainersSlfsCumulativeTotalFemales + data.trainingOfTrainersSlfsCumulativeTotalMales]);

    ws_data.push([]);
    ws_data.push(['Lead Farmers (LF)']);
    ws_data.push(['District', 'Female', 'Male', 'Total', 'Cumulative Females', 'Cumulative Males', 'Cumulative Total',]);
    data.trainingOfTrainersDistricts.map(d => {
      ws_data.push([d.name, d.lfsFemales, d.lfsMales,
        d.lfsMales + d.lfsFemales,
        d.lfsCumulativeFemales, d.lfsCumulativeMales,
        d.lfsCumulativeFemales + d.lfsCumulativeMales]);
    });
    ws_data.push(['', data.trainingOfTrainersLfsTotalFemales,
      data.trainingOfTrainersLfsTotalMales,
      data.trainingOfTrainersLfsTotalFemales + data.trainingOfTrainersLfsTotalMales,
      data.trainingOfTrainersLfsCumulativeTotalFemales,
      data.trainingOfTrainersLfsCumulativeTotalMales,
      data.trainingOfTrainersLfsCumulativeTotalFemales + data.trainingOfTrainersLfsCumulativeTotalMales]);
    ws_data.push([]);

    wb.Sheets['Training of Trainers Report'] = XLSX.utils.aoa_to_sheet(ws_data);

    XLSX.writeFile(wb,
        `ToT_Report_${formatTime(data.fromDate, 'yyyy-LL-dd')}-${formatTime(data.toDate, 'yyyy-LL-dd')}.xlsx`);
  }

  const downloadFbsTrainingReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('FBS Training Report');

    let ws_data = [
      ['FBS Training Report'],
      [`From: ${data.fromDate}`],
      [`To: ${data.toDate}`],
      [],
    ];

    ws_data.push(['Number of farmers trained in farmer business school (FBS)']);
    ws_data.push([]);
    ws_data.push(['District', 'Female', 'Male', 'Total', 'Cumulative Females', 'Cumulative Males', 'Cumulative Total',]);
    data.fbsTrainingDistricts.map(d => {
      ws_data.push([d.name, d.females, d.males, d.males + d.females,
        d.cumulativeFemales, d.cumulativeMales,
        d.cumulativeFemales + d.cumulativeMales]);
    });
    ws_data.push(['', data.fbsTrainingTotalFemales,
      data.fbsTrainingTotalMales,
      data.fbsTrainingTotalFemales + data.fbsTrainingTotalMales,
      data.fbsTrainingCumulativeTotalFemales,
      data.fbsTrainingCumulativeTotalMales,
      data.fbsTrainingCumulativeTotalFemales + data.fbsTrainingCumulativeTotalMales]);

    wb.Sheets['FBS Training Report'] = XLSX.utils.aoa_to_sheet(ws_data);

    XLSX.writeFile(wb,
        `FBS_Training_Report_${formatTime(data.fromDate, 'yyyy-LL-dd')}-${formatTime(data.toDate, 'yyyy-LL-dd')}.xlsx`);
  }

  const downloadMoAReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Ministry of Agriculture Report');

    let ws_data = [
        ['Ministry of Agriculture Report'],
        [`From: ${data.fromDate}`],
        [`To: ${data.toDate}`],
        [],
        ['Number of intermediaries trained in nutrition sensitive agriculture by gender.'],
        ['Women', data.femaleIntermediariesTrainedNsa],
        ['Men', data.maleIntermediariesTrainedNsa],
        ['Number of beneficiaries trained in diverse agricultural production:'],
        ['Number of households trained in food preservation and storage techniques promoted by project:'],
        ['Number of women and additional men reached with hygiene, food and nutrition messages.'],
        ['Women'],
        ['Men'],
        ['Number households engaged in SILC:'],
        ['Number of beneficiaries trained in FBS:'],
        ['Number of HHs educated under FBS that apply 30% of the 10 key practices for joint decision-making and resource planning at household level:'],
    ];

    wb.Sheets['Ministry of Agriculture Report'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `MoA_report_${formatTime(data.fromDate, 'yyyy-LL-dd')}-${formatTime(data.toDate, 'yyyy-LL-dd')}.xlsx`);
  };

  const downloadPostHarvestTrainingReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Post Harvest Training Report');

    let ws_data = [
      ['Post Harvest Training Report'],
      [`From: ${data.fromDate}`],
      [`To: ${data.toDate}`],
      [],
    ];

    ws_data.push(['Number of people who received post harvest training']);
    ws_data.push([]);
    ws_data.push(['District', 'Female', 'Male', 'Total', 'Cumulative Females', 'Cumulative Males', 'Cumulative Total',]);

    data.postHarvestTrainingDistricts.map(d => {
      ws_data.push([d.name, d.females, d.males, d.total,
        d.cumulativeFemales, d.cumulativeMales,
        d.cumulativeTotal]);
    });

    ws_data.push(['', '', '', data.postHarvestTrainingTotal, '', '', data.postHarvestTrainingCumulativeTotal]);
    ws_data.push([]);

    wb.Sheets['Post Harvest Training Report'] = XLSX.utils.aoa_to_sheet(ws_data);

    XLSX.writeFile(wb,
        `Post_Harvest_Training_Report_${formatTime(data.fromDate, 'yyyy-LL-dd')}-${formatTime(data.toDate, 'yyyy-LL-dd')}.xlsx`);
  }

  const downloadIntermediaryListReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Intermediary Report');

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let headerRow = ['District', 'Ward', 'Camp', 'Village', 'Name', 'Date of birth', 'Age', 'Gender', 'Type', 'Observing intermediary'];

    let ws_data = [
      ['Intermediary Report'],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      ['Total intermediaries:', data.intermediaries.length],
      [],
      headerRow,
    ];

    data.intermediaries.map(i => {
      let intermediaryRow = [
        i.ward ? i.ward.district.districtName : '',
        i.ward ? i.ward.wardName : '',
        i.village ? i.village.camp.campName : '',
        i.village ? i.village.villageName : '',
        `${i.person.personalData.firstName} ${i.person.personalData.lastName}`,
        `${i.person.personalData.dateOfBirth ? formatTime(i.person.personalData.dateOfBirth) : ''}`,
        `${i.person.personalData.dateOfBirth ? getAge(i.person.personalData.dateOfBirth) : ''}`,
        `${formatGender(i.person.personalData.gender)}`,
        `${i.intermediaryType.nameIntermediaryType}`,
        `${i.observingIntermediary ? 
            i.observingIntermediary.person.personalData.firstName + ' ' + 
            i.observingIntermediary.person.personalData.lastName : ''}`,
      ];

      ws_data.push(intermediaryRow);
    });

    wb.Sheets['Intermediary Report'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `Intermediary_Report_${reportingLevel}_${data.reportingLevelName}.xlsx`);
  };

  const downloadFarmingGroupReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Farming Group Report');

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let headerRow = ['District', 'Ward', 'Camp', 'Village', 'FB number', 'Beneficiary', 'Lead Farmer', 'Senior Lead Farmer'];

    let ws_data = [
      ['Farming Group Report'],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      ['Total lead farmers:', data.leadFarmers.length],
      [],
      headerRow,
    ];

    data.leadFarmers.map(i => {
      i.households.map(h => {
        h.beneficiaries.map(b => {
          let beneficiaryRow = [
            h.addressData.village ? h.addressData.village.camp.ward.district.districtName : '',
            h.addressData.village ? h.addressData.village.camp.ward.wardName : '',
            h.addressData.village ? h.addressData.village.camp.campName : '',
            h.addressData.village ? h.addressData.village.villageName : '',
            h.fbNumber,
            `${b.firstName} ${b.lastName}`,
            `${i.firstName} ${i.lastName}`,
            `${i.observingIntermediary ?
                i.observingIntermediary.person.personalData.firstName + ' ' +
                i.observingIntermediary.person.personalData.lastName : ''}`,
          ];

          ws_data.push(beneficiaryRow);
        });
      });
    });

    wb.Sheets['Farming Group Report'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `Farming_Group_Report_${reportingLevel}_${data.reportingLevelName}.xlsx`);
  };

  const downloadPracticeApplicationCountReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push(`Practices Report (Count)`);

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let headerRow = [`${reportingLevel}`];

    let countRow = [data.reportingLevelName];

    headerRow.push('Number of applied practices');
    countRow.push(data.numberPracticeApplications);

    let ws_data = [
      [`Practice Applications Report (Count)`],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      headerRow,
      countRow
    ];

    wb.Sheets[`Practices Report (Count)`] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `Practice_Applications_Count_Report_${reportingLevel}_${data.reportingLevelName}.xlsx`);
  };

  const downloadPracticeApplicationListReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push(`Practices Report (List)`);

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let countRow = [data.reportingLevelName];

    let headerRow = ['District', 'Ward', 'Camp', 'Village', 'FB number', 'Practice', 'Date of visit', 'Lead Farmer'];

    countRow.push(data.numberPracticeApplications);

    let ws_data = [
      [`Practice Applications Report (List)`],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      headerRow,
      countRow
    ];

    data.practiceApplications.map(pa => {
      let beneficiaryRow = [
        pa.siteVisit.household.addressData.village ? pa.siteVisit.household.addressData.village.camp.ward.district.districtName : '',
        pa.siteVisit.household.addressData.village ? pa.siteVisit.household.addressData.village.camp.ward.wardName : '',
        pa.siteVisit.household.addressData.village ? pa.siteVisit.household.addressData.village.camp.campName : '',
        pa.siteVisit.household.addressData.village ? pa.siteVisit.household.addressData.village.villageName : '',
        pa.siteVisit.household.fbNumber,
        pa.practice.name,
        formatTime(pa.siteVisit.dateVisited),
        `${pa.siteVisit.gizIntermediary ?
            pa.siteVisit.gizIntermediary.person.personalData.firstName + ' ' +
            pa.siteVisit.gizIntermediary.person.personalData.lastName : ''}`,
      ];

      ws_data.push(beneficiaryRow);
    });

    wb.Sheets[`Practices Report (List)`] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `Practice_Applications_List_Report_${reportingLevel}_${data.reportingLevelName}.xlsx`);
  };

  const fetchReport =
      async (loading, from, to, observer, district, ward, camp, village) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          await generateReport({
            'fromDate': from,
            'toDate': to,
            'reportType': reportType,
            'observer': observer,
            'district': district,
            'ward': ward,
            'camp': camp,
            'village': village
          }).then((response) => {
            setIsLoading(false);
            if (response.data && response.data.reportType === 'MOA_REPORT') {
              downloadMoAReport(response.data);
            }
            if (response.data && response.data.reportType === 'TRAINING_REPORT') {
              downloadTrainingReport(response.data);
            }
            if (response.data && response.data.reportType === 'INPUT_REPORT') {
              downloadInputReport(response.data);
            }
            if (response.data && response.data.reportType === 'KEY_OUTPUT_REPORT') {
              downloadKeyOutputReport(response.data);
            }
            if (response.data && response.data.reportType === 'DIVERSE_TRAINING_REPORT') {
              downloadDiverseTrainingReport(response.data);
            }
            if (response.data && response.data.reportType === 'TRAINING_OF_TRAINERS_REPORT') {
              downloadTrainingOfTrainersReport(response.data);
            }
            if (response.data && response.data.reportType === 'FBS_TRAINING_REPORT') {
              downloadFbsTrainingReport(response.data);
            }
            if (response.data && response.data.reportType === 'POST_HARVEST_TRAINING_REPORT') {
              downloadPostHarvestTrainingReport(response.data);
            }
            if (response.data && response.data.reportType === 'GIZ_INTERMEDIARY_REPORT') {
              downloadIntermediaryListReport(response.data);
            }
            if (response.data && response.data.reportType === 'PRACTICES_APPLIED_LIST_REPORT') {
              downloadPracticeApplicationListReport(response.data);
            }
            if (response.data && response.data.reportType === 'PRACTICES_APPLIED_COUNT_REPORT') {
              downloadPracticeApplicationCountReport(response.data);
            }
          });
        } catch (error) {
          console.log(error);
        }
      };

  const renderData = () => {
    return (
        <>
          <Paper variant="outlined" className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                    disabled={reportType === 'GIZ_INTERMEDIARY_REPORT'
                        || reportType === 'GIZ_FARMING_GROUP_MEMBERS_REPORT'
                        || reportType === 'PRACTICES_APPLIED_COUNT_REPORT'
                        || reportType === 'PRACTICES_APPLIED_LIST_REPORT'}
                    autoFocus
                    margin="normal"
                    id="fromDate"
                    label="From"
                    type="date"
                    variant={'outlined'}
                    value={fromDate}
                    onChange={handleFromDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                    disabled={reportType === 'GIZ_INTERMEDIARY_REPORT'
                        || reportType === 'GIZ_FARMING_GROUP_MEMBERS_REPORT'
                        || reportType === 'PRACTICES_APPLIED_COUNT_REPORT'
                        || reportType === 'PRACTICES_APPLIED_LIST_REPORT'}
                    autoFocus
                    margin="normal"
                    id="toDate"
                    label="To"
                    type="date"
                    variant={'outlined'}
                    value={toDate}
                    onChange={handleToDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <FormControl variant="outlined" style={{marginTop: 16, minWidth: '10rem'}}>
                  <InputLabel htmlFor="unit-select">Type</InputLabel>
                  <Select
                      native
                      value={reportType}
                      label={'Type'}
                      onChange={handleReportType}
                      name="unit"
                      inputProps={{
                        id: 'reportType-select',
                      }}
                  >
                    <optgroup label="General">
                      <option value={'TRAINING_REPORT'}>Beneficiary Training Report</option>
                      <option value={'INPUT_REPORT'}>Beneficiary Input Report</option>
                      <option value={'KEY_OUTPUT_REPORT'}>Key Output Report</option>
                      <option value={'TRAINING_OF_TRAINERS_REPORT'}>Training of Trainers Report</option>
                      <option value={'FBS_TRAINING_REPORT'}>FBS Training Report</option>
                      <option value={'DIVERSE_TRAINING_REPORT'}>AgriLight/Intensive & WASH & Nutrition Report</option>
                      <option value={'POST_HARVEST_TRAINING_REPORT'}>Post-harvest Training Report</option>
                      <option value={'GIZ_INTERMEDIARY_REPORT'}>GIZ Intermediary Report</option>
                      <option value={'GIZ_FARMING_GROUP_MEMBERS_REPORT'}>Farming Group Members Report</option>
                      <option value={'PRACTICES_APPLIED_LIST_REPORT'}>Applied Practices Report (List)</option>
                      <option value={'PRACTICES_APPLIED_COUNT_REPORT'}>Applied Practices Report (Count)</option>
                    </optgroup>
                    <optgroup label="Special">
                      <option value={'MOA_REPORT'}>MoA Report</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} xl={3} style={{paddingTop: '2em'}}>
                { (reportType === 'GIZ_INTERMEDIARY_REPORT' || reportType === 'GIZ_FARMING_GROUP_MEMBERS_REPORT') &&
                <>
                  <Typography color="inherit" variant="body1" component="div">
                    Please specify if the report should be filtered by only one observing intermediary (otherwise leave blank)
                  </Typography>
                  <BasicAutocomplete handleChange={(observer) => setObserver(observer)} searchFct={searchGizIntermediaries} label={'Observing intermediary'} property={'intermediary'} />
                  <br/>
                  <Typography color="inherit" variant="body1" component="div">
                    To filter list further specify the specific location for which the report should be generated
                  </Typography>
                  <BasicAutocomplete handleChange={(district) => setDistrict(district)} searchFct={searchDistricts} label={'District'} property={'districtName'} />
                  { district &&
                  <>
                    <BasicAutocomplete handleChange={(ward) => setWard(ward)} searchFct={(searchTerm) => searchWards(searchTerm, district.id)} label={'Ward'} property={'wardName'} />
                    <Typography color="inherit" variant="subtitle2" component="div">
                      Select ward or leave blank to report on entire district
                    </Typography>
                  </>
                  }
                  { ward &&
                  <>
                    <BasicAutocomplete handleChange={(camp) => setCamp(camp)} searchFct={(searchTerm) => searchCamps(searchTerm, ward.id)} label={'Camp'} property={'campName'} />
                    <Typography color="inherit" variant="subtitle2" component="div">
                      Select camp or leave blank to report on entire ward
                    </Typography>
                  </>
                  }
                  { camp &&
                  <>
                    <BasicAutocomplete handleChange={(village) => setVillage(village)} searchFct={(searchTerm) => searchVillages(searchTerm, camp.id)} label={'Village'} property={'villageNameSimple'} />
                    <Typography color="inherit" variant="subtitle2" component="div">
                      Select village or leave blank to report on entire camp
                    </Typography>
                  </>
                  }
                </>
                }
                { (reportType === 'PRACTICES_APPLIED_COUNT_REPORT' || reportType === 'PRACTICES_APPLIED_LIST_REPORT') &&
                    <>
                      <Typography color="inherit" variant="body1" component="div">
                        Please specify the specific location for which the report should be generated
                      </Typography>
                      <BasicAutocomplete handleChange={(district) => setDistrict(district)} searchFct={searchDistricts} label={'District'} property={'districtName'} />
                      { district &&
                          <>
                            <BasicAutocomplete handleChange={(ward) => setWard(ward)} searchFct={(searchTerm) => searchWards(searchTerm, district.id)} label={'Ward'} property={'wardName'} />
                            <Typography color="inherit" variant="subtitle2" component="div">
                              Select ward or leave blank to report on entire district
                            </Typography>
                          </>
                      }
                      { ward &&
                          <>
                            <BasicAutocomplete handleChange={(camp) => setCamp(camp)} searchFct={(searchTerm) => searchCamps(searchTerm, ward.id)} label={'Camp'} property={'campName'} />
                            <Typography color="inherit" variant="subtitle2" component="div">
                              Select camp or leave blank to report on entire ward
                            </Typography>
                          </>
                      }
                      { camp &&
                          <>
                            <BasicAutocomplete handleChange={(village) => setVillage(village)} searchFct={(searchTerm) => searchVillages(searchTerm, camp.id)} label={'Village'} property={'villageNameSimple'} />
                            <Typography color="inherit" variant="subtitle2" component="div">
                              Select village or leave blank to report on entire camp
                            </Typography>
                          </>
                      }
                    </>
                }
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Button
                    style={{marginTop: 22}}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => fetchReport(true, fromDate, toDate, observer, district, ward, camp, village)}>
                  Generate
                </Button>
              </Grid>
            </Grid>
          </Paper>
          { isLoading &&
          <Grid item xs={6} md={2} xl={2}>
            <CircularProgress size={72} color="primary" className={classes.paper}/>
          </Grid>
          }
        </>

    );
  };

  return renderData();
}

export default withStyles(styles)(GizReport);