import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createCrsIntermediary, deleteCrsAgent,
  getCrsIntermediaries,
  getCrsIntermediariesDump, updateCrsAgent
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import {formatAgentType} from "../../utils/agentTypeHelper";
import {formatGender} from "../../utils/genderHelper";
import FileDownload from "js-file-download";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/GetApp";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";
import {UserContext} from "../auth/UserProvider";
import AddCrsAgentDialog from "../dialogs/AddCrsAgentDialog";
import CreationSnackbar from "../dialogs/CreationSnackbar";
import debounce from "lodash.debounce";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  fab: {
    position: 'absolute',
    bottom: '1rem',
    right: '12rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function CrsIntermediaries(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedCrsIntermediaries, setPaginatedCrsIntermediaries] = useState('');
  const [intermediaryBuffer, setIntermediaryBuffer] = useState('');
  const [successBarText, setSuccessBarText] = useState('');
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const authState = useContext(UserContext);

  const cancelRequest = useRef();

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getCrsIntermediariesDump()
          .then((response) => {
            FileDownload(response.data, 'crs_intermediaries.csv');
            setIsLoadingDump(false);
          });
        } catch (error) {
          console.log(error);
        }
      };



  const addConfirmationHandler =
      async (values, gender, agentType) => {
        await createCrsIntermediary({
          'person': {
            'personalData': {
              'firstName': values.firstName,
              'lastName': values.lastName,
              'dateOfBirth': values.dateOfBirth,
              'phoneNumber': values.phoneNumber,
              'gender': gender,
            }
          },
          'agentType': agentType,
          'village': values.village,
          'observer': values.observer,
          'nrcNumber': values.nrcNumber,
        }).then((entity) => {
          setSuccessBarText(entity.data ? `Intermediary ${entity.data.person.personalData.firstName} ${entity.data.person.personalData.lastName}` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, values, gender, agentType) => {
        await updateCrsAgentDataAndDispatch(entityId, {
          'person': {
            'personalData': {
              'firstName': values.firstName,
              'lastName': values.lastName,
              'dateOfBirth': values.dateOfBirth,
              'phoneNumber': values.phoneNumber,
              'gender': gender,
            }
          },
          'agentType': agentType,
          'village': values.village,
          'observer': values.observer,
          'nrcNumber': values.nrcNumber,
        });
        await fetchData();
      };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getCrsIntermediaries(page, size, searchTerm);
          const crsIntermediariesData = result.data;

          if (!cancelRequest.current) {
            if (crsIntermediariesData && Object.keys(crsIntermediariesData).length > 0) {
              setPaginatedCrsIntermediaries(crsIntermediariesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const removeHandler = async (intermediaryId, deleteType = 'SOFT') => {
    await deleteCrsAgent(intermediaryId, deleteType);
    await fetchData();
  };

  const updateCrsAgentDataAndDispatch = async (intermediaryId, fields) => {
    try {
      let buffer = {
        ...intermediaryBuffer
      };

      setIntermediaryBuffer(buffer);

      const { data: data } = await updateCrsAgent(
          intermediaryId,
          fields
      );

      if (!cancelRequest.current) {
        setIntermediaryBuffer(data);
      }
    } catch (error) {
      setIntermediaryBuffer(intermediaryBuffer);
    }
  };

  const handleSuccessBarState = (open) => {
    setShowSuccessBar(open);
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedCrsIntermediaries) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="First name, last name, village"
                id="agent-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '95%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedCrsIntermediaries.content}
                columns={[
                  {
                    field: "agentType",
                    headerName: "Agent type",
                    flex: 1.5,
                    renderCell: (params) =>
                        (formatAgentType(params.row.agentType))
                  },
                  {
                    field: "firstNames",
                    headerName: "First name(s)",
                    flex: 1,
                    renderCell: (params) => (
                        params.row.person.personalData.firstName
                    )
                  },
                  {
                    field: "lastName",
                    headerName: "Last name",
                    flex: 1,
                    renderCell: (params) => (
                        params.row.person.personalData.lastName
                    )
                  },
                  {
                    field: "gender",
                    headerName: "Gender",
                    flex: 1,
                    renderCell: (params) => (
                        formatGender(params.row.person.personalData.gender)
                    )
                  },
                  {
                    field: "dateOfBirth",
                    headerName: "Date of Birth",
                    flex: 1,
                    renderCell: (params) => (
                        formatTime(params.row.person.personalData.dateOfBirth)
                    )
                  },
                  {
                    field: "yearOfBirth",
                    headerName: "Year of Birth",
                    flex: 1,
                    renderCell: (params) => (
                        params.row.person.personalData.yearOfBirth
                    )
                  },
                  {
                    field: "phoneNumber",
                    headerName: "Phone number",
                    flex: 1,
                    renderCell: (params) => (
                        params.row.person.personalData.phoneNumber
                    )
                  },
                  {
                    field: "village",
                    headerName: "Village",
                    flex: 1,
                    renderCell: (params) => (
                        params.row.village ? params.row.village.villageName : ''
                    )
                  },
                  { field: 'observer', headerName: 'Observed by', flex: 1,
                    valueFormatter: (params) =>
                        (params.row.observer && params.row.observer.person ?
                            (params.row.observer.person.personalData.firstName + ' ' + params.row.observer.person.personalData.lastName) : ' ' ) },

                  {
                    field: "nrcNumber",
                    headerName: "NRC number",
                    flex: 1,
                    renderCell: (params) => (
                        params.row.nrcNumber
                    )
                  },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: 'actions',
                    headerName: 'Actions',
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                          <AddCrsAgentDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                          <DataGridActionBar
                            data={params.row}
                            type={'Agent'}
                            deleteHandler={removeHandler}
                            relations={[
                              'Referral',
                              'Lesson',
                              'IEC Distribution',
                              'Garden Input',
                            ]}
                        >
                        </DataGridActionBar>
                      </>
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedCrsIntermediaries.size}
                rowCount={paginatedCrsIntermediaries.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
            <Fab
                size="small"
                color="primary"
                aria-label="add"
                onClick={() => fetchDump()}
                className={classes.fab}
                style={{position: 'fixed', marginBottom: '1rem'}}
            >
              <DownloadIcon/>
            </Fab>
          </div>
          <AddCrsAgentDialog confirmationHandler={addConfirmationHandler} />
          <CreationSnackbar successBarOpen={showSuccessBar} successBarHandler={handleSuccessBarState} successText={successBarText} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(CrsIntermediaries);