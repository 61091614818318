import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createFarmingGroupMemberDropout, getFarmingGroupMemberDropouts
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import AddFarmingGroupMemberDropoutDialog
  from "../dialogs/AddFarmingGroupMemberDropoutDialog";
import debounce from "lodash.debounce";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function FarmingGroupDropouts(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedTrainers, setPaginatedTrainers] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const addConfirmationHandler =
      async (member, dateLeft, reasonLeaving) => {

        let body = {
          'farmingGroupMember': member,
          'dateLeft': dateLeft,
          'reasonLeavingValue': reasonLeaving
        }

        await createFarmingGroupMemberDropout(body);
        await fetchData();
      };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getFarmingGroupMemberDropouts(page, size, searchTerm);
          const trainersData = result.data;

          if (!cancelRequest.current) {
            if (trainersData && Object.keys(trainersData).length > 0) {
              setPaginatedTrainers(trainersData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedTrainers) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="FB number, farming group name"
                id="dropout-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '95%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedTrainers.content}
                columns={[
                  {
                    field: "farmingGroupMember",
                    headerName: "Household", flex: 1,
                    renderCell: (params) => (
                        params.value ? params.value.household.fbNumber : ''
                    )
                  },
                  {
                    field: "farmingGroup",
                    headerName: "Farming group", flex: 1,
                    renderCell: (params) => (
                        params.row && params.row.farmingGroupMember ? params.row.farmingGroupMember.farmingGroup.nameFarmingGroup : ''
                    )
                  },
                  { field: 'dateLeft', headerName: 'Dropout date', flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.value))
                  },
                  {
                    field: "reasonLeavingValue",
                    headerName: "Reason", flex: 1,
                    renderCell: (params) => (
                        params.value ? params.value.nameReason : ''
                    )
                  },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: 'actions',
                    headerName: 'Actions',
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <DataGridActionBar data={params.row}>
                      </DataGridActionBar>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedTrainers.size}
                rowCount={paginatedTrainers.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <AddFarmingGroupMemberDropoutDialog confirmationHandler={addConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(FarmingGroupDropouts);