import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  getAvailableFbNumber,
  searchBeneficiaryRoles, searchBeneficiaryStatuses,
  searchNutritionVolunteers,
  searchVillages
} from "../../utils/api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {FormControlLabel, Switch} from "@material-ui/core";
import {UserContext} from "../auth/UserProvider";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {useFormik} from 'formik';
import * as Yup from 'yup';

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '1rem',
    right: '2rem',
  },
});

function AddHouseholdDialog(props) {
  const {classes, confirmationHandler, beneficiaryConfirmationHandler,
    action = 'create', entity } = props;
  const [fbNumber, setFbNumber] = React.useState('');
  const [householdOpen, setHouseholdOpen] = React.useState(false);
  const [beneficiaryOpen, setBeneficiaryOpen] = React.useState(false);
  const [fbAutoChecked, setFbAutoChecked] = React.useState(false);
  const [gender, setGender] = React.useState('FEMALE');

  const authState = useContext(UserContext);

  const HouseholdSchema = Yup.object().shape({
    fbNumber: Yup.string()
    .min(7, 'Too Short!')
    .max(9, 'Too Long!')
    .matches('^FB', 'Has to start with FB!')
    .required('Required'),
    registrationDate: Yup.date().min(new Date("2015-01-01"), 'Date cannot be before 2015!').max(new Date(), 'Date cannot be in the future!').required('Required'),
    village: Yup.object().nullable().required('Required'),
    nv: Yup.object().nullable(),
  });

  const BeneficiarySchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    dateOfBirth: Yup.string(),
    dateRegistered: Yup.string().required('Required'),
    relation: Yup.object().nullable().required('Required'),
    status: Yup.object().nullable().required('Required'),
  });

  const householdFormik = useFormik({
    initialValues: {
      fbNumber: '',
      registrationDate: '',
      village: null,
      nv: null,
    },
    validationSchema: HouseholdSchema,
  });

  const beneficiaryFormik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      dateRegistered: '',
      relation: null,
      status: null,
    },
    validationSchema: BeneficiarySchema,
  });

  const setFormFieldsTouched = async () => {
    await householdFormik.setFieldTouched('fbNumber', true);
    await householdFormik.setFieldTouched('registrationDate', true);
    await householdFormik.setFieldTouched('village', true);
    await householdFormik.setFieldTouched('nv', true);
  }

  const handleClickOpen = async () => {
    if (entity) {
      await householdFormik.setFieldValue('fbNumber', entity.fbNumber);
      await householdFormik.setFieldValue('registrationDate',entity.dateRegistered);
      await householdFormik.setFieldValue('village',entity.addressData.village);
      await householdFormik.setFieldValue('nv', entity.agent);
      await setFormFieldsTouched();
    }
    setHouseholdOpen(true);
  };

  const handleCloseHouseholdDialog = async () => {
    setHouseholdOpen(false);
    setFbAutoChecked(false);
    await householdFormik.resetForm();
  };

  const handleCloseBeneficiaryDialog = async (createOther = false) => {
    setBeneficiaryOpen(false);
    await beneficiaryFormik.resetForm();
    setBeneficiaryOpen(createOther);
  };

  const handleCreateHouseholdAddBeneficiary = () => {
    action === 'create' ?
        confirmationHandler(householdFormik.values) :
        confirmationHandler(entity.id, householdFormik.values);
    handleCloseHouseholdDialog();
    setBeneficiaryOpen(true);
  };

  const handleCreateHousehold = () => {
    action === 'create' ?
        confirmationHandler(householdFormik.values) :
        confirmationHandler(entity.id, householdFormik.values);
    handleCloseHouseholdDialog();
  };

  const handleCreateBeneficiaryAddBeneficiary = () => {
    beneficiaryConfirmationHandler(beneficiaryFormik.values, { fbNumber: fbNumber }, gender);
    handleCloseBeneficiaryDialog(true);
  };

  const handleCreateBeneficiary = () => {
    beneficiaryConfirmationHandler(beneficiaryFormik.values, { fbNumber: fbNumber }, gender);
    handleCloseBeneficiaryDialog(false);
  };

  const handleFbNumberChange = (event) => {
    setFbNumber(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleFbAutoChange = (event) => {
    let checked = event.target.checked;

    setFbAutoChecked(checked);
    if (checked) {
      fetchAvailbaleFbNumber();
    }
  };

  const fetchAvailbaleFbNumber =
    async () => {
      try {
        const result = await getAvailableFbNumber();
        const response = result.data;
        if (response) {
          await householdFormik.setFieldValue('fbNumber', response.fbNumber);
          setFbNumber(response.fbNumber);
        }
      } catch (error) {
      }
    };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        {action === 'create' &&
        <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
            style={{position: 'fixed', marginBottom: '1rem'}}
        >
          <AddIcon/>
          Add household
        </Fab>
        }
        {action === 'edit' &&
        <Tooltip title="Edit">
          <IconButton aria-label="edit"
                      onClick={handleClickOpen}>
            <EditIcon/>
          </IconButton>
        </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog}
                open={householdOpen} onClose={handleCloseHouseholdDialog}
                aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} household</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the new household
            </DialogContentText>
            <form>
                  <TextField
                      autoFocus
                      margin="normal"
                      id="fbNumber"
                      name="fbNumber"
                      label="FB number"
                      type="text"
                      variant={'outlined'}
                      disabled={fbAutoChecked}
                      value={householdFormik.values.fbNumber}
                      onChange={(event) => {
                        householdFormik.handleChange(event);
                        setFbNumber(event.target.value);
                      }}
                      error={Boolean(householdFormik.errors.fbNumber)}
                      helperText={householdFormik.errors.fbNumber}
                      fullWidth
                  />
                <FormControlLabel control={
                  <Switch
                      color="primary"
                      checked={fbAutoChecked}
                      onChange={handleFbAutoChange}/>
                } label="Auto-generate FB number" />
                <TextField
                    autoFocus
                    margin="normal"
                    id="registrationDate"
                    name="registrationDate"
                    label="Date registered"
                    type="date"
                    variant={'outlined'}
                    value={householdFormik.values.registrationDate}
                    onChange={householdFormik.handleChange}
                    error={Boolean(householdFormik.errors.registrationDate)}
                    helperText={householdFormik.errors.registrationDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              <BasicAutocomplete handleChange={(value) => householdFormik.setFieldValue("village", value)}
                                 searchFct={searchVillages} label={'Village'}
                                 property={'villageName'}
                                 currentValue={householdFormik.values.village}
                                 error={Boolean(householdFormik.errors.village)}
                                 helperText={householdFormik.errors.village} />
              <BasicAutocomplete handleChange={(value) => householdFormik.setFieldValue("nv", value)}
                                 searchFct={searchNutritionVolunteers}
                                 label={'Nutritional volunteer'}
                                 property={'nutritionalVolunteer'}
                                 currentValue={householdFormik.values.nv}
                                 error={Boolean(householdFormik.errors.nv)}
                                 helperText={householdFormik.errors.nv} />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseHouseholdDialog} color="primary">
              Cancel
            </Button>
            { action === 'edit' &&
              <Button disabled={!householdFormik.dirty || Object.keys(householdFormik.errors).length !== 0} onClick={handleCreateHousehold} color="primary">
                Update
              </Button>
            }
            { action !== 'edit' &&
              <Button disabled={!householdFormik.dirty || Object.keys(householdFormik.errors).length !== 0} onClick={handleCreateHouseholdAddBeneficiary} color="primary">
                Create and add beneficiary
              </Button>
            }
          </DialogActions>
        </Dialog>
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog}
                open={beneficiaryOpen} onClose={() => handleCloseBeneficiaryDialog(false)}
                aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add beneficiary</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of beneficiary
            </DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="first-name"
                    label="First name"
                    type="text"
                    fullWidth
                    name="firstName"
                    variant={'outlined'}
                    value={beneficiaryFormik.values.firstName}
                    onChange={beneficiaryFormik.handleChange}
                    error={Boolean(beneficiaryFormik.errors.firstName)}
                    helperText={beneficiaryFormik.errors.firstName}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="last-name"
                    label="Last name"
                    type="text"
                    fullWidth
                    name="lastName"
                    variant={'outlined'}
                    value={beneficiaryFormik.values.lastName}
                    onChange={beneficiaryFormik.handleChange}
                    error={Boolean(beneficiaryFormik.errors.lastName)}
                    helperText={beneficiaryFormik.errors.lastName}
                />
              </FormControl>
            </div>
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={gender} onChange={handleGenderChange}>
                  <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                  <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="birthDate"
                  label="Date of birth"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="dateOfBirth"
                  variant={'outlined'}
                  value={beneficiaryFormik.values.dateOfBirth}
                  onChange={beneficiaryFormik.handleChange}
                  error={Boolean(beneficiaryFormik.errors.dateOfBirth)}
                  helperText={beneficiaryFormik.errors.dateOfBirth}
              />
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="fb-number"
                    label="FB number"
                    type="text"
                    variant={'outlined'}
                    disabled
                    value={fbNumber}
                    onChange={handleFbNumberChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="dateRegistered"
                  label="Date registered"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="dateRegistered"
                  variant={'outlined'}
                  value={beneficiaryFormik.values.dateRegistered}
                  onChange={beneficiaryFormik.handleChange}
                  error={Boolean(beneficiaryFormik.errors.dateRegistered)}
                  helperText={beneficiaryFormik.errors.dateRegistered}
              />
            </div>
            <BasicAutocomplete handleChange={(value) => beneficiaryFormik.setFieldValue('relation', value)}
                               searchFct={searchBeneficiaryRoles}
                               label={'Role'}
                               property={'nameRelation'}
                               currentValue={beneficiaryFormik.values.relation}
                               error={Boolean(beneficiaryFormik.errors.relation)}
                               helperText={beneficiaryFormik.errors.relation} />
            <BasicAutocomplete handleChange={(value) => beneficiaryFormik.setFieldValue('status', value)}
                               searchFct={searchBeneficiaryStatuses}
                               label={'Status'}
                               property={'nameStatus'}
                               currentValue={beneficiaryFormik.values.status}
                               error={Boolean(beneficiaryFormik.errors.status)}
                               helperText={beneficiaryFormik.errors.status} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseHouseholdDialog} color="primary">
              Cancel
            </Button>
            <Button disabled={!beneficiaryFormik.dirty || Object.keys(beneficiaryFormik.errors).length !== 0} onClick={handleCreateBeneficiaryAddBeneficiary} color="primary">
              Create + add additional beneficiary
            </Button>
            <Button disabled={!beneficiaryFormik.dirty || Object.keys(beneficiaryFormik.errors).length !== 0} onClick={handleCreateBeneficiary} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddHouseholdDialog)