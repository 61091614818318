import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createBeneficiary, deleteBeneficiary,
  getBeneficiaries, getBeneficiariesDump, updateBeneficiary
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import {formatGender} from "../../utils/genderHelper";
import AddBeneficiaryDialog from "../dialogs/AddBeneficiaryDialog";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/GetApp";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";
import {UserContext} from "../auth/UserProvider";
import CreationSnackbar from "../dialogs/CreationSnackbar";
import debounce from "lodash.debounce";
import {TextField} from "@material-ui/core";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white',
    height: '88%',
  },
  fab: {
    position: 'absolute',
    bottom: '1rem',
    right: '15rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function Beneficiaries(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedBeneficiaries, setPaginatedBeneficiaries] = useState('');
  const [beneficiaryBuffer, setBeneficiaryBuffer] = useState('');
  const [successBarText, setSuccessBarText] = useState('');
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const authState = useContext(UserContext);

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getBeneficiariesDump().then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'beneficiaries.csv');
            document.body.appendChild(link);
            link.click();
          });

          setIsLoadingDump(false);
        } catch (error) {
          console.log(error);
        }
      };

  const addConfirmationHandler =
      async (values, gender) => {
          let body = {
            'person': {
              'dateRegistered': values.dateRegistered,
              'personalData': {
                'firstName': values.firstName,
                'lastName': values.lastName,
                'dateOfBirth': values.dateOfBirth,
                'gender': gender,
              },
              'household': values.household,
            },
            'status': values.status,
            'relation': values.relation,
          }

        await createBeneficiary(body).then((entity) => {
          setSuccessBarText(entity.data ? `Beneficiary ${entity.data.person.personalData.firstName} ${entity.data.person.personalData.lastName}` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, values, gender) => {
        let body = {
          'person': {
            'dateRegistered': values.dateRegistered,
            'personalData': {
              'firstName': values.firstName,
              'lastName': values.lastName,
              'dateOfBirth': values.dateOfBirth,
              'gender': gender,
            },
            'household': values.household,
          },
          'status': values.status,
          'relation': values.relation,
        }

        await updateBeneficiaryAndDispatch(entityId, body);
        await fetchData();
      };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (beneficiaryId, deleteType = 'SOFT') => {
    await deleteBeneficiary(beneficiaryId, deleteType);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getBeneficiaries(page, size, searchTerm);
          const beneficiariesData = result.data;

          if (!cancelRequest.current) {
            if (beneficiariesData && Object.keys(beneficiariesData).length > 0) {
              setPaginatedBeneficiaries(beneficiariesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const updateBeneficiaryAndDispatch = async (beneficiaryId, fields) => {
    try {
      let buffer = {
        ...beneficiaryBuffer
      };

      setBeneficiaryBuffer(buffer);

      const { data: data } = await updateBeneficiary(
          beneficiaryId,
          fields
      );

      if (!cancelRequest.current) {
        setBeneficiaryBuffer(data);
      }
    } catch (error) {
      setBeneficiaryBuffer(beneficiaryBuffer);
    }
  };

  const handleSuccessBarState = (open) => {
    setShowSuccessBar(open);
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedBeneficiaries) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="FB number, Beneficiary name"
                id="beneficiary-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={paginatedBeneficiaries.content}
              getRowClassName={(params) => `row-${params.row.deleted === true ? 'deleted' : 'active'}`}
              columns={[{
                field: "firstNames",
                headerName: "First name(s)",
                flex: 1,
                renderCell: (params) =>
                    (params.row.person.personalData.firstName)

              },
                {
                  field: "lastName",
                  headerName: "Last name",
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.person.personalData.lastName)
                },
                {
                  field: "gender",
                  headerName: "Gender",
                  flex: 1,
                  renderCell: (params) => (
                      formatGender(params.row.person.personalData.gender)
                  )
                },
                {
                  field: "dateOfBirth",
                  headerName: "Date of Birth",
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.person.personalData.dateOfBirth))
                },
                {
                  field: "yearOfBirth",
                  headerName: "Year of Birth",
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.person.personalData.yearOfBirth)
                },
                { field: 'person', headerName: 'Household FB number', flex: 1.5,
                  valueFormatter: (params) =>
                      (params.value.household ? params.value.household.fbNumber : ' '),
                },
                { field: 'relation', headerName: 'Role', flex: 1.5,
                  valueFormatter: (params) =>
                      (params.value ? params.value.nameRelation : ' '),
                },
                { field: 'status', headerName: 'Status', flex: 1.5,
                  valueFormatter: (params) =>
                      (params.value ? params.value.nameStatus : ' '),
                },
                {
                  field: "dateRegistered",
                  headerName: "Date registered",
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.person.dateRegistered))
                },
                { field: 'dateCreated', headerName: 'Date created',
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated))
                },
                {
                  field: 'actions',
                  headerName: 'Actions',
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <>
                      <AddBeneficiaryDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                      <DataGridActionBar
                          data={params.row}
                          type={'Beneficiary'}
                          deleteHandler={removeHandler}
                          relations={[
                          ]}
                      >
                      </DataGridActionBar>
                    </>;
                  }
                }
              ]}
              density="compact"
              pagination
              pageSize={paginatedBeneficiaries.size}
              rowCount={paginatedBeneficiaries.totalElements}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
          <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
            <Fab
                size="small"
                color="primary"
                aria-label="add"
                onClick={() => fetchDump()}
                className={classes.fab}
                style={{position: 'fixed', marginBottom: '1rem'}}
            >
              <DownloadIcon/>
            </Fab>
          </div>
          <AddBeneficiaryDialog confirmationHandler={addConfirmationHandler} />
          <CreationSnackbar successBarOpen={showSuccessBar} successBarHandler={handleSuccessBarState} successText={successBarText} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Beneficiaries);