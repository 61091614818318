import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import {withStyles} from "@material-ui/core/styles";
import {
  generateKeyOuputs,
  searchVillages
} from "../../utils/api";
import Button from "@material-ui/core/Button";
import {CircularProgress} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {formatTime} from "../../utils/timeHelper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
  title: {
    textDecoration: 'underline'
  }
});

function KeyOutputs(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [village, setVillage] = useState('');
  const [keyOutputs, setKeyOutputs] = useState(undefined);


  const [fromMissing, setFromMissing] = useState(false);
  const [toMissing, setToMissing] = useState(false);


  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
    setFromMissing(false);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
    setToMissing(false);
  };

  const onChangeVillage = value => {
    setVillage(value);
  };

  const fetchOutputs =
      async (loading, from, to, selectedVillage) => {
        setIsError(false);
        try {
          if (from === '' || to === '') {
            from === '' ? setFromMissing(true) : setFromMissing(false);
            to === '' ? setToMissing(true) : setToMissing(false);
          } else {
            setIsLoading(loading);
            await generateKeyOuputs({
              'fromDate': from,
              'toDate': to,
              'village': selectedVillage
            }).then((response) => {
              setIsLoading(false);
              setKeyOutputs(response.data);
            });
          }
        } catch (error) {
          console.log(error);
        }
      };

  const renderData = () => {
    return (
        <>
          <Paper variant="outlined" className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                    style={{marginTop: 20}}
                    error={fromMissing}
                    helperText={fromMissing ? 'Select from date' : ''}
                    autoFocus
                    variant="outlined"
                    margin="normal"
                    id="fromDate"
                    label="From"
                    type="date"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                    style={{marginTop: 20}}
                    error={toMissing}
                    helperText={toMissing ? 'Select to date' : ''}
                    autoFocus
                    variant="outlined"
                    margin="normal"
                    id="toDate"
                    label="To"
                    type="date"
                    value={toDate}
                    onChange={handleToDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={3}>
                <BasicAutocomplete handleChange={onChangeVillage} searchFct={searchVillages} label={'Village'} property={'villageName'} />
              </Grid>
              <Grid item xs={12} md={1}>
                <Button style={{marginTop: 25}} size="large" variant="contained" color="primary" onClick={() => fetchOutputs(true, fromDate, toDate, village)}>
                  Generate
                </Button>
              </Grid>
            </Grid>
          </Paper>
            { isLoading &&
            <Paper variant="outlined" className={classes.paper}>
              <div>
                <CircularProgress size={24} color="primary" />
              </div>
            </Paper>
            }
            { keyOutputs && keyOutputs.trainingSessionOutputs.length > 0 &&
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h6">
                Village: {keyOutputs.village ? keyOutputs.village.villageName + ' (' + keyOutputs.village.camp.campName + ')' : ''}
              </Typography>
              <Typography variant="subtitle1">
                Camp: {keyOutputs.village ? keyOutputs.village.camp.campName : ''}
              </Typography>
              { keyOutputs && keyOutputs.trainingSessionOutputs.map((output) => (
                  <div style={{marginTop: '1rem'}}>
                    <Typography variant="h5">
                      {output.trainingSession.trainingLesson.name + ' (' + formatTime(output.trainingSession.dateConducted) + ')'}
                    </Typography>
                    { output.participants.map((participant) => (
                        <>
                          <Typography variant="h6" style={{marginTop: '1rem'}}>
                            Household: {participant.household.fbNumber}
                          </Typography>
                          <Typography variant="subtitle1" style={{marginBottom: '1rem'}}>
                            Beneficiary: {participant.beneficiary}
                          </Typography>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={3}>
                              <div>
                                <Typography variant="subtitle1" className={classes.title}>
                                  Crops grown before training
                                </Typography>
                                <List dense={true}>
                                  { participant.cropsGrownBeforeTraining.map((crop) => (
                                      <ListItem>
                                        <ListItemText
                                            primary={'- ' + crop.name}
                                        />
                                      </ListItem>
                                  ))}
                                </List>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <div>
                                <Typography variant="subtitle1" className={classes.title}>
                                  Crops grown after training
                                </Typography>
                                <List dense={true}>
                                  { participant.cropsGrownAfterTraining.map((crop) => (
                                      <ListItem>
                                        <ListItemText
                                            primary={'- ' + crop.name}
                                        />
                                      </ListItem>
                                  ))}
                                </List>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <div>
                                <Typography variant="subtitle1" className={classes.title}>
                                  Key outputs
                                </Typography>
                                <List dense={true}>
                                  { participant.keyElements.map((o) => (
                                      <ListItem>
                                        <ListItemText
                                            primary={'- ' + o.name}
                                        />
                                      </ListItem>
                                  ))}
                                </List>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <div>
                                <Typography variant="subtitle1" className={classes.title}>
                                  Practices
                                </Typography>
                                <List dense={true}>
                                  { participant.practices.map((practice) => (
                                      <ListItem>
                                        <ListItemText
                                            primary={'- ' + practice.name}
                                        />
                                      </ListItem>
                                  ))}
                                </List>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <Typography variant="subtitle1" className={classes.title}>
                                Techniques
                              </Typography>
                              <List dense={true}>
                                { participant.techniques.map((technique) => (
                                    <ListItem>
                                      <ListItemText
                                          primary={'- ' + technique.name}
                                      />
                                    </ListItem>
                                ))}
                              </List>
                            </Grid>
                          </Grid>
                        </>

                    ))}
                  </div>
              ))}
            </Paper>
            }
            { keyOutputs && keyOutputs.trainingSessionOutputs.length === 0 &&
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h6">
                No data available yet for selected timeframe and village!
              </Typography>
            </Paper>
            }
        </>

    );
  };

  return renderData();
}

export default withStyles(styles)(KeyOutputs);