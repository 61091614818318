import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createTotTrainingSessionParticipant, deleteTotParticipant,
  getTotTrainingSessionParticipants, updateTotParticipant
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import AddTotSessionParticipantDialog
  from "../dialogs/AddTotSessionParticipantDialog";
import debounce from "lodash.debounce";
import {formatTime} from "../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function Participants(props) {
  const { classes, role } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedParticipants, setPaginatedParticipants] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [participantBuffer, setParticipantBuffer] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const addConfirmationHandler =
      async (trainingSession, intermediary) => {
        await createTotTrainingSessionParticipant({
          'trainingSessionTot': trainingSession,
          'person': intermediary.person,
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, trainingSession, intermediary) => {
        await updateDistrictDataAndDispatch(entityId, {
          'trainingSessionTot': trainingSession,
          'person': intermediary.person,
        });
        await fetchData();
      };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const updateDistrictDataAndDispatch = async (participantId, fields) => {
    try {
      let buffer = {
        ...participantBuffer
      };

      setParticipantBuffer(buffer);

      const { data: data } = await updateTotParticipant(
          participantId,
          fields
      );

      if (!cancelRequest.current) {
        setParticipantBuffer(data);
      }
    } catch (error) {
      setParticipantBuffer(participantBuffer);
    }
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (participantId) => {
    await deleteTotParticipant(participantId);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getTotTrainingSessionParticipants(page, size, searchTerm);
          const totParticipantsData = result.data;

          if (!cancelRequest.current) {
            if (totParticipantsData && Object.keys(totParticipantsData).length > 0) {
              setPaginatedParticipants(totParticipantsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedParticipants) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Participant first, last name, session name"
                id="participant-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '95%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedParticipants.content}
                columns={[
                  { field: 'trainingSessionTot', headerName: 'Session', flex: 1,
                    valueFormatter: (params) =>
                        (params.value.trainingBlock.name + ' (' + params.value.dateConducted + ')' ), },
                  {
                    field: "person",
                    headerName: "Participant",
                    flex: 1,
                    renderCell: (params) => (
                        params.value ? params.value.personalData.firstName + ' ' + params.value.personalData.lastName : ''
                    )
                  },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                        {(role === 'ROLE_SUPER' || role === 'ROLE_STANDARD') &&
                            <>
                            <AddTotSessionParticipantDialog
                                confirmationHandler={updateConfirmationHandler}
                                action={'edit'}
                                entity={params.row}/>
                            <DataGridActionBar deleteHandler={removeHandler} data={params.row}>
                            </DataGridActionBar>
                            </>
                        }
                      </>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedParticipants.size}
                rowCount={paginatedParticipants.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <AddTotSessionParticipantDialog confirmationHandler={addConfirmationHandler}/>
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Participants);