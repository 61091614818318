import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Chip} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";


const styles = () => ({
  block: {
    display: 'block',
  },
});

function UserRoleChip(props) {
  const { role } = props;

  const formatRole = (rawType) => {
    if (!rawType) {
      return null;
    }

    switch (rawType) {
      case 'SLF':
        return 'SLF (App user)';
      case 'READER':
        return 'Read-Only User';
      case 'SUPER':
        return 'Super Admin';
      case 'STANDARD':
        return 'Standard User';
      case 'CEO':
        return 'Camp Extension Officer';
      case 'PSP':
        return 'Priv. Serv. Prov.';
      default:
        return '';
    }
  }

  const formatShortRole = (rawType) => {
    if (!rawType) {
      return null;
    }

    switch (rawType) {
      case 'SLF':
        return 'SLF';
      case 'READER':
        return 'RO';
      case 'SUPER':
        return 'SA';
      case 'STANDARD':
        return 'STD';
      case 'CEO':
        return 'CEO';
      case 'PSP':
        return 'PSP';
      default:
        return '';
    }
  }

  let roleString = formatRole(role);

  return (
      <Chip avatar={<Avatar>{formatShortRole(role)}</Avatar>} label={roleString} />
  );
}

export default withStyles(styles)(UserRoleChip)