import React, {useCallback, useEffect, useRef, useState} from 'react';
import { CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {getVisits} from "../../../utils/api";
import DataGridActionBar from "../../parts/DataGridActionBar";
import {formatTime} from "../../../utils/timeHelper";
import debounce from "lodash.debounce";
import TextField from "@material-ui/core/TextField";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function Visits(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedVisits, setPaginatedVisits] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getVisits(page, size, searchTerm);
          const visitsData = result.data;

          if (!cancelRequest.current) {
            if (visitsData && Object.keys(visitsData).length > 0) {
              setPaginatedVisits(visitsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedVisits) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Village name, FB number"
                id="visit-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={paginatedVisits.content}
              columns={[
                { field: 'household', headerName: 'Village', flex: 1,
                  valueFormatter: (params) =>
                      (params.value.addressData.village.villageName) },
                {
                  field: "fbNumber",
                  headerName: "Household FB number",
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.household.fbNumber)
                },
                { field: 'dateVisited', headerName: 'Date visited',
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateVisited))
                },
                {
                  field: "agent",
                  headerName: "Agent",
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.crsAgent ?
                              params.row.crsAgent.person.personalData.firstName +
                              ' ' + params.row.crsAgent.person.personalData.lastName
                              : ''
                      )
                },
                { field: 'dateCreated', headerName: 'Date created',
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated))
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <DataGridActionBar data={params.row}>
                    </DataGridActionBar>;
                  }
                }
              ]}
              density="compact"
              pagination
              pageSize={paginatedVisits.size}
              rowCount={paginatedVisits.totalElements}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Visits);