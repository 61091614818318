import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createFbsCourse, deleteFacilitator, getFbsCourses, updateFbsCourse
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import AddFbsCourseDialog from "../dialogs/AddFbsCourseDialog";
import debounce from "lodash.debounce";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function Courses(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedCourses, setPaginatedCourses] = useState('');
  const [courseBuffer, setCourseBuffer] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const addConfirmationHandler =
      async (name, additionalInfo, startDate, endDate) => {
        await createFbsCourse({
          'name': name,
          'additionalInfo': additionalInfo,
          'dateStart': startDate,
          'dateEnd': endDate,
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (courseId, name, additionalInfo, startDate, endDate) => {
        await updateCourseDataAndDispatch(courseId, {
          'name': name,
          'additionalInfo': additionalInfo,
          'dateStart': startDate,
          'dateEnd': endDate,
        });
        await fetchData();
      };

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (facilitatorId, deleteType = 'SOFT') => {
    await deleteFacilitator(facilitatorId, deleteType);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getFbsCourses(page, size, searchTerm);
          const coursesData = result.data;

          if (!cancelRequest.current) {
            if (coursesData && Object.keys(coursesData).length > 0) {
              setPaginatedCourses(coursesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const updateCourseDataAndDispatch = async (courseId, fields) => {
    try {
      let buffer = {
        ...courseBuffer
      };

      setCourseBuffer(buffer);

      const { data: courseData } = await updateFbsCourse(
          courseId,
          fields
      );

      if (!cancelRequest.current) {
        setCourseBuffer(courseData);
      }
    } catch (error) {
      setCourseBuffer(courseBuffer);
    }
  };

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedCourses) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Name"
                id="course-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '95%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedCourses.content}
                getRowClassName={(params) => `row-${params.row.deleted === true ? 'deleted' : 'active'}`}
                columns={[
                  { field: 'name', headerName: 'Name', flex: 1 },
                  { field: 'additionalInfo', headerName: 'Additional info', flex: 1 },
                  { field: 'dateStart', headerName: 'Start date', flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateStart))
                  },
                  { field: 'dateEnd', headerName: 'End date', flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateEnd))
                  },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                            <AddFbsCourseDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                            <DataGridActionBar
                                data={params.row}
                                type={'FBS Course'}
                                deleteHandler={removeHandler}
                                relations={[
                                  'FBS Module'
                                ]}/>
                          </>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedCourses.size}
                rowCount={paginatedCourses.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <AddFbsCourseDialog
              confirmationHandler={addConfirmationHandler}/>
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Courses);