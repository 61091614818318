import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import {
  searchLeadFarmers
} from "../../utils/api";
import {UserContext} from "../auth/UserProvider";
import Tooltip from "@material-ui/core/Tooltip";
import AddPersonIcon from "@material-ui/icons/PersonAdd";
import {IconButton} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddLeadFarmerDialog(props) {
  const { classes, confirmationHandler } = props;
  const [leadFarmers, setLeadFarmers] =
      React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const loading = open && options.length === 0;

  const authState = useContext(UserContext);

  const onInputValueChange = async (event) => {
    if (event) {
      await searchLeadFarmers(event.target.value)
      .then((response) => setOptions(response.data));
    }
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await searchLeadFarmers('');

      if (active) {
        if (response.data) {
          setOptions(response.data);
        } else {
          setOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLeadFarmers([]);
  };

  const handleConfirm = () => {
    confirmationHandler(leadFarmers);
    handleClose();
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        <Tooltip title="Add Lead Farmers">
          <IconButton aria-label="edit"
                      onClick={handleClickOpen}>
            <AddPersonIcon />
          </IconButton>
        </Tooltip>
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add lead farmers</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Choose the lead farmers to connect to SLF
            </DialogContentText>
            <Autocomplete
                multiple
                disableCloseOnSelect
                id="lead-farmer-multi-select"
                options={options}
                getOptionLabel={(option) =>  `${option.person.personalData.firstName} ${option.person.personalData.lastName} (${option.ward ? option.ward.wardName + ', ' + option.ward.district.districtName : ''})`}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Lead farmers..."
                    />
                )}
                onInputChange={onInputValueChange}
                name="leadFarmers"
                variant={'outlined'}
                value={leadFarmers}
                onChange={(event, value) => setLeadFarmers(value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddLeadFarmerDialog)