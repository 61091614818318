import React, {useContext, useEffect, useRef, useState} from 'react';
import {CircularProgress} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import {
  createUser, getUsers
} from "../../utils/api";
import {formatTime} from "../../utils/timeHelper";
import {UserContext} from "../auth/UserProvider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import UserRoleChip from "../parts/UserRoleChip";
import firebase from "firebase/app";
import MuiPhoneNumber from 'material-ui-phone-number';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const styles = () => ({
  paper: {
    padding: 15,
    paddingBottom: '1rem',
    marginTop: 10
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  error: {
    color: 'red',
  },
  input: {
    marginTop: '1rem',
  }
});


function MyProfileSettings(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [code, setCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [mfaRequired, setMfaRequired] = useState(false);
  const [user, setUser] = useState('');
  const [phone, setPhone] = useState(undefined);
  const [error, setError] = useState(false);
  const [twoFactorError, setTwoFactorError] = useState('');
  const [recaptcha, setRecaptcha] = useState(undefined);

  useEffect(() => {
    const verifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
      size: "invisible",
    });
    if (!recaptcha) {
      verifier.verify().then(() => setRecaptcha(verifier));
    }
  }, []);

  const authState = useContext(UserContext);

  const handleOnChange = (value) => {
    setPhone(value);
    console.log(phone);
  }

  const onVerifyEmail = () => {
    console.log(authState.user.sendEmailVerification());
  }

  const onCodeChange = (e) => {
    setCode(e.target.value);
  };

  const onEnableMfa = () => {
    authState.user.multiFactor
      .getSession()
      .then(function(multiFactorSession) {
        // Specify the phone number and pass the MFA session.
        var phoneInfoOptions = {
          phoneNumber: phone,
          session: multiFactorSession
        };
        var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        if (!recaptcha) {
          const verifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
            size: "invisible",
          });
          if (!recaptcha) {
            verifier.verify().then(() => setRecaptcha(verifier));
          }
        }
        // Send SMS verification code.
        phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptcha)
          .catch((e) => {
            setTwoFactorError(`${e.message}`);
            setError(true);
          })
          .then((response) => {
            setVerificationId(response);
            setMfaRequired(true);
          });
      }).catch(function (e) {
        setTwoFactorError(`${e.message}`);
        setError(true);
      })
  }

  const onEnrollMfa = () => {
      // Ask user for the verification code.
      let cred = firebase.auth.PhoneAuthProvider.credential(verificationId, code);
      let multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      // Complete enrollment.
      authState.user.multiFactor.enroll(multiFactorAssertion, 'Phone number')
        .catch(function (e) {
          console.log(e);
          setTwoFactorError(`${e.message}`);
          setError(true);
      });
  }

  const cancelRequest = useRef();

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getUsers(page, size);
          const rolesData = result.data;

          if (!cancelRequest.current) {
            if (rolesData && Object.keys(rolesData).length > 0) {
              setUser(rolesData.find(u => u.uid === authState.user.uid))
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
            <div id="recaptcha"> </div>
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            Access denied!
            <div id="recaptcha"> </div>
          </>
      );
    }

    if (isLoading || !user) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
            <div id="recaptcha"> </div>
          </>
      );
    }

    return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={6}>
            <Paper className={classes.paper}>
              <UserRoleChip role={user.role}/>
              <Typography style={{ marginTop: '1rem', marginBottom: '1rem' }} variant="h5" color="textPrimary">
                Detailed user information
              </Typography>
              <Typography component={'span'} style={{ marginTop: '1rem' }} variant="body1" color="textPrimary">
                {user &&
                    <>
                      <div><strong>Name:</strong> {user.name ? user.name : ''}</div>
                      <div><strong>Email:</strong> {user.email ? user.email : ''}</div>
                      <div><strong>Created:</strong> {user.dateCreated ? formatTime(user.dateCreated, 'dd.LL.yyyy HH:mm') : ''}</div>
                    </>
                }
              </Typography>
              <Typography style={{ marginTop: '1rem' }} variant="h5" color="textPrimary">
                Two-factor authentication
              </Typography>
              <Typography style={{ marginTop: '1rem' }} variant="body1" color="textPrimary">
                Two factor authentication provides additional security for your account. In order to activate two-factor authentication, verify your email address and phone number in the following two steps.
              </Typography>
              <Typography style={{ marginTop: '1rem' }} variant="h6" color="textPrimary">
                1. Account ownership verification
              </Typography>
              <Typography style={{ marginTop: '1rem' }} variant="body1" color="textPrimary">
                In order to make sure that the person signing up for two-factor authentication is the same as the owner of this account, please make sure that you have recently signed in (in the last 5 minutes). If not, please sign out and log in again. Then you will be able to proceed with step 2.
              </Typography>
              <Typography style={{ marginTop: '1rem' }} variant="h6" color="textPrimary">
                2. Email verification
              </Typography>
              { user && user.isEmailVerified &&
                <Typography style={{ marginTop: '1rem' }} variant="body1" color="textPrimary">Your email is already verified, go to step 3.</Typography>
              }
              { user && !user.isEmailVerified &&
                <>
                  <Typography style={{ marginTop: '1rem' }} variant="body1" color="textPrimary">Your email needs to be verified, click the button below and use the link in the email you receive!</Typography>
                  <Button
                      style={{marginTop: 22}}
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={onVerifyEmail}>
                    Send verification email
                  </Button>
                </>
              }
              <Typography style={{ marginTop: '1rem', marginBottom: '1rem' }} variant="h6" color="textPrimary">
                3. Activate using phone number
              </Typography>
              <MuiPhoneNumber
                  defaultCountry={'zm'}
                  preferredCountries={['zm','de']}
                  regions={['africa', 'europe']}
                  variant={'outlined'}
                  onChange={handleOnChange}/>
              <br/>
              <Typography style={{ marginTop: '1rem' }} className={classes.error} variant="caption" component="div" hidden={!error}>
                {twoFactorError}
              </Typography>
              <div hidden={mfaRequired}>
                <Button
                    style={{marginTop: 22}}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={onEnableMfa}>Send verification to phone
                </Button>
              </div>
              <div hidden={!mfaRequired}>
                <TextField
                    className={classes.input}
                    fullWidth
                    id="code"
                    value={code}
                    label="Verification code"
                    variant="outlined"
                    type="text"
                    onChange={onCodeChange}/>
              </div>
              <div hidden={!mfaRequired}>
                <Button
                    style={{marginTop: 22}}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={onEnrollMfa}>Enroll
                </Button>
              </div>
            </Paper>
          </Grid>
          <div id="recaptcha"> </div>
        </Grid>
    );
  };

  return renderData();
}

export default withStyles(styles)(MyProfileSettings);