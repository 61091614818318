import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {getReferrals} from "../../../utils/api";
import DataGridActionBar from "../../parts/DataGridActionBar";
import debounce from "lodash.debounce";
import {formatTime} from "../../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function Referrals(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedReferrals, setPaginatedReferrals] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getReferrals(page, size, searchTerm);
          const referralsData = result.data;

          if (!cancelRequest.current) {
            if (referralsData && Object.keys(referralsData).length > 0) {
              setPaginatedReferrals(referralsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedReferrals) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Beneficiary first, last name, output type name"
                id="referral-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={paginatedReferrals.content}
              columns={[
                { field: 'monthReferred', headerName: 'Month referred' },
                { field: 'yearReferred', headerName: 'Year referred' },
                { field: 'outputType', headerName: 'Referral Facility',
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.referralFacility.nameFacilityType)
                },
                { field: 'person', headerName: 'Beneficiary',
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.person ? params.row.person.firstName + ' ' +
                          params.row.person.lastName : '')
                },
                {
                  field: "agent",
                  headerName: "Agent",
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.crsAgent ?
                              params.row.crsAgent.person.personalData.firstName +
                              ' ' + params.row.crsAgent.person.personalData.lastName
                              : ''
                      )
                },
                { field: 'dateCreated', headerName: 'Date created',
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated))
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <DataGridActionBar data={params.row}>
                    </DataGridActionBar>;
                  }
                }
              ]}
              density="compact"
              pagination
              pageSize={paginatedReferrals.size}
              rowCount={paginatedReferrals.totalElements}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Referrals);