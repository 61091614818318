import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import {
  searchGizIntermediaries, searchWorkingMaterialTypes
} from "../../utils/api";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {UserContext} from "../auth/UserProvider";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddWorkingMaterialDistributionDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [quantity, setQuantity] = React.useState(0);
  const [type, setType] = React.useState(undefined);
  const [distributionDate, setDistributionDate] = React.useState(undefined);
  const [intermediary, setIntermediary] = React.useState(undefined);
  const [additionalInfo, setAdditionalInfo] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const handleClickOpen = () => {
    setQuantity(entity && entity.quantity ? entity.quantity : '');
    setType(entity && entity.workingMaterialType ?
        entity.workingMaterialType : '');
    setDistributionDate(entity && entity.dateDistributed ?
        entity.dateDistributed : '');
    setIntermediary(entity && entity.gizIntermediary ?
        entity.gizIntermediary : '');
    setAdditionalInfo(entity && entity.additionalInfo ?
        entity.additionalInfo : '');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setQuantity(0);
    setDistributionDate(undefined);
    setIntermediary(undefined);
    setAdditionalInfo(undefined);
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(quantity, type, intermediary, distributionDate, additionalInfo) :
        confirmationHandler(entity.id, quantity, type, intermediary, distributionDate, additionalInfo);
    handleClose();
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const onChangeType = value => {
    setType(value);
  };

  const onChangeIntermediary = value => {
    setIntermediary(value);
  };

  const handlePurchaseDateChange = (event) => {
    setDistributionDate(event.target.value);
  };

  const handleAdditionalInfoChange = (event) => {
    setAdditionalInfo(event.target.value);
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add Distribution
          </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} Working Material Distribution</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the material distribution
            </DialogContentText>
            <BasicAutocomplete handleChange={onChangeType} searchFct={searchWorkingMaterialTypes} label={'Type'} property={'name'} currentValue={type} />
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="quantity"
                    label="Quantity"
                    type="number"
                    inputProps={{
                      step: ".1"
                    }}
                    value={quantity}
                    onChange={handleQuantityChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <BasicAutocomplete handleChange={onChangeIntermediary} searchFct={searchGizIntermediaries} label={'Intermediary'} property={'intermediary'} />
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="purchaseDate"
                  label="Date of purchase"
                  type="date"
                  value={distributionDate}
                  onChange={handlePurchaseDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="additional-info"
                  label="Additional info"
                  type="text"
                  value={additionalInfo}
                  onChange={handleAdditionalInfoChange}
                  fullWidth
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddWorkingMaterialDistributionDialog)