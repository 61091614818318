import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createMultipleProjectOutputs,
  getProjectOutputDump,
  getProjectOutputs, updateLesson
} from "../../../utils/api";
import DataGridActionBar from "../../parts/DataGridActionBar";
import {UserContext} from "../../auth/UserProvider";
import FileDownload from "js-file-download";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/GetApp";
import DumpLoadingIndicator from "../../parts/DumpLoadingIndicator";
import AddProjectOutputDialog from "../../dialogs/AddProjectOutputDialog";
import CreationSnackbar from "../../dialogs/CreationSnackbar";
import debounce from "lodash.debounce";
import {formatTime} from "../../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '13rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function ProjectOutputs(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedProjectOutputs, setPaginatedProjectOutputs] = useState('');
  const [outputBuffer, setOutputBuffer] = useState('');
  const [successBarText, setSuccessBarText] = useState('');
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const authState = useContext(UserContext);

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getProjectOutputDump().then((response) => {
            FileDownload(response.data, 'project_outputs.csv');
            setIsLoadingDump(false);
          });
        } catch (error) {
          console.log(error);
        }
      };

  const addConfirmationHandler =
      async (values, beneficiary) => {
        let body = [];

        values.outputTypes.map(outputType => {
          let bodyPart = {
            'outputType': outputType,
            'term': +values.term,
            'year': +values.year
          };

          if (beneficiary) {
            bodyPart['person'] = beneficiary.person;
          }

          body.push(bodyPart);
        })
        await createMultipleProjectOutputs(body).then((entity) => {
          setSuccessBarText(entity.data ? `${values.outputTypes.length} project output(s) ` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, values, beneficiary) => {
        if (values.outputTypes.length === 1) {
          let body = {
            'outputType': values.outputTypes[0],
            'term': +values.term,
            'year': +values.year
          };

          if (beneficiary) {
             body['person'] = beneficiary.person;
          }

          await updateOutputAndDispatch(entityId, body);
          await fetchData();
        }
      };

  const updateOutputAndDispatch = async (outputId, fields) => {
    try {
      let buffer = {
        ...outputBuffer
      };

      setOutputBuffer(buffer);

      const { data: data } = await updateLesson(
          outputId,
          fields
      );

      if (!cancelRequest.current) {
        setOutputBuffer(data);
      }
    } catch (error) {
      setOutputBuffer(outputBuffer);
    }
  };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getProjectOutputs(page, size, searchTerm);
          const projectOutputsData = result.data;

          if (!cancelRequest.current) {
            if (projectOutputsData && Object.keys(projectOutputsData).length > 0) {
              setPaginatedProjectOutputs(projectOutputsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const handleSuccessBarState = (open) => {
    setShowSuccessBar(open);
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedProjectOutputs) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
      <>
        <div className={classes.contentWrapper} style={{}}>
          <TextField
              label="Beneficiary first, last name, output name"
              id="output-filter"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleSearchTermChanged}
          />
        </div>
        <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={paginatedProjectOutputs.content}
              columns={[
                { field: 'term', headerName: 'Term' },
                { field: 'year', headerName: 'Year' },
                { field: 'outputType', headerName: 'Output Type',
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.outputType.nameProjectOutputType)
                },
                { field: 'person', headerName: 'Beneficiary',
                  flex: 1,
                  renderCell: (params) =>
                      (params.row.person ? params.row.person.personalData.firstName + ' ' +
                          params.row.person.personalData.lastName : '')
                },
                { field: 'dateCreated', headerName: 'Date created',
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated))
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <>
                      <AddProjectOutputDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                      <DataGridActionBar data={params.row}>
                      </DataGridActionBar>
                    </>;
                  }
                }
              ]}
              density="compact"
              pagination
              pageSize={paginatedProjectOutputs.size}
              rowCount={paginatedProjectOutputs.totalElements}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
        </div>
        <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
          <Fab
              size="small"
              color="primary"
              hidden={!isLoadingDump}
              aria-label="add"
              onClick={() => fetchDump()}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <DownloadIcon/>
          </Fab>
        </div>
        <AddProjectOutputDialog
            confirmationHandler={addConfirmationHandler} />
        <CreationSnackbar successBarOpen={showSuccessBar} successBarHandler={handleSuccessBarState} successText={successBarText} />
      </>
    );
  };

  return renderData();
}

export default withStyles(styles)(ProjectOutputs);