import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  searchCrsIecTypes,
  searchHouseholds, searchNutritionVolunteers
} from "../../utils/api";
import {UserContext} from "../auth/UserProvider";
import * as Yup from "yup";
import {useFormik} from "formik";
import {IconButton, Tooltip} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddCrsIecDistributionDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const IecDistributionSchema = Yup.object().shape({
    dateDistributed: Yup.date().min(new Date("2015-01-01"), 'Date cannot be before 2015!').max(new Date(), 'Date cannot be in the future!').required('Required'),
    iecType: Yup.object().nullable().required('Required'),
    household: Yup.object().nullable().required('Required'),
    agent: Yup.object().nullable().required('Required'),
  });

  const iecDistributionFormik = useFormik({
    initialValues: {
      dateDistributed: '',
      iecType: null,
      household: null,
      agent: null,
    },
    validationSchema: IecDistributionSchema,
  });

  const setFormFieldsTouched = async () => {
    await iecDistributionFormik.setFieldTouched('dateDistributed', true);
    await iecDistributionFormik.setFieldTouched('iecType', true);
    await iecDistributionFormik.setFieldTouched('household', true);
    await iecDistributionFormik.setFieldTouched('agent', true);
  }

  const handleClickOpen = async () => {
    if (entity) {
      await iecDistributionFormik.setFieldValue('dateDistributed', entity.dateDistributed);
      await iecDistributionFormik.setFieldValue('iecType', entity.iecType);
      await iecDistributionFormik.setFieldValue('household', entity.household);
      await iecDistributionFormik.setFieldValue('agent', entity.agent);
      await setFormFieldsTouched();
    }
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    await iecDistributionFormik.resetForm();
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(iecDistributionFormik.values) :
        confirmationHandler(entity.id, iecDistributionFormik.values);
    handleClose();
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        {action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add distribution
          </Fab>
        }
        {action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} IEC distribution</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the IEC distribution
            </DialogContentText>
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="dateDistributed"
                  label="Date distributed"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="dateDistributed"
                  variant={'outlined'}
                  value={iecDistributionFormik.values.dateDistributed}
                  onChange={iecDistributionFormik.handleChange}
                  error={Boolean(iecDistributionFormik.errors.dateDistributed)}
                  helperText={iecDistributionFormik.errors.dateDistributed}
              />
            </div>
            <BasicAutocomplete
                handleChange={(value) => iecDistributionFormik.setFieldValue("iecType", value)}
                searchFct={searchCrsIecTypes}
                label={'IEC Type'}
                property={'nameIecType'}
                currentValue={iecDistributionFormik.values.iecType}
                error={Boolean(iecDistributionFormik.errors.iecType)}
                helperText={iecDistributionFormik.errors.iecType} />
            <BasicAutocomplete
                handleChange={(value) => iecDistributionFormik.setFieldValue("household", value)}
                searchFct={searchHouseholds}
                label={'Household'}
                property={'fbNumber'}
                currentValue={iecDistributionFormik.values.household}
                error={Boolean(iecDistributionFormik.errors.household)}
                helperText={iecDistributionFormik.errors.household} />
            <BasicAutocomplete
                handleChange={(value) => iecDistributionFormik.setFieldValue("agent", value)}
                searchFct={searchNutritionVolunteers}
                label={'Agent'}
                property={'nutritionalVolunteer'}
                currentValue={iecDistributionFormik.values.agent}
                error={Boolean(iecDistributionFormik.errors.agent)}
                helperText={iecDistributionFormik.errors.agent} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={!iecDistributionFormik.dirty || Object.keys(iecDistributionFormik.errors).length !== 0} onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddCrsIecDistributionDialog)