import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createIecMaterialDistribution,
  getIecMaterialDistributions
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import AddIecDistributionDialog from "../dialogs/AddIecDistributionDialog";
import debounce from "lodash.debounce";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function IecMaterialsDistribution(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedIecMaterialDistributions, setPaginatedIecMaterialDistributions] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const addConfirmationHandler =
      async (quantity, type, intermediary, household, distributionDate) => {
        await createIecMaterialDistribution({
          'quantity': +quantity,
          'iecMaterialType': type,
          'gizIntermediary': intermediary,
          'household': household,
          'dateDistributed': distributionDate,
        });
        await fetchData();
      };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getIecMaterialDistributions(page, size, searchTerm);
          const distributionData = result.data;

          if (!cancelRequest.current) {
            if (distributionData && Object.keys(distributionData).length > 0) {
              setPaginatedIecMaterialDistributions(distributionData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedIecMaterialDistributions) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Type, FB number, intermediary name"
                id="dropout-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={paginatedIecMaterialDistributions.content}
              columns={[
                { field: 'iecMaterialType', headerName: 'Type', flex: 1,
                  valueFormatter: (params) =>
                      (params.value.name), },
                {
                  field: 'gizIntermediary', headerName: 'Intermediary', flex: 1,
                  renderCell: (params) =>
                      (params.row.gizIntermediary ?
                              params.row.gizIntermediary.person.personalData.firstName
                              +
                              ' '
                              + params.row.gizIntermediary.person.personalData.lastName
                              : ' '
                      )
                },
                {
                  field: "household",
                  headerName: "Household FB number",
                  flex: 1,
                  renderCell: (params) =>
                      (params.value.fbNumber)
                },
                { field: 'quantity', headerName: 'Quantity', flex: 1 },
                { field: 'dateDistributed', headerName: 'Date distributed', flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateDistributed))
                },
                { field: 'dateCreated', headerName: 'Date created',
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated))
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <DataGridActionBar data={params.row}>
                    </DataGridActionBar>;
                  }
                }
              ]}
              density="compact"
              pagination
              pageSize={paginatedIecMaterialDistributions.size}
              rowCount={paginatedIecMaterialDistributions.totalElements}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
          <AddIecDistributionDialog
              confirmationHandler={addConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(IecMaterialsDistribution);