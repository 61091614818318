import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import {
  searchSiteVisits, searchTechniques
} from "../../utils/api";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {UserContext} from "../auth/UserProvider";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddTechniqueUsageDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [siteVisit, setSiteVisit] = React.useState(undefined);
  const [techniques, setTechniques] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const authState = useContext(UserContext);

  React.useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchTechniques('');

      if (active) {
        if (response.data) {
          setOptions(response.data);
        } else {
          setOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSiteVisit(undefined);
    setTechniques([]);
  };

  const handleConfirm = () => {
    confirmationHandler(techniques, siteVisit);
    handleClose();
  };

  const onChangeSiteVisit = value => {
    setSiteVisit(value);
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add crop grown
          </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
            <Tooltip title="Edit">
              <IconButton aria-label="edit"
                          onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add technique usage</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of crop grown
            </DialogContentText>
            <Autocomplete
                multiple
                disableCloseOnSelect
                id="technique-multi-select"
                options={options}
                getOptionLabel={(option) =>  `${option.name}`}
                value={techniques}
                onChange={(event, value) => setTechniques(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Techniques..."
                    />
                )}
            />
            <BasicAutocomplete handleChange={onChangeSiteVisit} searchFct={searchSiteVisits} label={'Site visit'} property={'siteVisit'} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddTechniqueUsageDialog)