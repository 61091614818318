import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete
  from "../autocompletes/BasicAutocomplete";
import {
  searchFarmingGroups, searchHouseholds
} from "../../utils/api";
import {UserContext} from "../auth/UserProvider";
import {Autocomplete} from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddFarmingGroupDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [households, setHouseholds] = React.useState([]);
  const [householdOptions, setHouseholdOptions] = React.useState([]);
  const [farmingGroup, setFarmingGroup] = React.useState(undefined);
  const [dateRegistered, setDateRegistered] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setHouseholds([]);
    setFarmingGroup(undefined);
    setDateRegistered(undefined);
  };

  const handleConfirm = () => {
    confirmationHandler(farmingGroup, households, dateRegistered);
    handleClose();
  };

  const onChangeFarmingGroup = value => {
    setFarmingGroup(value);
  };

  const handleDateRegisteredChange = (event) => {
    setDateRegistered(event.target.value);
  };

  React.useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchHouseholds('');

      if (active) {
        if (response.data) {
          setHouseholdOptions(response.data);
        } else {
          setHouseholdOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add group member
          </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
            <Tooltip title="Edit">
              <IconButton aria-label="edit"
                          onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} farming group member</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the group member
            </DialogContentText>
            <Autocomplete
                multiple
                disableCloseOnSelect
                id="households-multi-select"
                options={householdOptions}
                getOptionLabel={(option) =>  `${option.fbNumber}`}
                value={households}
                variant={'outlined'}
                name="households"
                onChange={(event, value) => setHouseholds(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Households..."
                    />
                )}
                onInputChange={async (event) => {
                  if (event) {
                    await searchHouseholds(event.target.value).then((response) => setHouseholdOptions(response.data));
                  }}
                }
            />
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="dateRegistered"
                    label="Date registered"
                    type="date"
                    value={dateRegistered}
                    onChange={handleDateRegisteredChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </FormControl>
            </div>
            <BasicAutocomplete handleChange={onChangeFarmingGroup} searchFct={searchFarmingGroups} label={'Farming group'} property={'nameFarmingGroup'} currentValue={farmingGroup} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddFarmingGroupDialog)