import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createMultipleTrainingSessionParticipants,
  deleteTrainingSessionParticipant,
  getTrainingSessionParticipants,
  getTrainingSessionParticipantsDump,
  updateTrainingSessionParticipant
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import AddTrainingSessionParticipantDialog
  from "../dialogs/AddTrainingSessionParticipantDialog";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/GetApp";
import FileDownload from "js-file-download";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";
import {UserContext} from "../auth/UserProvider";
import CreationSnackbar from "../dialogs/CreationSnackbar";
import debounce from "lodash.debounce";
import {formatTime} from "../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '15rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function Participants(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedParticipants, setPaginatedParticipants] = useState('');
  const [successBarText, setSuccessBarText] = useState('');
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [participantBuffer, setParticipantBuffer] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const authState = useContext(UserContext);

  const cancelRequest = useRef();

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getTrainingSessionParticipantsDump().then((response) => {
            FileDownload(response.data, 'beneficiary_trainings.csv');
            setIsLoadingDump(false);
          });
        } catch (error) {
          console.log(error);
        }
      };

  const addConfirmationHandler =
      async (values) => {
        let body = [];

        values.trainingSessions.map(session => {
          values.persons.map(person => {
            body.push({
              'trainingSession': session,
              'person': person,
          });
        })});

        await createMultipleTrainingSessionParticipants(body).then((entity) => {
          setSuccessBarText(entity.data ? `${values.trainingSessions.length} session participant(s) ` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, values) => {
        console.log(values);
        await updateDistrictDataAndDispatch(entityId, {
          'trainingSession': values.trainingSessions[0],
          'person': values.persons[0],
        });
        await fetchData();
      };

  const updateDistrictDataAndDispatch = async (participantId, fields) => {
    try {
      let buffer = {
        ...participantBuffer
      };

      setParticipantBuffer(buffer);

      const { data: data } = await updateTrainingSessionParticipant(
          participantId,
          fields
      );

      if (!cancelRequest.current) {
        setParticipantBuffer(data);
      }
    } catch (error) {
      setParticipantBuffer(participantBuffer);
    }
  };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (participantId) => {
    await deleteTrainingSessionParticipant(participantId);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getTrainingSessionParticipants(page, size, searchTerm);
          const participantsData = result.data;

          if (!cancelRequest.current) {
            if (participantsData && Object.keys(participantsData).length > 0) {
              setPaginatedParticipants(participantsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const handleSuccessBarState = (open) => {
    setShowSuccessBar(open);
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedParticipants) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Participant first, last name, session name"
                id="participant-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedParticipants.content}
                columns={[
                  { field: 'trainingSession', headerName: 'Session', width: 300,
                    valueFormatter: (params) =>
                        (params.value ? params.value.trainingLesson.name + ' (' + params.value.dateConducted + ')' : ''), },
                  {
                    field: "person",
                    headerName: "Participant", flex: 1,
                    renderCell: (params) => (
                        params.value ? params.value.personalData.firstName + ' ' + params.value.personalData.lastName : ''
                    )
                  },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                        <AddTrainingSessionParticipantDialog
                            confirmationHandler={updateConfirmationHandler}
                            action={'edit'}
                            entity={params.row}/>
                        <DataGridActionBar deleteHandler={removeHandler} data={params.row}>
                        </DataGridActionBar>
                      </>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedParticipants.size}
                rowCount={paginatedParticipants.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
            <Fab
                size="small"
                color="primary"
                hidden={!isLoadingDump}
                aria-label="add"
                onClick={() => fetchDump()}
                className={classes.fab}
                style={{position: 'fixed', marginBottom: '1rem'}}
            >
              <DownloadIcon/>
            </Fab>
          </div>
          <AddTrainingSessionParticipantDialog
              confirmationHandler={addConfirmationHandler} />
          <CreationSnackbar
              successBarOpen={showSuccessBar}
              successBarHandler={handleSuccessBarState}
              successText={successBarText} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Participants);