import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createBeneficiaryDropout, deleteBeneficiaryDropout,
  getBeneficiaryDropouts, getBeneficiaryDropoutsDump, updateBeneficiaryDropout
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import AddBeneficiaryDropoutDialog
  from "../dialogs/AddBeneficiaryDropoutDialog";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/GetApp";
import {UserContext} from "../auth/UserProvider";
import FileDownload from "js-file-download";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";
import CreationSnackbar from "../dialogs/CreationSnackbar";
import debounce from "lodash.debounce";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '14rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function BeneficiaryDropouts(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedTrainers, setPaginatedTrainers] = useState('');
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [successBarText, setSuccessBarText] = useState('');
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const authState = useContext(UserContext);

  const cancelRequest = useRef();

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getBeneficiaryDropoutsDump()
          .then((response) => {
            FileDownload(response.data, 'beneficiary_dropouts.csv');
            setIsLoadingDump(false);
          });
        } catch (error) {
          console.log(error);
        }
      };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (dropoutId, deleteType = 'SOFT') => {
    await deleteBeneficiaryDropout(dropoutId, deleteType);
    await fetchData();
  };

  const handleSuccessBarState = (open) => {
    setShowSuccessBar(open);
  }

  const addConfirmationHandler =
      async (values) => {

        let body = {
          'person': values.person,
          'dateLeft': values.dateLeft,
          'reasonLeaving': values.reason
        }

        await createBeneficiaryDropout(body).then((entity) => {
          console.log(entity);
          setSuccessBarText(entity.data ? `Dropout ${entity.data.person.personalData.firstName} ${entity.data.person.personalData.lastName} successfully created` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, values) => {

        let body = {
          'person': values.person,
          'dateLeft': values.dateLeft,
          'reasonLeaving': values.reason
        }

        await updateBeneficiaryDropout(entityId, body);
        await fetchData();
      };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getBeneficiaryDropouts(page, size, searchTerm);
          const trainersData = result.data;

          if (!cancelRequest.current) {
            if (trainersData && Object.keys(trainersData).length > 0) {
              setPaginatedTrainers(trainersData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedTrainers) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
      <>
        <div className={classes.contentWrapper} style={{}}>
          <TextField
              label="Name, district, ward, camp, village"
              id="dropout-filter"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleSearchTermChanged}
          />
        </div>
        <div style={{ flexGrow: 1, height: '95%', paddingBottom: '2.5rem' }}>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={paginatedTrainers.content}
              columns={[
                {
                  field: "person",
                  headerName: "Dropout name", flex: 1,
                  renderCell: (params) => (
                      params.value ? params.value.personalData.firstName + ' ' + params.value.personalData.lastName : ''
                  )
                },
                {
                  field: "village",
                  headerName: "Village", flex: 1,
                  renderCell: (params) => (
                      params.row && params.row.person.household ? params.row.person.household.addressData.village.villageName : ''
                  )
                },
                {
                  field: "camp",
                  headerName: "Camp", flex: 1,
                  renderCell: (params) => (
                      params.row && params.row.person.household ? params.row.person.household.addressData.village.camp.campName : ''
                  )
                },
                {
                  field: "ward",
                  headerName: "Ward", flex: 1,
                  renderCell: (params) => (
                      params.row && params.row.person.household ? params.row.person.household.addressData.village.camp.ward.wardName : ''
                  )
                },
                {
                  field: "district",
                  headerName: "District", flex: 1,
                  renderCell: (params) => (
                      params.row && params.row.person.household ? params.row.person.household.addressData.village.camp.ward.district.districtName : ''
                  )
                },
                { field: 'dateLeft', headerName: 'Dropout date', flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.value))
                },
                {
                  field: "reasonLeaving",
                  headerName: "Reason", flex: 1,
                  renderCell: (params) => (
                      params.value ? params.value.nameReason : ''
                  )
                },
                {
                  field: 'actions',
                  headerName: 'Actions',
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <>
                      <AddBeneficiaryDropoutDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                      <DataGridActionBar
                          data={params.row}
                          type={'Beneficiary Dropout'}
                          deleteHandler={removeHandler}
                          relations={[
                          ]}>
                      </DataGridActionBar>
                    </>;
                  }
                }
              ]}
              density="compact"
              pagination
              pageSize={paginatedTrainers.size}
              rowCount={paginatedTrainers.totalElements}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
          <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
            <Fab
                size="small"
                color="primary"
                aria-label="add"
                onClick={() => fetchDump()}
                className={classes.fab}
                style={{position: 'fixed', marginBottom: '1rem'}}
            >
              <DownloadIcon/>
            </Fab>
          </div>
        </div>
        <AddBeneficiaryDropoutDialog confirmationHandler={addConfirmationHandler} />
        <CreationSnackbar successBarOpen={showSuccessBar} successBarHandler={handleSuccessBarState} successText={successBarText} />
      </>
    );
  };

  return renderData();
}

export default withStyles(styles)(BeneficiaryDropouts);