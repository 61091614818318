import React, {useContext, useEffect} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  getHouseholdBeneficiaries,
  searchHouseholds,
  searchProjectOutputTypes
} from "../../utils/api";
import {UserContext} from "../auth/UserProvider";
import FormControl from "@material-ui/core/FormControl";
import AutoComplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Autocomplete} from "@material-ui/lab";
import * as Yup from "yup";
import {useFormik} from "formik";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddProjectOutputDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [household, setHousehold] = React.useState(undefined);
  const [beneficiary, setBeneficiary] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [acOpen, setAcOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [outputOptions, setOutputOptions] = React.useState([]);

  const authState = useContext(UserContext);

  const ProjectOutputSchema = Yup.object().shape({
    term: Yup.number().required('Required'),
    year: Yup.number().required('Required'),
    outputTypes: Yup.array().min(1, 'Select at least one').required('Required'),
    household: Yup.object().nullable().required('Required'),
  });

  const projectOutputFormik = useFormik({
    initialValues: {
      term: 1,
      year: new Date().getFullYear(),
      outputTypes: [],
      household: null,
    },
    validationSchema: ProjectOutputSchema,
  });

  const setFormFieldsTouched = async () => {
    await projectOutputFormik.setFieldTouched('term', true);
    await projectOutputFormik.setFieldTouched('year', true);
    await projectOutputFormik.setFieldTouched('outputTypes', true);
    await projectOutputFormik.setFieldTouched('household', true);
  }

  useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchProjectOutputTypes('');

      if (active) {
        if (response.data) {
          setOutputOptions(response.data);
        } else {
          setOutputOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    onSelectHousehold();
  }, [household]);

  const handleClickOpen = async () => {
    if (entity) {
      await projectOutputFormik.setFieldValue('term', entity.term);
      await projectOutputFormik.setFieldValue('year', entity.year);
      await projectOutputFormik.setFieldValue('outputTypes', [ entity.outputType ]);
      await projectOutputFormik.setFieldValue('household', entity.household);
      await setFormFieldsTouched();
    }
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    await projectOutputFormik.resetForm();
    setHousehold(undefined);
    setBeneficiary(null);
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(projectOutputFormik.values, beneficiary) :
        confirmationHandler(entity.id, projectOutputFormik.values, beneficiary);
    handleClose();
  };

  const onBeneficiaryChange = value => {
    setBeneficiary(value);
  };

  const onSelectHousehold = async () => {
    if (household && household.id)
      await getHouseholdBeneficiaries(household.id)
      .then((response) => setOptions(response.data));
  }

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        {action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add Output
          </Fab>
        }
        {action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} Project Output</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the output
            </DialogContentText>
            <Autocomplete
                multiple
                disableCloseOnSelect
                id="output-multi-select"
                options={outputOptions}
                getOptionLabel={(option) =>  `${option.nameProjectOutputType}`}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Output types..."
                        error={Boolean(projectOutputFormik.errors.outputTypes)}
                        helperText={projectOutputFormik.errors.outputTypes}
                    />
                )}
                name="outputTypes"
                variant={'outlined'}
                value={projectOutputFormik.values.outputTypes}
                onChange={(event, value) => projectOutputFormik.setFieldValue("outputTypes", value)}
            />
            <br/>
            <DescriptionList>
              <DescriptionTerm>Term</DescriptionTerm>
              <Description>
                <Select
                    autoFocus
                    labelId="term-select"
                    id="term-select"
                    value={projectOutputFormik.values.term + ''}
                    variant="outlined"
                    name="term"
                    className={classes.selectEmpty}
                    onChange={projectOutputFormik.handleChange}
                >
                  <MenuItem value={'1'}>1</MenuItem>
                  <MenuItem value={'2'}>2</MenuItem>
                  <MenuItem value={'3'}>3</MenuItem>
                  <MenuItem value={'4'}>4</MenuItem>
                </Select>
              </Description>
            </DescriptionList>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="year"
                    label="Year"
                    InputProps={{ inputProps: { min: 2015, max: 2030 } }}
                    type="number"
                    fullWidth
                    name="year"
                    variant={'outlined'}
                    value={projectOutputFormik.values.year}
                    onChange={projectOutputFormik.handleChange}
                    error={Boolean(projectOutputFormik.errors.year)}
                    helperText={projectOutputFormik.errors.year}
                />
              </FormControl>
            </div>
            <BasicAutocomplete
                handleChange={(value) => {
                  projectOutputFormik.setFieldValue("household", value);
                  setHousehold(value);
                }}
                searchFct={searchHouseholds}
                label={'Household'}
                property={'fbNumber'}
                currentValue={projectOutputFormik.values.household}
                error={Boolean(projectOutputFormik.errors.household)}
                helperText={projectOutputFormik.errors.household} />
            { household &&
            <AutoComplete
                style={{ marginTop: 20 }}
                value={beneficiary}
                id="autocomplete"
                fullWidth
                open={acOpen}
                onOpen={() => {
                  setAcOpen(true);
                }}
                onClose={() => {
                  setAcOpen(false);
                }}
                onChange={(e, selectedValue) => onBeneficiaryChange(selectedValue)}
                getOptionSelected={(option, value) => option && value && option.name === value.name}
                getOptionLabel={(option) => {
                  return option.person.personalData.firstName + ' ' + option.person.personalData.lastName;
                }}
                onInputChange={onBeneficiaryChange}
                options={options}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={'Beneficiary'}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                          ),
                        }}
                    />
                )}
            />
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={!projectOutputFormik.dirty || Object.keys(projectOutputFormik.errors).length !== 0}  onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddProjectOutputDialog)