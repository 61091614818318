import React from 'react';

import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

export default function CreationSnackbar ({ successBarOpen = false, successBarHandler, successText}) {

  return (
      <Snackbar
          open={successBarOpen}
          autoHideDuration={3000}
          onClose={() => successBarHandler(false)}>
        <Alert onClose={() => successBarHandler(false)} severity="success" sx={{ width: '100%' }}>
          {`${successText} successfully created!`}
        </Alert>
      </Snackbar>
  );
}