import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  searchBeneficiaryRoles,
  searchBeneficiaryStatuses,
  searchHouseholds
} from "../../utils/api";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {UserContext} from "../auth/UserProvider";
import * as Yup from "yup";
import {useFormik} from "formik";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '1rem',
    right: '2rem',
  },
});

function AddBeneficiaryDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity, preselectHousehold } = props;
  const [gender, setGender] = React.useState('FEMALE');
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const BeneficiarySchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    dateOfBirth: Yup.string(),
    dateRegistered: Yup.string().required('Required'),
    household: Yup.object().nullable().required('Required'),
    relation: Yup.object().nullable().required('Required'),
    status: Yup.object().nullable().required('Required'),
  });

  const beneficiaryFormik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      dateRegistered: '',
      household: null,
      relation: null,
      status: null,
    },
    validationSchema: BeneficiarySchema,
  });

  const setFormFieldsTouched = async () => {
    await beneficiaryFormik.setFieldTouched('firstName', true);
    await beneficiaryFormik.setFieldTouched('lastName', true);
    await beneficiaryFormik.setFieldTouched('dateOfBirth', true);
    await beneficiaryFormik.setFieldTouched('dateRegistered', true);
    await beneficiaryFormik.setFieldTouched('household', true);
    await beneficiaryFormik.setFieldTouched('status', true);
    await beneficiaryFormik.setFieldTouched('relation', true);
  }

  const handleClickOpen = async () => {
    if (entity) {
      await beneficiaryFormik.setFieldValue('firstName', entity.person.personalData.firstName);
      await beneficiaryFormik.setFieldValue('lastName', entity.person.personalData.lastName);
      setGender(entity && entity.person.personalData.gender ?
          entity.person.personalData.gender : 'FEMALE');
      await beneficiaryFormik.setFieldValue('dateOfBirth', entity.person.personalData.dateOfBirth);
      await beneficiaryFormik.setFieldValue('dateRegistered', entity.person.dateRegistered);
      await beneficiaryFormik.setFieldValue('household', entity.person.household);
      await beneficiaryFormik.setFieldValue('status', entity.status);
      await beneficiaryFormik.setFieldValue('relation', entity.relation);
      await setFormFieldsTouched();
    }
    if (preselectHousehold) {
      await beneficiaryFormik.setFieldValue('household', preselectHousehold);
    }
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    await beneficiaryFormik.resetForm();
  };

  const handleConfirm = () => {
    action !== 'edit' ?
        confirmationHandler(beneficiaryFormik.values, gender) :
        confirmationHandler(entity.id, beneficiaryFormik.values, gender);
    handleClose();
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        { action === 'lessonCreate' &&
          <Button
              style={{marginTop: 22}}
              variant="contained"
              size="large"
              color="primary"
              onClick={handleClickOpen}>
            Add newborn
          </Button>
        }
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add beneficiary
          </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action !== 'edit' ? 'Add' : 'Edit'} { action !== 'lessonCreate' ? 'beneficiary' : 'newborn'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of beneficiary
            </DialogContentText>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="first-name"
                    label="First name"
                    type="text"
                    fullWidth
                    name="firstName"
                    variant={'outlined'}
                    value={beneficiaryFormik.values.firstName}
                    onChange={beneficiaryFormik.handleChange}
                    error={Boolean(beneficiaryFormik.errors.firstName)}
                    helperText={beneficiaryFormik.errors.firstName}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    margin="normal"
                    id="last-name"
                    label="Last name"
                    type="text"
                    fullWidth
                    name="lastName"
                    variant={'outlined'}
                    value={beneficiaryFormik.values.lastName}
                    onChange={beneficiaryFormik.handleChange}
                    error={Boolean(beneficiaryFormik.errors.lastName)}
                    helperText={beneficiaryFormik.errors.lastName}
                />
              </FormControl>
            </div>
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={gender} onChange={handleGenderChange}>
                  <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                  <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <TextField
                  margin="normal"
                  id="birthDate"
                  label="Date of birth"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="dateOfBirth"
                  variant={'outlined'}
                  value={beneficiaryFormik.values.dateOfBirth}
                  onChange={beneficiaryFormik.handleChange}
                  error={Boolean(beneficiaryFormik.errors.dateOfBirth)}
                  helperText={beneficiaryFormik.errors.dateOfBirth}
              />
            </div>
            <BasicAutocomplete
                handleChange={(value) => beneficiaryFormik.setFieldValue("household", value)}
                disabled={preselectHousehold}
                searchFct={searchHouseholds}
                label={'Household'}
                property={'fbNumber'}
                currentValue={beneficiaryFormik.values.household}
                error={Boolean(beneficiaryFormik.errors.household)}
                helperText={beneficiaryFormik.errors.household} />
            <div>
              <TextField
                  margin="normal"
                  id="dateRegistered"
                  label="Date registered"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="dateRegistered"
                  variant={'outlined'}
                  value={beneficiaryFormik.values.dateRegistered}
                  onChange={beneficiaryFormik.handleChange}
                  error={Boolean(beneficiaryFormik.errors.dateRegistered)}
                  helperText={beneficiaryFormik.errors.dateRegistered}
              />
            </div>
            <BasicAutocomplete
                handleChange={(value) => beneficiaryFormik.setFieldValue("relation", value)}
                searchFct={searchBeneficiaryRoles}
                label={'Role'}
                property={'nameRelation'}
                currentValue={beneficiaryFormik.values.relation}
                error={Boolean(beneficiaryFormik.errors.relation)}
                helperText={beneficiaryFormik.errors.relation} />
            <BasicAutocomplete
                handleChange={(value) => beneficiaryFormik.setFieldValue("status", value)}
                searchFct={searchBeneficiaryStatuses}
                label={'Status'}
                property={'nameStatus'}
                currentValue={beneficiaryFormik.values.status}
                error={Boolean(beneficiaryFormik.errors.status)}
                helperText={beneficiaryFormik.errors.status} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={!beneficiaryFormik.dirty || Object.keys(beneficiaryFormik.errors).length !== 0}  onClick={handleConfirm} color="primary">
              { action !== 'edit' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddBeneficiaryDialog)