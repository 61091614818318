import React, {useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AuthIcon from "@material-ui/icons/Fingerprint";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { auth } from "./auth/firebase";
import firebase from "firebase";

const styles = () => ({
  contentWrapper: {
    width: 500,
    margin: 'auto',
    marginTop: '10rem',
    marginLeft: 'auto',
    textAlign: 'center',
  },
  button: {
    margin: 'auto',
    marginTop: '2rem',
  },
  input: {
    marginTop: '2rem',
  },
  paper: {
    padding: '2rem',
  },
  loginError: {
    color: 'red',
  }
});

function Login(props) {
  const { classes } = props;

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [recaptcha, setRecaptcha] = useState(undefined);
  const [resolver, setResolver] = useState(undefined);
  const [mfaRequired, setMfaRequired] = useState(false);

  useEffect(() => {
    const verifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
      size: "invisible",
    });
    if (!recaptcha) {
      verifier.verify().then(() => setRecaptcha(verifier));
    }
  }, [recaptcha]);

  const signInWithEmailAndPasswordHandler =
      (event, userValue, passwordValue) => {
        auth.signInWithEmailAndPassword(userValue, passwordValue)
        .then(() => {
          setLoginError(false);
        })
        .catch((e) => {
          if (e.code === 'auth/multi-factor-auth-required') {
            setResolver(e.resolver);
            let phoneInfoOptions = {
              multiFactorHint: e.resolver.hints[0],
              session: e.resolver.session
            };
            let phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
            if (!recaptcha) {
              const verifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
                size: "invisible",
              });
              if (!recaptcha) {
                verifier.verify().then(() => setRecaptcha(verifier));
              }
            }
            phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptcha)
              .catch((e) => {
                console.log(e);
              })
              .then((response) => {
                setVerificationId(response);
                setMfaRequired(true);
              });

          } else {
            setLoginError(true);
          }
        });
        event.preventDefault();
      };

  const onUserChange = (e) => {
    setUser(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onCodeChange = (e) => {
    setCode(e.target.value);
  };

  const onLogin = (e) => {
    if (!mfaRequired) {
      signInWithEmailAndPasswordHandler(e, user, password);
    } else {
      let cred = firebase.auth.PhoneAuthProvider.credential(
          verificationId, code);
      let multiFactorAssertion =
          firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      return resolver.resolveSignIn(multiFactorAssertion);
    }
  };

  return (
      <>
        <div className={classes.contentWrapper}>
          <Paper elevation={3} className={classes.paper}>
            <AuthIcon style={{ fontSize: 80 }} />
            <Typography color="inherit" variant="h5" component="h1">
              Please log-in first!
            </Typography>
            <form className={classes.root}>
              <div>
                <TextField
                    className={classes.input}
                    fullWidth
                    id="user"
                    value={user}
                    label="User"
                    variant="outlined"
                    onChange={onUserChange}/>
              </div>
              <div>
                <TextField
                    className={classes.input}
                    fullWidth
                    id="password"
                    value={password}
                    label="Password"
                    variant="outlined"
                    type="password"
                    onChange={onPasswordChange} />
              </div>
              <div>
                <Typography className={classes.loginError} variant="caption" component="div" hidden={!loginError}>
                  Wrong credentials!
                </Typography>
                <Typography variant="caption" component="div" hidden={!mfaRequired}>
                  Please enter the verification code you receive via SMS!
                </Typography>
              </div>
              <div hidden={!mfaRequired}>
                <TextField
                    className={classes.input}
                    fullWidth
                    id="code"
                    value={code}
                    label="Verification code"
                    variant="outlined"
                    type="text"
                    onChange={onCodeChange}/>
              </div>
              <div>
                <Button
                    className={classes.input}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={onLogin}>
                  Login
                </Button>
              </div>
            </form>
          </Paper>
        </div>
        <div id="recaptcha"> </div>
      </>
  );
}

export default withStyles(styles)(Login);