import React, {useRef, useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import {
  getSlfReport,
  invalidateSlfReport,
  reactivateSlfReport, validateSlfReport
} from "../../utils/api";
import {
  CircularProgress,
  Table, TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {formatTime} from "../../utils/timeHelper";
import Button from "@material-ui/core/Button";

const styles = () => ({
  heading: {
    marginTop: '1rem',
    marginBottom: '1rem',
  }
});

function InspectReportDialog(props) {
  const { classes, type, reportId, refresh } = props;
  const [open, setOpen] = React.useState(false);
  const [report, setReport] = React.useState(undefined);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    fetchData(false, reportId);
  };

  const handleClose = () => {
    refresh(true, 0);
    setOpen(false);
  };

  const cancelRequest = useRef();

  const validateReport =
      async (loading, rId) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          await validateSlfReport({ reportId: rId})
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }

        handleClose();
      };

  const reactivateReport =
      async (loading, rId) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          await reactivateSlfReport({ reportId: rId});
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }

        handleClose();
      };

  const invalidateReport =
      async (loading, rId) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          await invalidateSlfReport({ reportId: rId});
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }

        handleClose();
      };

  const fetchData =
      async (loading, rId) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getSlfReport(rId);
          const reportData = result.data;

          if (!cancelRequest.current) {
            if (reportData && Object.keys(reportData).length > 0) {
              setReport(reportData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  return (
      <div>
        <Tooltip title="Inspect">
          <IconButton aria-label="edit"
                      onClick={handleClickOpen}>
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Dialog fullScreen className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Senior Lead Farmer Report</DialogTitle>
          <DialogContent>
            {isLoading || !report &&
              <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
                <CircularProgress color="primary" />
              </div>
            }
            { report &&
              <>
                <Typography variant="subtitle1" gutterBottom>Report <strong>{report.month}/{report.year}</strong> submitted by <strong>{report.seniorLeadFarmer ? report.seniorLeadFarmer.person.personalData.firstName + ' ' + report.seniorLeadFarmer.person.personalData.lastName : ''}</strong> on <strong>{formatTime(report.creationDate, 'dd.LL.yyyy')}</strong></Typography>
                { report.leadFarmerSupervisions && report.leadFarmerSupervisions.length > 0 &&
                    <>
                      <Typography className={classes.heading} variant="h6" gutterBottom>Lead Farmer Supervisions ({report.leadFarmerSupervisions.length})</Typography>
                      <TableContainer>
                        <Table style={{width: 500}} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#eee'}}>
                            <TableRow>
                              <TableCell>Lead Farmer</TableCell>
                              <TableCell>Date of visit</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {report.leadFarmerSupervisions.map((supervision) => (
                                <TableRow key={supervision.id}>
                                  <TableCell>{supervision.leadFarmer ? `${supervision.leadFarmer.person.personalData.firstName} ${supervision.leadFarmer.person.personalData.lastName}` : ''}</TableCell>
                                  <TableCell>{supervision.dateVisited}</TableCell>
                                </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                }
                { report.leadFarmerTrainings && report.leadFarmerTrainings.length > 0 &&
                  <>
                    <Typography className={classes.heading} variant="h6" gutterBottom>Lead Farmer Trainings ({report.leadFarmerTrainings.length})</Typography>
                    <TableContainer>
                      <Table style={{width: 750}} aria-label="simple table">
                        <TableHead style={{backgroundColor: '#eee'}}>
                          <TableRow>
                            <TableCell>Training block</TableCell>
                            <TableCell>Date of training</TableCell>
                            <TableCell>Participants</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {report.leadFarmerTrainings.map((training) => (
                              <TableRow key={training.id}>
                                <TableCell>{training.trainingBlock ? training.trainingBlock.name : ''}</TableCell>
                                <TableCell>{training.dateConducted}</TableCell>
                                <TableCell>
                                  <ul>
                                    {training.participants && training.participants.map((p) => (
                                        <li>{`${p.personalData.firstName} ${p.personalData.lastName}`}</li>
                                    ))}
                                  </ul>
                                </TableCell>
                              </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                }
                { report.leadFarmerDropouts && report.leadFarmerDropouts.length > 0 &&
                <>
                  <Typography className={classes.heading} variant="h6" gutterBottom>Lead Farmer Dropouts ({report.leadFarmerDropouts.length})</Typography>
                  <TableContainer>
                    <Table style={{width: 500}} aria-label="simple table">
                      <TableHead style={{backgroundColor: '#eee'}}>
                        <TableRow>
                          <TableCell>Lead Farmer</TableCell>
                          <TableCell>Reason</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {report.leadFarmerDropouts.map((dropout) => (
                            <TableRow key={dropout.id}>
                              <TableCell>{dropout.leadFarmer ? `${dropout.leadFarmer.personalData.firstName} ${dropout.leadFarmer.personalData.lastName}` : ''}</TableCell>
                              <TableCell>{dropout.reason.nameReason}</TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
                }
                { report.leadFarmerChallenges && report.leadFarmerChallenges.length > 0 &&
                  <>
                    <Typography className={classes.heading} variant="h6" gutterBottom>Lead Farmer Challenges ({report.leadFarmerChallenges.length})</Typography>
                    <TableContainer>
                      <Table style={{width: 500}} aria-label="simple table">
                        <TableHead style={{backgroundColor: '#eee'}}>
                          <TableRow>
                            <TableCell>Lead Farmer</TableCell>
                            <TableCell>Challenge</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {report.leadFarmerChallenges.map((challenge) => (
                              <TableRow key={challenge.id}>
                                <TableCell>{challenge.leadFarmer ? `${challenge.leadFarmer.personalData.firstName} ${challenge.leadFarmer.personalData.lastName}` : ''}</TableCell>
                                <TableCell>{challenge.challenge.name}</TableCell>
                              </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                }
                { report.beneficiaryTrainings && report.beneficiaryTrainings.length > 0 &&
                  <>
                    <Typography className={classes.heading} variant="h6" gutterBottom>Beneficiary Trainings ({report.beneficiaryTrainings.length})</Typography>
                    <TableContainer>
                      <Table style={{width: 1000}} aria-label="simple table">
                        <TableHead style={{backgroundColor: '#eee'}}>
                          <TableRow>
                            <TableCell>Training lesson</TableCell>
                            <TableCell>Trainer</TableCell>
                            <TableCell>Date of training</TableCell>
                            <TableCell>Participants</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {report.beneficiaryTrainings.map((training) => (
                              <TableRow key={training.id}>
                                <TableCell>{training.trainingLesson ? training.trainingLesson.name : ''}</TableCell>
                                <TableCell>{training.trainer ? `${training.trainer.person.personalData.firstName} ${training.trainer.person.personalData.lastName}` : ''}</TableCell>
                                <TableCell>{training.dateConducted}</TableCell>
                                <TableCell>
                                  <ul>
                                    {training.participants && training.participants.map((p) => (
                                        <li>{`${p.personalData.firstName} ${p.personalData.lastName}`}</li>
                                    ))}
                                  </ul>
                                </TableCell>
                              </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                }
                { report.beneficiaryDropouts && report.beneficiaryDropouts.length > 0 &&
                <>
                  <Typography className={classes.heading} variant="h6" gutterBottom>Beneficiary Dropouts ({report.beneficiaryDropouts.length})</Typography>
                  <TableContainer>
                    <Table style={{width: 500}} aria-label="simple table">
                      <TableHead style={{backgroundColor: '#eee'}}>
                        <TableRow>
                          <TableCell>Beneficiary</TableCell>
                          <TableCell>Reason</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {report.beneficiaryDropouts.map((dropout) => (
                            <TableRow key={dropout.id}>
                              <TableCell>{dropout.beneficiary ? `${dropout.beneficiary.personalData.firstName} ${dropout.beneficiary.personalData.lastName}` : ''}</TableCell>
                              <TableCell>{dropout.reason.nameReason}</TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
                }
                { report.specialReports && report.specialReports.length > 0 &&
                <>
                  <Typography className={classes.heading} variant="h6" gutterBottom>Special Bi-Annual Report ({report.specialReports.length})</Typography>
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead style={{backgroundColor: '#eee'}}>
                        <TableRow>
                          <TableCell>Household</TableCell>
                          <TableCell>Lead Farmer</TableCell>
                          <TableCell>Date of visit</TableCell>
                          <TableCell>Crops</TableCell>
                          <TableCell>Goods</TableCell>
                          <TableCell>Key outputs</TableCell>
                          <TableCell>Techniques</TableCell>
                          <TableCell>IEC materials</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {report.specialReports.map((report) => (
                            <TableRow key={report.id}>
                              <TableCell>{report.household ? report.household.fbNumber : ''}</TableCell>
                              <TableCell>{report.leadFarmer ? `${report.leadFarmer.person.personalData.firstName} ${report.leadFarmer.person.personalData.lastName}` : ''}</TableCell>
                              <TableCell>{report.dateVisited}</TableCell>
                              <TableCell>
                                <ul>
                                  {report.crops && report.crops.map((c) => (
                                      <li>{`${c.name}`}</li>
                                  ))}
                                </ul>
                              </TableCell>
                              <TableCell>
                                <ul>
                                  {report.goods && report.goods.map((g) => (
                                      <li>{`${g.name}`}</li>
                                  ))}
                                </ul>
                              </TableCell>
                              <TableCell>
                                <ul>
                                  {report.keyElements && report.keyElements.map((ke) => (
                                      <li>{`${ke.name}`}</li>
                                  ))}
                                </ul>
                              </TableCell>
                              <TableCell>
                                <ul>
                                  {report.techniques && report.techniques.map((t) => (
                                      <li>{`${t.name}`}</li>
                                  ))}
                                </ul>
                              </TableCell>
                              <TableCell>
                                <ul>
                                  {report.iecMaterials && report.iecMaterials.map((im) => (
                                      <li>{`${im.name}`}</li>
                                  ))}
                                </ul>
                              </TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
                }
              </>
            }
          </DialogContent>
          <DialogActions>
            { type && type === 'delivered' &&
              <>
                <Button onClick={() => invalidateReport(true, reportId)}
                        color="primary">
                  Invalidate
                </Button>
                <Button onClick={() => validateReport(true, reportId)} color="primary">
                  Validate
                </Button>
              </>
            }
            { type && type === 'invalidated' &&
            <>
              <Button onClick={() => reactivateReport(true, reportId)}
                      color="primary">
                Reactivate
              </Button>
            </>
            }
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(InspectReportDialog)