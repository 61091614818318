import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  searchGizIntermediaries,
  searchHouseholds,
  searchTrainingLessons, searchVillages
} from "../../utils/api";
import {UserContext} from "../auth/UserProvider";
import {Autocomplete} from "@material-ui/lab";
import * as Yup from "yup";
import {useFormik} from "formik";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddTrainingSessionDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [open, setOpen] = React.useState(false);
  const [lessonOptions, setLessonOptions] = React.useState([]);
  const [householdOptions, setHouseholdOptions] = React.useState([]);

  const authState = useContext(UserContext);

  const SessionSchema = Yup.object().shape({
    dateConducted: Yup.date().min(new Date("2015-01-01"), 'Date cannot be before 2015!').max(new Date(), 'Date cannot be in the future!').required('Required'),
    lessons: Yup.array().min(1, 'Select at least one').required('Required'),
    households: Yup.array().min(1, 'Select at least one').required('Required'),
    intermediary: Yup.object().nullable().required('Required'),
    village: Yup.object().nullable().required('Required'),
  });

  const handleClickOpen = async () => {
    if (entity) {
      await sessionFormik.setFieldValue('dateConducted', entity.dateConducted);
      await sessionFormik.setFieldValue('lessons', [ entity.trainingLesson ]);
      await sessionFormik.setFieldValue('households', [ entity.household ]);
      await sessionFormik.setFieldValue('intermediary', entity.intermediary);
      await sessionFormik.setFieldValue('village', entity.village);
      await sessionFormik.setFieldValue('additionalInfo', entity.additionalInfo);
      await setFormFieldsTouched();
    }
    setOpen(true);
  };

  const setFormFieldsTouched = async () => {
    await sessionFormik.setFieldTouched('dateConducted', true);
    await sessionFormik.setFieldTouched('lessons', true);
    await sessionFormik.setFieldTouched('households', true);
    await sessionFormik.setFieldTouched('intermediary', true);
    await sessionFormik.setFieldTouched('village', true);
    await sessionFormik.setFieldTouched('additionalInfo', true);
  }

  const sessionFormik = useFormik({
    initialValues: {
      dateConducted: '',
      lessons: [],
      households: [],
      intermediary: null,
      village: null,
      additionalInfo: '',
    },
    validationSchema: SessionSchema,
  });

  React.useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchTrainingLessons('');

      if (active) {
        if (response.data) {
          setLessonOptions(response.data);
        } else {
          setLessonOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  React.useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchHouseholds('');

      if (active) {
        if (response.data) {
          setHouseholdOptions(response.data);
        } else {
          setHouseholdOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  const handleClose = async () => {
    setOpen(false);
    await sessionFormik.resetForm();
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(sessionFormik.values) :
        confirmationHandler(entity.id, sessionFormik.values);
    handleClose();
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        {action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add Session
          </Fab>
        }
        {action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
            <Tooltip title="Edit">
              <IconButton aria-label="edit"
                          onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} Training Session</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the session
            </DialogContentText>
            { action === 'create' &&
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="lesson-multi-select"
                    options={lessonOptions}
                    getOptionLabel={(option) =>  `${option.name}`}
                    value={sessionFormik.values.lessons}
                    variant={'outlined'}
                    name="lessons"
                    onChange={(event, value) => sessionFormik.setFieldValue("lessons", value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Lessons..."
                            error={Boolean(sessionFormik.errors.lessons)}
                            helperText={sessionFormik.errors.lessons}
                        />
                    )}
                />
            }
            { action === 'edit' &&
                <Autocomplete
                    id="lesson-single-select"
                    options={lessonOptions}
                    getOptionLabel={(option) =>  `${option.name}`}
                    value={sessionFormik.values.lessons[0]}
                    variant={'outlined'}
                    name="lessons"
                    onChange={(event, value) => sessionFormik.setFieldValue("lessons", [ value ])}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Lesson"
                            error={Boolean(sessionFormik.errors.lessons)}
                            helperText={sessionFormik.errors.lessons}
                        />
                    )}
                />
            }
            <div>
              <TextField
                  margin="normal"
                  id="dateConducted"
                  label="Date of lesson"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="dateConducted"
                  variant={'outlined'}
                  value={sessionFormik.values.dateConducted}
                  onChange={sessionFormik.handleChange}
                  error={Boolean(sessionFormik.errors.dateConducted)}
                  helperText={sessionFormik.errors.dateConducted}
              />
            </div>
            <BasicAutocomplete
                handleChange={(value) => {sessionFormik.setFieldValue("intermediary", value)}}
                searchFct={searchGizIntermediaries} 
                label={'Trainer'} 
                property={'intermediary'}
                currentValue={sessionFormik.values.intermediary}
                error={Boolean(sessionFormik.errors.intermediary)}
                helperText={sessionFormik.errors.intermediary} />
            <br/>
            { action === 'create' &&
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="household-multi-select"
                    options={householdOptions}
                    getOptionLabel={(option) =>  `${option.fbNumber}`}
                    value={sessionFormik.values.households}
                    variant={'outlined'}
                    name="households"
                    onChange={(event, value) => sessionFormik.setFieldValue("households", value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Households..."
                            error={Boolean(sessionFormik.errors.households)}
                            helperText={sessionFormik.errors.households}
                        />
                    )}
                    onInputChange={async (event) => {
                      if (event) {
                        await searchHouseholds(event.target.value)
                            .then((response) => setHouseholdOptions(response.data));
                      }}
                    }
                />
            }
            { action === 'edit' &&
                <Autocomplete
                    id="household-single-select"
                    options={householdOptions}
                    getOptionLabel={(option) =>  `${option.fbNumber}`}
                    value={sessionFormik.values.households[0]}
                    variant={'outlined'}
                    name="households"
                    onChange={(event, value) => sessionFormik.setFieldValue("households", [ value ])}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Household"
                            error={Boolean(sessionFormik.errors.households)}
                            helperText={sessionFormik.errors.households}
                        />
                    )}
                    onInputChange={async (event) => {
                      if (event) {
                        await searchHouseholds(event.target.value)
                            .then((response) => setHouseholdOptions(response.data));
                      }}
                    }
                />
            }
            <BasicAutocomplete
                handleChange={(value) => {sessionFormik.setFieldValue("village", value)}}
                searchFct={searchVillages} 
                label={'Village'} 
                property={'villageName'}
                currentValue={sessionFormik.values.village}
                error={Boolean(sessionFormik.errors.village)}
                helperText={sessionFormik.errors.village} />
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    margin="normal"
                    id="training-lesson-additional-info"
                    label="Additional info"
                    type="text"
                    value={sessionFormik.values.additionalInfo}
                    onChange={sessionFormik.handleChange}
                    fullWidth
                    variant={'outlined'}
                    name="additionalInfo"
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
                disabled={!sessionFormik.dirty || Object.keys(sessionFormik.errors).length !== 0}
                onClick={handleConfirm}
                color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddTrainingSessionDialog)