import React, {useCallback, useEffect, useRef, useState} from 'react';
import { CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createMultipleTrainingSessions, deleteTrainingSession,
  getTrainingSessions, updateTrainingSession
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import AddTrainingSessionDialog from "../dialogs/AddTrainingSessionDialog";
import {formatTime} from "../../utils/timeHelper";
import CreationSnackbar from "../dialogs/CreationSnackbar";
import debounce from "lodash.debounce";
import TextField from "@material-ui/core/TextField";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function Trainings(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedTrainingSessions, setPaginatedTrainingSessions] = useState('');
  const [successBarText, setSuccessBarText] = useState('');
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sessionBuffer, setSessionBuffer] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const addConfirmationHandler =
      async (values) => {
        let body = [];

        values.lessons.map(lesson => {
          values.households.map(household => {
            body.push({
              'trainingLesson': lesson,
              'dateConducted': values.dateConducted,
              'intermediary': values.intermediary,
              'household': household,
              'village': values.village,
              'additionalInfo': values.additionalInfo,
            })
          });
        });

        await createMultipleTrainingSessions(body).then((entity) => {
          setSuccessBarText(entity.data ? `${values.lessons.length} session(s) ` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const updateConfirmationHandler =
      async (entityId, values) => {
        console.log(values);
        await updateDistrictDataAndDispatch(entityId, {
          'trainingLesson': values.lessons[0],
          'dateConducted': values.dateConducted,
          'intermediary': values.intermediary,
          'household': values.households[0],
          'village': values.village,
          'additionalInfo': values.additionalInfo,
        });
        await fetchData();
      };

  const updateDistrictDataAndDispatch = async (sessionId, fields) => {
    try {
      let buffer = {
        ...sessionBuffer
      };

      setSessionBuffer(buffer);

      const { data: data } = await updateTrainingSession(
          sessionId,
          fields
      );

      if (!cancelRequest.current) {
        setSessionBuffer(data);
      }
    } catch (error) {
      setSessionBuffer(sessionBuffer);
    }
  };

  const removeHandler = async (sessionId) => {
    await deleteTrainingSession(sessionId);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getTrainingSessions(page, size, searchTerm);
          const trainingSessionData = result.data;

          if (!cancelRequest.current) {
            if (trainingSessionData && Object.keys(trainingSessionData).length > 0) {
              setPaginatedTrainingSessions(trainingSessionData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const handleSuccessBarState = (open) => {
    setShowSuccessBar(open);
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedTrainingSessions) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Lesson name, trainer first, last name"
                id="training-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedTrainingSessions.content}
                columns={[
                  { field: 'trainingLesson', headerName: 'Lesson', flex: 1,
                    valueFormatter: (params) =>
                        (params.value.name), },
                  { field: 'dateConducted', headerName: 'Date conducted', flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateConducted))
                  },
                  {
                    field: 'intermediary', headerName: 'Trainer', flex: 1,
                    renderCell: (params) =>
                        (params.row.intermediary ?
                                params.row.intermediary.person.personalData.firstName
                                +
                                ' '
                                + params.row.intermediary.person.personalData.lastName
                                : ' '
                        )
                  },
                  {
                    field: "household",
                    headerName: "Household FB number",
                    flex: 1,
                    renderCell: (params) =>
                        (params.value ? params.value.fbNumber : '')
                  },
                  {
                    field: "village",
                    headerName: "Village",
                    flex: 1,
                    renderCell: (params) =>
                        (params.value ? params.value.villageName : '')
                  },
                  { field: 'additionalInfo', headerName: 'Additional Info', flex: 1 },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                        <AddTrainingSessionDialog
                            confirmationHandler={updateConfirmationHandler}
                            action={'edit'}
                            entity={params.row}/>
                        <DataGridActionBar deleteHandler={removeHandler} data={params.row}>
                        </DataGridActionBar>
                      </>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedTrainingSessions.size}
                rowCount={paginatedTrainingSessions.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <AddTrainingSessionDialog
              confirmationHandler={addConfirmationHandler} />
          <CreationSnackbar
              successBarOpen={showSuccessBar}
              successBarHandler={handleSuccessBarState}
              successText={successBarText} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Trainings);