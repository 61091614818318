import React, {useContext, useEffect} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  getBeneficiariesByNv, searchLessonTypes, searchNutritionVolunteers
} from "../../utils/api";
import {UserContext} from "../auth/UserProvider";
import AutoComplete from "@material-ui/lab/Autocomplete";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Autocomplete} from "@material-ui/lab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Switch} from "@material-ui/core";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '12rem',
  },
});

function AddLessonByNvDialog(props) {
  const { classes, confirmationHandler} = props;
  const [nv, setNv] = React.useState(undefined);
  const [beneficiaries, setBeneficiaries] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [acOpen, setAcOpen] = React.useState(false);
  const [lessonOptions, setLessonOptions] = React.useState([]);
  const [beneficiaryOptions, setBeneficiaryOptions] = React.useState([]);
  const [addAllBeneficiaries, setAddAllBeneficiaries] = React.useState(false);

  const authState = useContext(UserContext);

  const LessonSchema = Yup.object().shape({
    dateConducted: Yup.date().min(new Date("2015-01-01"), 'Date cannot be before 2015!').max(new Date(), 'Date cannot be in the future!').required('Required'),
    lessonTypes: Yup.array().min(1, 'Select at least one').required('Required'),
    nv: Yup.object().nullable().required('Required'),
  });

  const lessonFormik = useFormik({
    initialValues: {
      dateConducted: '',
      lessonTypes: [],
      nv: null,
    },
    validationSchema: LessonSchema,
  });

  useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchLessonTypes('');

      if (active) {
        if (response.data) {
          setLessonOptions(response.data);
        } else {
          setLessonOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    onSelectNv();
  }, [nv]);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    await lessonFormik.resetForm();
    setNv(undefined);
    setBeneficiaries([]);
  };

  const handleConfirm = () => {
    confirmationHandler(lessonFormik.values, addAllBeneficiaries ? beneficiaryOptions : beneficiaries);
    handleClose();
  };

  const onSelectNv = async () => {
    if (nv && nv.id)
    await getBeneficiariesByNv(nv.id)
      .then((response) => setBeneficiaryOptions(response.data));
  }

  const handleAllBeneficiariesChange = (event) => {
    let checked = event.target.checked;

    setAddAllBeneficiaries(checked);
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
            style={{position: 'fixed', marginBottom: '1rem'}}
        >
          <AddIcon/>
          Add Lesson by NV
        </Fab>
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add Lesson</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the lesson
            </DialogContentText>
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="dateConducted"
                  label="Date of lesson"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="dateConducted"
                  variant={'outlined'}
                  value={lessonFormik.values.dateConducted}
                  onChange={lessonFormik.handleChange}
                  error={Boolean(lessonFormik.errors.dateConducted)}
                  helperText={lessonFormik.errors.dateConducted}
              />
            </div>
            <Autocomplete
                multiple
                disableCloseOnSelect
                id="lesson-multi-select"
                options={lessonOptions}
                getOptionLabel={(option) =>  `${option.nameLessonType}`}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Lessons..."
                        error={Boolean(lessonFormik.errors.lessonTypes)}
                        helperText={lessonFormik.errors.lessonTypes}
                    />
                )}
                name="lessonTypes"
                variant={'outlined'}
                value={lessonFormik.values.lessonTypes}
                onChange={(event, value) => lessonFormik.setFieldValue("lessonTypes", value)}
            />
            <BasicAutocomplete
                handleChange={(value) => {
                  lessonFormik.setFieldValue("nv", value);
                  setNv(value);
                  setBeneficiaries([]);
                }}
                searchFct={searchNutritionVolunteers}
                label={'Nutrition Volunteer'}
                property={'nutritionalVolunteer'}
                currentValue={lessonFormik.values.nv}
                error={Boolean(lessonFormik.errors.nv)}
                helperText={lessonFormik.errors.nv} />
            { nv &&
              <>
                <AutoComplete
                    style={{ marginTop: 20 }}
                    multiple
                    disableCloseOnSelect
                    disabled={addAllBeneficiaries}
                    id="beneficiaries-multi-select"
                    options={beneficiaryOptions}
                    getOptionLabel={(option) => {
                      return `${option.person.personalData.firstName} ${option.person.personalData.lastName} (${option.person.household.fbNumber})`;
                    }}
                    getOptionSelected={(option, value) => option && value && option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Beneficiaries..."
                        />
                    )}
                    name="beneficiaries"
                    variant={'outlined'}
                    value={beneficiaries}
                    fullWidth
                    open={acOpen}
                    onOpen={() => {
                      setAcOpen(true);
                    }}
                    onClose={() => {
                      setAcOpen(false);
                    }}
                    onChange={(event, value) => setBeneficiaries(value)}
                />
                <FormControlLabel control={
                  <Switch
                      color="primary"
                      checked={addAllBeneficiaries}
                      onChange={handleAllBeneficiariesChange}/>
                } label="Add all beneficiaries" />
              </>

            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={!lessonFormik.dirty || Object.keys(lessonFormik.errors).length !== 0} onClick={handleConfirm} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddLessonByNvDialog)