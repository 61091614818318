import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  searchBeneficiaries, searchLeavingReasons
} from "../../utils/api";
import TextField from "@material-ui/core/TextField";
import {UserContext} from "../auth/UserProvider";
import EditIcon from "@material-ui/icons/Edit";
import {IconButton, Tooltip} from "@material-ui/core";
import * as Yup from "yup";
import {useFormik} from "formik";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddBeneficiaryDropoutDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const DropoutSchema = Yup.object().shape({
    person: Yup.object().nullable().required('Required'),
    reason: Yup.object().nullable().required('Required'),
    dateLeft: Yup.date().min(new Date("2015-01-01"), 'Date cannot be before 2015!').max(new Date(), 'Date cannot be in the future!').required('Required'),
  });

  const dropoutFormik = useFormik({
    initialValues: {
      person: null,
      reason: null,
      dateLeft: '',
    },
    validationSchema: DropoutSchema,
  });

  const setFormFieldsTouched = async () => {
    await dropoutFormik.setFieldTouched('reason', true);
    await dropoutFormik.setFieldTouched('person', true);
    await dropoutFormik.setFieldTouched('dateLeft', true);
  }

  const handleClickOpen = async () => {
    if (entity) {
      await dropoutFormik.setFieldValue('reason', entity.reasonLeaving);
      await dropoutFormik.setFieldValue('person', entity.person);
      await dropoutFormik.setFieldValue('dateLeft', entity.dateLeft);
      await setFormFieldsTouched();
    }
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    await dropoutFormik.resetForm();
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(dropoutFormik.values) :
        confirmationHandler(entity.id, dropoutFormik.values);
    handleClose();
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        {action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add dropout
          </Fab>
        }
        {action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} beneficiary dropout</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of dropout
            </DialogContentText>
            <BasicAutocomplete 
                handleChange={(value) => dropoutFormik.setFieldValue("person", value)}
                searchFct={searchBeneficiaries} 
                label={'Beneficiary'} 
                property={'beneficiaryHousehold'}
                currentValue={dropoutFormik.values.person}
                error={Boolean(dropoutFormik.errors.person)}
                helperText={dropoutFormik.errors.person} />
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="dateLeft"
                  name="dateLeft"
                  label="Dropout date"
                  type="date"
                  variant="outlined"
                  value={dropoutFormik.values.dateLeft}
                  onChange={dropoutFormik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  currentValue={dropoutFormik.values.dateLeft}
                  error={Boolean(dropoutFormik.errors.dateLeft)}
                  helperText={dropoutFormik.errors.dateLeft}
              />
            </div>
            <BasicAutocomplete
                handleChange={(value) => dropoutFormik.setFieldValue("reason", value)}
                searchFct={searchLeavingReasons}
                label={'Reason'}
                property={'nameReason'}
                currentValue={dropoutFormik.values.reason}
                error={Boolean(dropoutFormik.errors.reason)}
                helperText={dropoutFormik.errors.reason}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={!dropoutFormik.dirty || Object.keys(dropoutFormik.errors).length !== 0} onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddBeneficiaryDropoutDialog)