import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import {
  searchGizIntermediaries,
  searchHouseholds
} from "../../utils/api";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {UserContext} from "../auth/UserProvider";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddPspSupervisoryVisitDialog(props) {
  const { classes, confirmationHandler } = props;
  const [remark, setRemark] = React.useState('');
  const [dateVisited, setDateVisited] = React.useState(undefined);
  const [intermediary, setIntermediary] = React.useState(undefined);
  const [household, setHousehold] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRemark('');
    setDateVisited(undefined);
    setIntermediary(undefined);
    setHousehold(undefined);
  };

  const handleConfirm = () => {
    confirmationHandler(intermediary, household, dateVisited, remark);
    handleClose();
  };

  const handleRemarkChange = (event) => {
    setRemark(event.target.value);
  };

  const onChangeIntermediary = value => {
    setIntermediary(value);
  };

  const onChangeHousehold = value => {
    setHousehold(value);
  };

  const handleVisitDateChange = (event) => {
    setDateVisited(event.target.value);
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
            style={{position: 'fixed', marginBottom: '1rem'}}
        >
          <AddIcon/>
          Add supervisory visit
        </Fab>
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add supervisory visit</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the supervisory visit
            </DialogContentText>
            <BasicAutocomplete handleChange={onChangeIntermediary} searchFct={searchGizIntermediaries} label={'Intermediary'} property={'intermediary'} />
            <BasicAutocomplete handleChange={onChangeHousehold} searchFct={searchHouseholds} label={'Household'} property={'fbNumber'} />
            <div>
              <TextField
                  margin="normal"
                  id="dateVisited"
                  label="Date of visit"
                  type="date"
                  variant={'outlined'}
                  value={dateVisited}
                  onChange={handleVisitDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    margin="normal"
                    id="remark"
                    label="Remark"
                    variant={'outlined'}
                    rows={4}
                    multiline
                    value={remark}
                    onChange={handleRemarkChange}
                    fullWidth
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddPspSupervisoryVisitDialog)