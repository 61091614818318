import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  searchGizIntermediaries,
  searchIntermediaryTypes, searchWards
} from "../../utils/api";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {UserContext} from "../auth/UserProvider";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '1rem',
    right: '1rem',
  },
});

function AddGizIntermediaryDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [gender, setGender] = React.useState('FEMALE');
  const [dateOfBirth, setDateOfBirth] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [intermediaryType, setIntermediaryType] = React.useState('');
  const [observer, setObserver] = React.useState(undefined);
  const [ward, setWard] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const handleClickOpen = () => {
    setFirstName(entity && entity.person.personalData.firstName ? entity.person.personalData.firstName : '');
    setLastName(entity && entity.person.personalData.lastName ? entity.person.personalData.lastName : '');
    setGender(entity && entity.person.personalData.gender ? entity.person.personalData.gender : 'FEMALE');
    setDateOfBirth(entity && entity.person.personalData.dateOfBirth ?
        entity.person.personalData.dateOfBirth : '');
    setPhoneNumber(entity && entity.person.personalData.phoneNumber ?
        entity.person.personalData.phoneNumber : '');
    setIntermediaryType(entity && entity.intermediaryType ?
        entity.intermediaryType : '');
    setObserver(entity && entity.observingIntermediary ?
        entity.observingIntermediary : null);
    setWard(entity && entity.ward ?
        entity.ward : null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFirstName('');
    setLastName('');
    setGender('FEMALE');
    setDateOfBirth(undefined);
    setPhoneNumber('');
    setIntermediaryType('');
    setObserver(null);
    setWard(null);
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(firstName, lastName, gender, dateOfBirth,
            phoneNumber, intermediaryType, ward, observer) :
        confirmationHandler(entity.id, firstName, lastName, gender,
            dateOfBirth, phoneNumber, intermediaryType, ward, observer);
    handleClose();
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleDateOfBirthChange = (event) => {
    setDateOfBirth(event.target.value);
  };

  const onChangeIntermediaryType = value => {
    setIntermediaryType(value);
  };

  const onChangeObserver = value => {
    setObserver(value);
  };

  const onChangeWard = value => {
    setWard(value);
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        { action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add intermediary
          </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} GIZ intermediary</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of intermediary
            </DialogContentText>
            <BasicAutocomplete handleChange={onChangeIntermediaryType} searchFct={searchIntermediaryTypes} label={'Type'} property={'nameIntermediaryType'} currentValue={intermediaryType} />
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="first-name"
                    label="First name"
                    type="text"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="last-name"
                    label="Last name"
                    type="text"
                    value={lastName}
                    onChange={handleLastNameChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={gender} onChange={handleGenderChange}>
                  <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                  <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="birthDate"
                  label="Date of birth"
                  type="date"
                  value={dateOfBirth}
                  onChange={handleDateOfBirthChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                    autoFocus
                    margin="normal"
                    id="phone-number"
                    label="Phone number"
                    type="text"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    fullWidth
                />
              </FormControl>
            </div>
            <BasicAutocomplete handleChange={onChangeWard} searchFct={searchWards} label={'Ward'} property={'wardName'} currentValue={ward}/>
            <BasicAutocomplete handleChange={onChangeObserver} searchFct={searchGizIntermediaries} label={'Observing intermediary'} property={'intermediary'} currentValue={observer} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddGizIntermediaryDialog)