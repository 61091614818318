import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createMultipleFbsModuleSessionParticipant, deleteFbsSessionParticipants,
  getFbsModuleSessionParticipants,
  getFbsModuleSessionParticipantsDump, updateFbsModuleSessionParticipant,
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import AddFbsSessionParticipantDialog
  from "../dialogs/AddFbsSessionParticipantDialog";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/GetApp";
import {UserContext} from "../auth/UserProvider";
import FileDownload from "js-file-download";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";
import debounce from "lodash.debounce";
import {formatTime} from "../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '15rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function Participants(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedParticipants, setPaginatedParticipants] =
      useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [participantBuffer, setParticipantBuffer] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const authState = useContext(UserContext);

  const cancelRequest = useRef();

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getFbsModuleSessionParticipantsDump().then((response) => {
            FileDownload(response.data, 'fbs_trainings.csv');
            setIsLoadingDump(false);
          });
        } catch (error) {
          console.log(error);
        }
      };

  const addConfirmationHandler =
      async (modules, persons) => {
        let body = [];

        modules.map(session => {
          persons.map(person => {
            body.push({
              'module': session,
              'person': person,
            });
          })});

        await createMultipleFbsModuleSessionParticipant(body);
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, modules, persons) => {
        await updateDistrictDataAndDispatch(entityId, {
          'module': modules[0],
          'person': persons[0],
        });
        await fetchData();
      };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (participantId) => {
    await deleteFbsSessionParticipants(participantId);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getFbsModuleSessionParticipants(page, size, searchTerm);
          const participantData = result.data;

          if (!cancelRequest.current) {
            if (participantData && Object.keys(participantData).length > 0) {
              setPaginatedParticipants(participantData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  const updateDistrictDataAndDispatch = async (participantId, fields) => {
    try {
      let buffer = {
        ...participantBuffer
      };

      setParticipantBuffer(buffer);

      const { data: data } = await updateFbsModuleSessionParticipant(
          participantId,
          fields
      );

      if (!cancelRequest.current) {
        setParticipantBuffer(data);
      }
    } catch (error) {
      setParticipantBuffer(participantBuffer);
    }
  };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedParticipants) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Participant first, last name"
                id="participant-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '95%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedParticipants.content}
                columns={[
                  {
                    field: "module",
                    headerName: "Module",
                    flex: 1,
                    renderCell: (params) => (
                        params.row.module ? params.row.module.fbsModule.name : ''
                    )
                  },
                  {
                    field: "person",
                    headerName: "Participant",
                    flex: 1,
                    renderCell: (params) => (
                        params.value ? params.value.personalData.firstName + ' ' + params.value.personalData.lastName : ''
                    )
                  },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                          <AddFbsSessionParticipantDialog classes={classes} action={'edit'}
                                                       confirmationHandler={updateConfirmationHandler} />
                          <DataGridActionBar data={params.row} deleteHandler={removeHandler}>
                        </DataGridActionBar></>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedParticipants.size}
                rowCount={paginatedParticipants.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
            <Fab
                size="small"
                color="primary"
                hidden={!isLoadingDump}
                aria-label="add"
                onClick={() => fetchDump()}
                className={classes.fab}
                style={{position: 'fixed', marginBottom: '1rem'}}
            >
              <DownloadIcon/>
            </Fab>
          </div>
          <AddFbsSessionParticipantDialog
              confirmationHandler={addConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Participants);