import React, {useEffect, useRef, useState} from 'react';
import { CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {withStyles} from "@material-ui/core/styles";
import {getCoreLessonStatistics} from "../../utils/api";
import Typography from "@material-ui/core/Typography";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
});


function CoreLessonStatistics(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [statistics, setStatistics] = useState('');

  const cancelRequest = useRef();

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getCoreLessonStatistics();
          const statisticsData = result.data;

          if (!cancelRequest.current) {
            if (statisticsData && Object.keys(statisticsData).length > 0) {
              setStatistics(statisticsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            Access denied!
          </>
      );
    }

    if (isLoading || !statistics) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <Paper variant="outlined" className={classes.paper}>
            <Typography variant="h4" color="textPrimary">
              Zambia
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              WORA
            </Typography>
            <Typography variant="body1" color="textPrimary">
            <strong>At least six:</strong>  {statistics.worasAtLeastSix.toLocaleString()} /
            <strong> All nine:</strong>  {statistics.worasAllNine.toLocaleString()}
            <Typography variant="subtitle1" color="textPrimary">
              MEN
            </Typography>
            <strong>At least six:</strong>  {statistics.menAtLeastSix.toLocaleString()} /
            <strong> All nine:</strong>  {statistics.menAllNine.toLocaleString()}
            </Typography>
            {statistics.provinceStatistics.map((province) => (
                <Paper variant="outlined" className={classes.paper} style={{backgroundColor: 'rgba(200, 15, 15, 0.1)'}}>
                  <Typography variant="h5" color="textPrimary">
                    {province.provinceName}
                  </Typography>
                  <Typography variant="subtitle1" color="textPrimary">
                    WORA
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    <strong>At least six:</strong>  {province.worasAtLeastSix.toLocaleString()} /
                    <strong> All nine:</strong>  {province.worasAllNine.toLocaleString()}
                  </Typography>
                  <Typography variant="subtitle1" color="textPrimary">
                    MEN
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    <strong>At least six:</strong>  {province.menAtLeastSix.toLocaleString()} /
                    <strong> All nine:</strong>  {province.menAllNine.toLocaleString()}
                    {province.districtStatistics.map((district) => (
                        <Paper elevation={0} className={classes.paper} style={{backgroundColor: 'rgba(200, 15, 15, 0.3)'}}>
                          <Typography variant="h6" color="textPrimary">
                            {district.districtName}
                          </Typography>
                          <Typography variant="subtitle1" color="textPrimary">
                            WORA
                          </Typography>
                          <Typography variant="body1" color="textPrimary">
                            <strong>At least six:</strong>  {district.worasAtLeastSix.toLocaleString()} /
                            <strong> All nine:</strong>  {district.worasAllNine.toLocaleString()}
                          </Typography>
                          <Typography variant="subtitle1" color="textPrimary">
                            MEN
                          </Typography>
                          <Typography variant="body1" color="textPrimary">
                            <strong>At least six:</strong>  {district.menAtLeastSix.toLocaleString()} /
                            <strong> All nine:</strong>  {district.menAllNine.toLocaleString()}
                          </Typography>
                        </Paper>
                    ))}
                  </Typography>
                </Paper>
            ))}
          </Paper>
        </>

    );
  };

  return renderData();
}

export default withStyles(styles)(CoreLessonStatistics);