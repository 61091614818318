import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {getCrsBeneficiaryGroups} from "../../../utils/api";
import DataGridActionBar from "../../parts/DataGridActionBar";
import debounce from "lodash.debounce";
import {formatTime} from "../../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function BeneficiaryGroups(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedBeneficiaryGroups, setPaginatedBeneficiaryGroups] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getCrsBeneficiaryGroups(page, size, searchTerm);
          const beneficiaryGroupsData = result.data;

          if (!cancelRequest.current) {
            if (beneficiaryGroupsData && Object.keys(beneficiaryGroupsData).length > 0) {
              setPaginatedBeneficiaryGroups(beneficiaryGroupsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedBeneficiaryGroups) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
      <>
        <div className={classes.contentWrapper} style={{}}>
          <TextField
              label="Group name"
              id="beneficiary-group-filter"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleSearchTermChanged}
          />
        </div>
        <DataGrid
            sortingOrder={['asc', 'desc', null]}
            scrollbarSize={15}
            columnTypes={['string', 'number', 'date', 'dateTime']}
            columnBuffer={2}
            headerHeight={56}
            localeText={'enUS'}
            rowHeight={52}
            icons={[]}
            className={classes.dataGrid}
            rows={paginatedBeneficiaryGroups.content}
            columns={[
              { field: 'groupCategory', headerName: 'Group category',
                flex: 1,
                renderCell: (params) =>
                    (params.row.groupCategoryValue.nameCategory)

              },
              { field: 'nameGroup', headerName: 'Group name', flex: 1 },
              {
                field: "camp",
                headerName: "Camp name",
                flex: 1,
                renderCell: (params) => (params.row.camp.campName)

              },
              {
                field: "agent",
                headerName: "Agent",
                flex: 1,
                renderCell: (params) =>
                  (params.row.crsAgent ?
                          params.row.crsAgent.person.personalData.firstName +
                          ' ' + params.row.crsAgent.person.personalData.lastName
                          : ''
                  )
              },
              { field: 'dateCreated', headerName: 'Date created',
                flex: 1,
                renderCell: (params) =>
                    (formatTime(params.row.dateCreated))
              },
              {
                field: "actions",
                headerName: "Actions",
                disableClickEventBubbling: true,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  return <DataGridActionBar data={params.row}>
                  </DataGridActionBar>;
                }
              }
            ]}
            density="compact"
            pagination
            pageSize={paginatedBeneficiaryGroups.size}
            rowCount={paginatedBeneficiaryGroups.totalElements}
            paginationMode="server"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
        />
      </>
    );
  };

  return renderData();
}

export default withStyles(styles)(BeneficiaryGroups);