import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createBeneficiary,
  createMultipleLessons,
  getLessons, getLessonsDump, updateLesson
} from "../../../utils/api";
import DataGridActionBar from "../../parts/DataGridActionBar";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/GetApp";
import AddLessonDialog from "../../dialogs/AddLessonDialog";
import DumpLoadingIndicator from "../../parts/DumpLoadingIndicator";
import FileDownload from "js-file-download";
import {UserContext} from "../../auth/UserProvider";
import CreationSnackbar from "../../dialogs/CreationSnackbar";
import debounce from "lodash.debounce";
import AddLessonByNvDialog from "../../dialogs/AddLessonByNvDialog";
import {formatTime} from "../../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '25rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function Lessons(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedLessons, setPaginatedLessons] = useState('');
  const [successBarText, setSuccessBarText] = useState('');
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [lessonBuffer, setLessonBuffer] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const cancelRequest = useRef();

  const authState = useContext(UserContext);

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getLessonsDump().then((response) => {
            FileDownload(response.data, 'lessons.csv');
            setIsLoadingDump(false);
          });
        } catch (error) {
          console.log(error);
        }
      };

  const addConfirmationHandler =
      async (values, beneficiaries) => {
        if (beneficiaries && beneficiaries.length > 0) {
          let completeBody = [];
          beneficiaries.map(beneficiary => {
            values.lessonTypes.map(lessonType => {
              completeBody.push({
                'lessonType': lessonType,
                'dateConducted': values.dateConducted,
                'household': beneficiary.person.household,
                'beneficiary': beneficiary,
              });
            });

          });
          await createMultipleLessons(completeBody).then((entity) => {
            setSuccessBarText(entity.data ? `${beneficiaries.length * values.lessonTypes.length} lesson(s) ` : '');
            handleSuccessBarState(true)
          });
        } else {
          let completeBody = [];
          values.lessonTypes.map(lessonType => {
            completeBody.push({
              'lessonType': lessonType,
              'dateConducted': values.dateConducted,
              'household': values.household
            });
          });
          await createMultipleLessons(completeBody).then((entity) => {
            setSuccessBarText(entity.data ? `${values.lessonTypes.length} lesson(s) ` : '');
            handleSuccessBarState(true)
          });
        }
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, values, beneficiaries) => {
        if (values.lessonTypes.length === 1) {
          let body = {
            'lessonType': values.lessonTypes[0],
            'dateConducted': values.dateConducted,
            'household': values.household,
          }

          if (beneficiaries.length === 1) {
            body['beneficiary'] = beneficiaries[0];
          }

          await updateLessonAndDispatch(entityId, body);
          await fetchData();
        }
      };

  const addBeneficiaryConfirmationHandler =
      async (values, gender) => {
        let body = {
          'person': {
            'dateRegistered': values.dateRegistered,
            'personalData': {
              'firstName': values.firstName,
              'lastName': values.lastName,
              'dateOfBirth': values.dateOfBirth,
              'gender': gender,
            },
            'household': values.household,
          },
          'status': values.status,
          'relation': values.relation,
        }

        await createBeneficiary(body).then((entity) => {
          setSuccessBarText(entity.data ? `Beneficiary ${entity.data.person.personalData.firstName} ${entity.data.person.personalData.lastName}` : '');
          handleSuccessBarState(true)
        });
        await fetchData();
      };

  const updateLessonAndDispatch = async (lessonId, fields) => {
    try {
      let buffer = {
        ...lessonBuffer
      };

      setLessonBuffer(buffer);

      const { data: data } = await updateLesson(
          lessonId,
          fields
      );

      if (!cancelRequest.current) {
        setLessonBuffer(data);
      }
    } catch (error) {
      setLessonBuffer(lessonBuffer);
    }
  };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getLessons(page, size, searchTerm);
          const lessonsData = result.data;

          if (!cancelRequest.current) {
            if (lessonsData && Object.keys(lessonsData).length > 0) {
              setPaginatedLessons(lessonsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const handleSuccessBarState = (open) => {
    setShowSuccessBar(open);
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedLessons) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
      <>
        <div className={classes.contentWrapper} style={{}}>
          <TextField
              label="FB number"
              id="lesson-filter"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleSearchTermChanged}
          />
        </div>
        <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={paginatedLessons.content}
              columns={[
                { field: 'monthConducted', headerName: 'Month conducted' },
                { field: 'yearConducted', headerName: 'Year conducted' },
                { field: 'lessonType', headerName: 'Lesson Type',
                  flex: 2,
                  renderCell: (params) =>
                      (params.row.lessonType.nameLessonType)

                },
                {
                  field: "fbNumber",
                  headerName: "Household FB number",
                  flex: 2,
                  renderCell: (params) =>
                      (params.row.beneficiary && params.row.beneficiary.person.household ? params.row.beneficiary.person.household.fbNumber : params.row.household ? params.row.household.fbNumber : '')

                },
                {
                  field: "beneficiary",
                  headerName: "Beneficiary", flex: 2,
                  renderCell: (params) => (
                      params.value ? params.value.person.personalData.firstName + ' ' + params.value.person.personalData.lastName : ''
                  )
                },
                {
                  field: "agent",
                  headerName: "Agent",
                  flex: 2,
                  renderCell: (params) =>
                      (params.row.crsAgent ?
                              params.row.crsAgent.person.personalData.firstName +
                              ' ' + params.row.crsAgent.person.personalData.lastName
                              : ''
                      )
                },
                { field: 'dateCreated', headerName: 'Date created',
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated))
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <>
                      <AddLessonDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                      <DataGridActionBar data={params.row}>
                      </DataGridActionBar>
                    </>;
                  }
                }
              ]}
              density="compact"
              pagination
              pageSize={paginatedLessons.size}
              rowCount={paginatedLessons.totalElements}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
        </div>
        <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
          <Fab
              size="small"
              color="primary"
              hidden={!isLoadingDump}
              aria-label="add"
              onClick={() => fetchDump()}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <DownloadIcon/>
          </Fab>
        </div>
        <AddLessonByNvDialog
            confirmationHandler={addConfirmationHandler} beneficiaryConfirmationHandler={addBeneficiaryConfirmationHandler} />
        <AddLessonDialog
            confirmationHandler={addConfirmationHandler} beneficiaryConfirmationHandler={addBeneficiaryConfirmationHandler} />
        <CreationSnackbar successBarOpen={showSuccessBar} successBarHandler={handleSuccessBarState} successText={successBarText} />
      </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Lessons);