import React, {useContext} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {UserContext} from "../auth/UserProvider";
import * as Yup from "yup";
import {useFormik} from "formik";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddProjectOutputTypeDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [open, setOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const OutputTypeSchema = Yup.object().shape({
    nameProjectOutputType: Yup.string().required('Required'),
  });

  const projectOutputTypeFormik = useFormik({
    initialValues: {
      nameProjectOutputType: '',
    },
    validationSchema: OutputTypeSchema,
  });

  const setFormFieldsTouched = async () => {
    await projectOutputTypeFormik.setFieldTouched('nameProjectOutputType', true);
  }

  const handleClickOpen = async () => {
    if (entity) {
      await projectOutputTypeFormik.setFieldValue('nameProjectOutputType', entity.nameProjectOutputType);
      await setFormFieldsTouched();
    }
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    await projectOutputTypeFormik.resetForm();
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(projectOutputTypeFormik.values) :
        confirmationHandler(entity.id, projectOutputTypeFormik.values);
    handleClose();
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        { action === 'create' &&
        <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            className={classes.fab}
            style={{position: 'fixed', marginBottom: '1rem'}}
        >
          <AddIcon/>
          Add Output Type
        </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
        <Tooltip title="Edit">
          <IconButton aria-label="edit"
                      onClick={handleClickOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} Output Type</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the new output type
            </DialogContentText>
            <FormControl className={classes.formControl}>
              <TextField
                  autoFocus
                  margin="normal"
                  id="output-type-name"
                  label="Name"
                  type="text"
                  fullWidth
                  name="nameProjectOutputType"
                  variant={'outlined'}
                  value={projectOutputTypeFormik.values.nameProjectOutputType}
                  onChange={projectOutputTypeFormik.handleChange}
                  error={Boolean(projectOutputTypeFormik.errors.nameProjectOutputType)}
                  helperText={projectOutputTypeFormik.errors.nameProjectOutputType}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={!projectOutputTypeFormik.dirty || Object.keys(projectOutputTypeFormik.errors).length !== 0} onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddProjectOutputTypeDialog)