import React, {useContext, useEffect} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  getHouseholdBeneficiaries, searchGardenInputTypes,
  searchHouseholds
} from "../../utils/api";
import {UserContext} from "../auth/UserProvider";
import CircularProgress from "@material-ui/core/CircularProgress";
import AutoComplete from "@material-ui/lab/Autocomplete";
import * as Yup from "yup";
import {useFormik} from "formik";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddGardenInputDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [household, setHousehold] = React.useState(undefined);
  const [beneficiary, setBeneficiary] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [acOpen, setAcOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const authState = useContext(UserContext);

  const GardenInputSchema = Yup.object().shape({
    dateDistributed: Yup.date().min(new Date("2015-01-01"), 'Date cannot be before 2015!').max(new Date(), 'Date cannot be in the future!').required('Required'),
    gardenInputType: Yup.object().nullable().required('Required'),
    household: Yup.object().nullable().required('Required'),
  });

  const gardenInputFormik = useFormik({
    initialValues: {
      dateDistributed: '',
      gardenInputType: null,
      household: null,
    },
    validationSchema: GardenInputSchema,
  });

  const setFormFieldsTouched = async () => {
    await gardenInputFormik.setFieldTouched('dateDistributed', true);
    await gardenInputFormik.setFieldTouched('gardenInputType', true);
    await gardenInputFormik.setFieldTouched('household', true);
  }

  useEffect(() => {
    onSelectHousehold();
  }, [household]);

  const handleClickOpen = async () => {
    if (entity) {
      console.log(entity);
      await gardenInputFormik.setFieldValue('dateDistributed', entity.dateDistributed ? entity.dateDistributed : `${entity.yearDistributed}-${entity.monthDistributed < 10 ? '0' + entity.monthDistributed  : entity.monthDistributed}-01`);
      await gardenInputFormik.setFieldValue('gardenInputType', entity.gardenInputType);
      await gardenInputFormik.setFieldValue('household', entity.household);
      setHousehold(entity.household);
      onBeneficiaryChange(entity.beneficiary);
      await setFormFieldsTouched();
    }
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    await gardenInputFormik.resetForm();
    setHousehold(undefined);
    setBeneficiary(null);
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(gardenInputFormik.values, beneficiary) :
        confirmationHandler(entity.id, gardenInputFormik.values, beneficiary);
    handleClose();
  };

  const onBeneficiaryChange = value => {
    setBeneficiary(value);
  };

  const onSelectHousehold = async () => {
    if (household && household.id)
    await getHouseholdBeneficiaries(household.id)
      .then((response) => setOptions(response.data));
  }

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        {action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add Input
          </Fab>
        }
        {action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
        <Tooltip title="Edit">
          <IconButton aria-label="edit"
                      onClick={handleClickOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} Garden Input</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the garden input
            </DialogContentText>
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="dateDistributed"
                  label="Date distributed"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="dateDistributed"
                  variant={'outlined'}
                  value={gardenInputFormik.values.dateDistributed}
                  onChange={gardenInputFormik.handleChange}
                  error={Boolean(gardenInputFormik.errors.dateDistributed)}
                  helperText={gardenInputFormik.errors.dateDistributed}
              />
            </div>
            <BasicAutocomplete
                handleChange={(value) => gardenInputFormik.setFieldValue("gardenInputType", value)}
                searchFct={searchGardenInputTypes}
                label={'Input Type'}
                property={'nameGardenInputType'}
                currentValue={gardenInputFormik.values.gardenInputType}
                error={Boolean(gardenInputFormik.errors.gardenInputType)}
                helperText={gardenInputFormik.errors.gardenInputType} />
            <BasicAutocomplete
                handleChange={(value) => {
                  gardenInputFormik.setFieldValue("household", value);
                  setHousehold(value);
                }}
                searchFct={searchHouseholds}
                label={'Household'}
                property={'fbNumber'}
                currentValue={gardenInputFormik.values.household}
                error={Boolean(gardenInputFormik.errors.household)}
                helperText={gardenInputFormik.errors.household} />
            { household &&
              <AutoComplete
                  style={{ marginTop: 20 }}
                  value={beneficiary}
                  id="autocomplete"
                  fullWidth
                  open={acOpen}
                  onOpen={() => {
                    setAcOpen(true);
                  }}
                  onClose={() => {
                    setAcOpen(false);
                  }}
                  onChange={(e, selectedValue) => onBeneficiaryChange(selectedValue)}
                  getOptionSelected={(option, value) => option && value && option.name === value.name}
                  getOptionLabel={(option) => {
                      return option.person.personalData.firstName + ' ' + option.person.personalData.lastName;
                  }}
                  onInputChange={onBeneficiaryChange}
                  options={options}
                  loading={loading}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label={'Beneficiary'}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                          }}
                      />
                  )}
              />
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={!gardenInputFormik.dirty || Object.keys(gardenInputFormik.errors).length !== 0} onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddGardenInputDialog)