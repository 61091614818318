import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import StatisticsIcon from '@material-ui/icons/Assessment';
import BeneficiaryIcon from '@material-ui/icons/AssignmentInd';
import GardenInputIcon from '@material-ui/icons/LocalFlorist';
import BeneficiaryGroupsIcon from '@material-ui/icons/GroupWork';
import HouseholdIcon from '@material-ui/icons/House';
import CrsIntermediariesIcon from '@material-ui/icons/PeopleOutline';
import GizIntermediariesIcon from '@material-ui/icons/People';
import LessonIcon from '@material-ui/icons/School';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import {UserContext} from "./auth/UserProvider";

const categories = [
  {
    id: 'Analysis, Reports and Targets',
    children: [
      { id: 'Dashboard', icon: <StatisticsIcon />,
        href: '/statistics/0' },
      { id: 'SLF Reporting', icon: <DnsRoundedIcon />,
        href: '/slf-reporting/0', role: 'ROLE_CEO' },
      { id: 'Report Creation', icon: <DnsRoundedIcon />,
        href: '/create-report/0', role: 'ROLE_READER' },
    ],
    role: 'ROLE_CEO',
  },
  {
    id: 'People',
    children: [
      { id: 'Households', icon: <HouseholdIcon />,
        href: '/households' },
      { id: 'Beneficiaries', icon: <BeneficiaryIcon />,
        href: '/beneficiaries' },
      { id: 'GIZ Intermediaries', icon: <GizIntermediariesIcon />,
        href: '/giz-intermediaries' },
      { id: 'CRS Intermediaries', icon: <CrsIntermediariesIcon />,
        href: '/crs-intermediaries' },
      { id: 'Dropouts', icon: <DnsRoundedIcon />,
        href: '/dropouts/0' },
    ],
  },
  {
    id: 'GIZ Activities',
    children: [
      { id: 'Training of Trainers', icon: <DnsRoundedIcon />,
        href: '/training-trainers/0' },
      { id: 'Visits and Observations', icon: <DnsRoundedIcon />,
        href: '/visits-observations/0' },
      { id: 'Beneficiary Trainings', icon: <DnsRoundedIcon />,
        href: '/beneficiary-trainings/0' },
      { id: 'Farming Groups', icon: <DnsRoundedIcon />,
        href: '/farming-groups/0' },
      { id: 'Farmer Business School', icon: <DnsRoundedIcon />,
        href: '/farmer-business-school/0' },
      { id: 'Supervisory Visits', icon: <DnsRoundedIcon />,
        href: '/psp-supervisory-visits', role: 'ROLE_PSP' },
    ],
    role: 'ROLE_PSP'
  },
  {
    id: 'Materials',
    children: [
      { id: 'Inputs', icon: <DnsRoundedIcon />,
        href: '/goods/0' },
      { id: 'IEC Materials', icon: <DnsRoundedIcon />,
        href: '/iec-materials' },
      { id: 'Working Materials', icon: <DnsRoundedIcon />,
        href: '/working-materials/0' },
    ],
  },
  {
    id: 'CRS Activities',
    children: [
      { id: 'Visits', icon: <DnsRoundedIcon />,
        href: '/visits' },
      { id: 'Project Outputs', icon: <DnsRoundedIcon />,
        href: '/project-outputs' },
      { id: 'Referrals', icon: <DnsRoundedIcon />,
        href: '/referrals' },
      { id: 'Garden Inputs', icon: <GardenInputIcon />,
        href: '/garden-inputs' },
      { id: 'Lessons', icon: <LessonIcon />,
        href: '/lessons' },
      { id: 'Distributed IEC Materials', icon: <DnsRoundedIcon />,
        href: '/distributed-iec-materials' },
      { id: 'Beneficiary Groups and Members', icon: <BeneficiaryGroupsIcon />,
        href: '/beneficiary-groups-crs' },
    ],
  },
  {
    id: 'Settings, Values and Planning',
    children: [
      { id: 'My Profile', icon: <PersonIcon/>, href: '/profile', role: 'ROLE_READER'},
      { id: 'User Management', icon: <PeopleIcon />, href: '/users/0', role: 'ROLE_READER'},
      { id: 'Geographic Sub-divisions', icon: <DnsRoundedIcon />,
        href: '/subdivisions/0'},
      { id: 'Duplications', icon: <DnsRoundedIcon />,
        href: '/duplications/0'},
      { id: 'CRS', icon: <DnsRoundedIcon />,
        href: '/crs-settings/0'},
      { id: 'Inputs', icon: <DnsRoundedIcon />,
        href: '/goods-settings/0'},
      { id: 'IEC Materials', icon: <DnsRoundedIcon />,
        href: '/iec-materials-settings/0'},
      { id: 'Working Material Types', icon: <DnsRoundedIcon />,
        href: '/working-material-types-settings'},
      { id: 'GIZ Intermediary Types', icon: <DnsRoundedIcon />,
        href: '/giz-intermediary-settings'},
      { id: 'Training Lessons', icon: <DnsRoundedIcon />,
        href: '/training-lesson-settings'},
      { id: 'Training of Trainers', icon: <DnsRoundedIcon />,
        href: '/tot-settings/0'},
      { id: 'Farmer Business School', icon: <DnsRoundedIcon />,
        href: '/fbs-settings/0'},
      { id: 'Practices', icon: <DnsRoundedIcon />,
        href: '/practice-settings'},
      { id: 'Key Outputs', icon: <DnsRoundedIcon />,
        href: '/key-output-settings'},
      { id: 'Techniques', icon: <DnsRoundedIcon />,
        href: '/technique-settings'},
      { id: 'Crops', icon: <DnsRoundedIcon />,
        href: '/crop-settings'},
      { id: 'Reasons for Leaving', icon: <DnsRoundedIcon />,
        href: '/leaving-reason-settings'},
      { id: 'Reasons for Abolishment', icon: <DnsRoundedIcon />,
        href: '/abolishment-reason-settings'},
      { id: 'Beneficiary Statuses', icon: <DnsRoundedIcon />,
        href: '/beneficiary-status-settings'},
      { id: 'Beneficiary Roles', icon: <DnsRoundedIcon />,
        href: '/beneficiary-roles-settings'},
    ], role: 'ROLE_READER',
  },
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.black,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: '#000000',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  itemCategory: {
    backgroundColor: '#ffffff',
    boxShadow: '0 -1px 0 #B72A20 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 12,
    color: theme.palette.common.black,
  },
  itemActiveItem: {
    color: '#C80F0F',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "red",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
    "&$selected:hover": {
      backgroundColor: "purple",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
    "&:hover": {
      backgroundColor: "white",
      color: "#C80F0F",
      "& .MuiListItemIcon-root": {
        color: "#C80F0F"
      }
    }
  },
  selected: {}
})(MuiListItem);

function Navigator(props) {
  const { classes, ...other } = props;
  const pathname = window.location.pathname;

  const authState = useContext(UserContext);

  return (
      <Drawer variant="permanent" {...other}>
        <List disablePadding>
          <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
            <table>
              <tbody>
              <tr>
                <td>
                  <img src={process.env.PUBLIC_URL + '/giz_logo.png'} alt="logo" width="100px"/>
                </td>
                <td>Food and Nutrition Security, Enhanced Resilience in Zambia (FANSER)</td>
              </tr>
              </tbody>
            </table>
          </ListItem>

          {authState.user && categories.filter(cat => {
            if (authState.role === 'ROLE_SUPER' || authState.role === 'ROLE_STANDARD') {
              return true;
            } else if (authState.role === 'ROLE_READER') {
              return authState.role !== cat.role;
            } else {
              return authState.role === cat.role;
            }
          }).map(({ id, children }) => (
              <React.Fragment key={id}>
                <ListItem className={classes.categoryHeader}>
                  <ListItemText
                      classes={{
                        primary: classes.categoryHeaderPrimary,
                      }}
                  >
                    {id}
                  </ListItemText>
                </ListItem>
                {children
                .filter(cat => {
                  if (authState.role === 'ROLE_SUPER' || authState.role === 'ROLE_STANDARD') {
                    return true;
                  } else if (authState.role === 'ROLE_READER') {
                    return authState.role !== cat.role;
                  } else {
                    return authState.role === cat.role;
                  }
                }).map(({ id: childId, icon, active, href, role }) => (
                    <ListItem
                        autoFocus={pathname === href}
                        key={childId}
                        button
                        className={clsx(classes.item, pathname === href && classes.itemActiveItem)}
                        component="a"
                        href={href}
                    >
                      <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                      <ListItemText
                          classes={{
                            primary: classes.itemPrimary,
                          }}
                      >
                        {childId}
                      </ListItemText>
                    </ListItem>
                ))}
                <Divider className={classes.divider} />
              </React.Fragment>
          ))}
        </List>
      </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);
