import React, {useCallback, useEffect, useRef, useState} from 'react';
import { CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createMultipleFbsModuleSessions, deleteFbsSessions,
  getFbsModuleSessions, updateFbsModuleSession
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import AddFbsSessionDialog from "../dialogs/AddFbsSessionDialog";
import debounce from "lodash.debounce";
import TextField from "@material-ui/core/TextField";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function Sessions(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedGoodsTypes, setPaginatedGoodsTypes] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sessionBuffer, setSessionBuffer] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const addConfirmationHandler =
      async (dateConducted, intermediary, camp, modules, additionalInfo) => {
        let body = [];

        modules.map(module => {body.push({
          'dateConducted': dateConducted,
          'intermediary': intermediary,
          'camp': camp,
          'fbsModule': module,
          'additionalInfo': additionalInfo,
        })})

        await createMultipleFbsModuleSessions(body);
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, dateConducted, intermediary, camp, module, additionalInfo) => {
        await updateDistrictDataAndDispatch(entityId, {
          'dateConducted': dateConducted,
          'intermediary': intermediary,
          'camp': camp,
          'fbsModule': module,
          'additionalInfo': additionalInfo,
        });
        await fetchData();
      };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (sessionId) => {
    await deleteFbsSessions(sessionId);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getFbsModuleSessions(page, size, searchTerm);
          const coursesData = result.data;

          if (!cancelRequest.current) {
            if (coursesData && Object.keys(coursesData).length > 0) {
              setPaginatedGoodsTypes(coursesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  const updateDistrictDataAndDispatch = async (sessionId, fields) => {
    try {
      let buffer = {
        ...sessionBuffer
      };

      setSessionBuffer(buffer);

      const { data: data } = await updateFbsModuleSession(
          sessionId,
          fields
      );

      if (!cancelRequest.current) {
        setSessionBuffer(data);
      }
    } catch (error) {
      setSessionBuffer(sessionBuffer);
    }
  };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedGoodsTypes) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Intermediary name, module name, camp name"
                id="session-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '95%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedGoodsTypes.content}
                columns={[
                  {
                    field: "fbsModule",
                    headerName: "Module",
                    flex: 1,
                    renderCell: (params) => (
                        params.row.fbsModule ? params.row.fbsModule.name : ''
                    )
                  },
                  {
                    field: "intermediary",
                    headerName: "Trainer",
                    flex: 1,
                    renderCell: (params) => (
                        params.value ? params.value.person.personalData.firstName + ' ' + params.value.person.personalData.lastName : ''
                    )
                  },
                  { field: 'dateConducted', headerName: 'Date conducted', flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateConducted))
                  },
                  { field: 'camp', headerName: 'Camp', flex: 1,
                    renderCell: (params) =>
                        (params.row.camp ? params.row.camp.campName : '')
                  },
                  { field: 'additionalInfo', headerName: 'PSP', flex: 1 },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                          <AddFbsSessionDialog classes={classes} action={'edit'}
                                                       confirmationHandler={updateConfirmationHandler} />
                          <DataGridActionBar data={params.row} deleteHandler={removeHandler}>
                        </DataGridActionBar></>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedGoodsTypes.size}
                rowCount={paginatedGoodsTypes.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <AddFbsSessionDialog confirmationHandler={addConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Sessions);