import React, {useContext, useEffect} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete
  from "../autocompletes/BasicAutocomplete";
import {searchGizIntermediaries, searchNutritionVolunteersWithHouseholds} from "../../utils/api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {UserContext} from "../auth/UserProvider";
import AutoComplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import {Chip} from "@material-ui/core";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '16rem',
  },
});

function AddFarmingGroupByNvDialog(props) {
  const { classes, confirmationHandler, action = 'create', entity } = props;
  const [gizIntermediary, setGizIntermediary] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);
  const [acOpen, setAcOpen] = React.useState(false);
  const [nvs, setNvs] = React.useState([]);
  const [nvOptions, setNvOptions] = React.useState([]);
  const [addedHouseholds, setAddedHouseholds] = React.useState([]);

  const authState = useContext(UserContext);

  const handleClickOpen = () => {
    setGizIntermediary(entity && entity.fbsCourse ? entity.fbsCourse : '');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setGizIntermediary('');
    setNvs([]);
  };

  useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchNutritionVolunteersWithHouseholds('');

      if (active) {
        if (response.data) {
          setNvOptions(response.data);
        } else {
          setNvOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  const handleConfirm = () => {
    confirmationHandler(gizIntermediary, nvs);
    handleClose();
  };

  const onChangeIntermediary = value => {
    setGizIntermediary(value);
  };

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        { action === 'create' &&
            <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
                onClick={handleClickOpen}
                className={classes.fab}
                style={{position: 'fixed', marginBottom: '1rem'}}
            >
              <AddIcon/>
              Add farming group by nvs
            </Fab>
        }
        { action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
            <Tooltip title="Edit">
              <IconButton aria-label="edit"
                          onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} farming group by NVs</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the farming group! This will create a farming group and add all households connected to the nutrition volunteers as members
            </DialogContentText>
            <BasicAutocomplete handleChange={onChangeIntermediary} searchFct={searchGizIntermediaries} label={'Intermediary'} property={'intermediary'} currentValue={gizIntermediary} />
            <AutoComplete
                style={{ marginTop: 20 }}
                multiple
                disableCloseOnSelect
                id="nv-multi-select"
                options={nvOptions}
                getOptionLabel={(option) => {
                  return `${option.person.personalData.firstName} ${option.person.personalData.lastName} (${option.village ? option.village.villageName + ' - ' + option.village.camp.campName : ''})`;
                }}
                getOptionSelected={(option, value) => option && value && option.id === value.id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Nutrition volunteers..."
                    />
                )}
                name="nvs"
                variant={'outlined'}
                value={nvs}
                fullWidth
                open={acOpen}
                onOpen={() => {
                  setAcOpen(true);
                }}
                onClose={() => {
                  setAcOpen(false);
                }}
                onInputChange={async (event) => {
                  if (event) {
                    await searchNutritionVolunteersWithHouseholds(event.target.value).then((response) => setNvOptions(response.data));
                  }}
                }
                onChange={(event, value) => {
                  let households = [];
                  value.forEach(nv => households.push(...nv.households))
                  setAddedHouseholds(households);
                  setNvs(value);
                }}
            />
            <br/>
            { addedHouseholds.length > 0 &&
                <Typography variant="subtitle1">Households that will be added to farming group:</Typography>
            }
            { addedHouseholds.length > 0 && addedHouseholds.map(hh =>
                    <Chip label={hh.fbNumber} variant="outlined" />
                )
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddFarmingGroupByNvDialog)