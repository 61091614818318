import React, {useCallback, useEffect, useRef, useState} from 'react';
import { CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createWorkingMaterialPurchase,
  deleteWorkingMaterialPurchase,
  getWorkingMaterialPurchases, updateWorkingMaterialPurchase
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import AddWorkingMaterialPurchaseDialog
  from "../dialogs/AddWorkingMaterialPurchaseDialog";
import debounce from "lodash.debounce";
import TextField from "@material-ui/core/TextField";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function WorkingMaterialPurchases(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedWorkingMaterialPurchases, setPaginatedWorkingMaterialPurchases] = useState('');
  const [purchaseBuffer, setPurchaseBuffer] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const addConfirmationHandler =
      async (quantity, type, purchaseDate) => {
        await createWorkingMaterialPurchase({
          'quantity': +quantity,
          'workingMaterialType': type,
          'datePurchased': purchaseDate,
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (cropId, quantity, type, purchaseDate) => {
        await updatePurchaseDataAndDispatch(cropId, {
          'quantity': +quantity,
          'workingMaterialType': type,
          'datePurchased': purchaseDate,
        });
        await fetchData();
      };

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (purchaseId, deleteType = 'SOFT') => {
    await deleteWorkingMaterialPurchase(purchaseId, deleteType);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getWorkingMaterialPurchases(page, size, searchTerm);
          const purchasesData = result.data;

          if (!cancelRequest.current) {
            if (purchasesData && Object.keys(purchasesData).length > 0) {
              setPaginatedWorkingMaterialPurchases(purchasesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const updatePurchaseDataAndDispatch = async (purchaseId, fields) => {
    try {
      let buffer = {
        ...purchaseBuffer
      };

      setPurchaseBuffer(buffer);

      const { data: purchaseData } = await updateWorkingMaterialPurchase(
          purchaseId,
          fields
      );

      if (!cancelRequest.current) {
        setPurchaseBuffer(purchaseData);
      }
    } catch (error) {
      setPurchaseBuffer(purchaseBuffer);
    }
  };

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedWorkingMaterialPurchases) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Material type"
                id="purchase-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedWorkingMaterialPurchases.content}
                getRowClassName={(params) => `row-${params.row.deleted === true ? 'deleted' : 'active'}`}
                columns={[
                  { field: 'workingMaterialType', headerName: 'Type', flex: 1,
                    valueFormatter: (params) =>
                        (params.value.name), },
                  { field: 'quantity', headerName: 'Quantity', flex: 1 },
                  { field: 'datePurchased', headerName: 'Date Purchased', flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.datePurchased))
                  },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                        <AddWorkingMaterialPurchaseDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                        <DataGridActionBar
                            data={params.row}
                            type={'Working material purchase'}
                            deleteHandler={removeHandler}/>
                      </>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedWorkingMaterialPurchases.size}
                rowCount={paginatedWorkingMaterialPurchases.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <AddWorkingMaterialPurchaseDialog
              confirmationHandler={addConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(WorkingMaterialPurchases);