import React, {useEffect, useRef, useState} from 'react';
import { CircularProgress } from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import {
  getDeliveredReports
} from "../../utils/api";
import {DataGrid} from "@material-ui/data-grid";
import {formatTime} from "../../utils/timeHelper";
import InspectReportDialog from "../dialogs/InspectReportDialog";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  }
});


function SlfDeliveredReports(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedReports, setPaginatedReports] = useState('');

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getDeliveredReports(page, size);
          const reportsData = result.data;

          if (!cancelRequest.current) {
            if (reportsData && Object.keys(reportsData).length > 0) {
              setPaginatedReports(reportsData);
              setIsLoading(false);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
            setIsLoading(false);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedReports) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedReports.content}
                getRowClassName={(params) => `row-${params.row.deleted === true ? 'deleted' : 'active'}`}
                columns={[
                  {
                    field: "seniorLeadFarmer",
                    headerName: "Submitted by",
                    width: 150,
                    renderCell: (params) => (
                        params.value ? params.value.person.personalData.firstName + ' ' + params.value.person.personalData.lastName : ''
                    )
                  },
                  { field: 'creationDate', headerName: 'Submission date', flex: 1,
                    valueFormatter: (params) => formatTime(params.value, 'dd.LL.yyyy HH:mm') },
                  { field: 'month', headerName: 'Month', width: 100 },
                  { field: 'year', headerName: 'Year', width: 85 },
                  {
                    field: "actions",
                    headerName: "Actions",
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    width: 50,
                    renderCell: (params) => {
                      return <>
                        <InspectReportDialog type={'delivered'} reportId={params.row.id} refresh={fetchData} />
                      </>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedReports.size}
                rowCount={paginatedReports.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(SlfDeliveredReports);