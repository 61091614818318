import React from 'react';

import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import AutoComplete from "@material-ui/lab/Autocomplete";

export default function BasicAutocomplete ({handleChange, searchFct, label, property, currentValue, error, helperText, disabled = false}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const onInputValueChange = async (event) => {
    if (event) {
      await searchFct(event.target.value)
      .then((response) => setOptions(response.data));
    }
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await searchFct('');

      if (active) {
        if (response.data) {
          setOptions(response.data);
        } else {
          setOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
      <AutoComplete
          style={{ marginTop: 20 }}
          value={currentValue === '' ? null : currentValue}
          id="autocomplete"
          disabled={disabled}
          fullWidth
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(e, selectedValue) => handleChange(selectedValue)}
          getOptionSelected={(option, value) => option && value && option.name === value.name}
          getOptionLabel={(option) => {
            if (property === 'villageName') {
              let villageName = option[property];
              let campName = option.camp.campName;
              return `${villageName} (Camp: ${campName})`
            } else if (property === 'villageNameSimple') {
              return `${option['villageName']}`
            } else if (property === 'personalData') {
              return option[property].firstName + ' ' + option[property].lastName;
            } else if (property === 'person') {
              return option[property].personalData.firstName + ' ' + option[property].personalData.lastName;
            } else if (property === 'intermediary') {
              return `${option.person.personalData.firstName} ${option.person.personalData.lastName} (${option.ward ? option.ward.wardName + ', ' + option.ward.district.districtName : ''})`;
            } else if (property === 'trainingLesson') {
              return `${option[property].name} (Date: ${option.dateConducted} 
            ${option.intermediary ? 'Trainer: ' + option.intermediary.person.personalData.firstName + ' ' + option.intermediary.person.personalData.lastName : ''})`;
            } else if (property === 'trainingBlock') {
              return `${option[property].name} (Date: ${option.dateConducted})`;
            } else if (property === 'nutritionalVolunteer') {
              return `${option.person.personalData.firstName} ${option.person.personalData.lastName} ${option.village ? '(Camp: ' + option.village.camp.campName + ')' : ''} [NRC: ${option.nrcNumber}]`;
            } else if (property === 'fbsModule') {
              return `${option[property].name} (Date: ${option.dateConducted})`;
            } else if (property === 'siteVisit') {
              return `${option.household.fbNumber} (Date: ${option.dateVisited})`;
            } else if (property === 'farmingGroupMember') {
              return `${option.household.fbNumber} (Group: ${option.farmingGroup.nameFarmingGroup})`;
            } else if (property === 'beneficiaryHousehold') {
              return `${option.personalData.firstName} ${option.personalData.lastName} ${option.household ? '(' + option.household.fbNumber + ')' : ''}`;
            } else {
              if (typeof option[property] === 'object' && option[property] !== null) {
                return option[property].firstName + ' ' + option[property].lastName;
              } else {
                return `${option[property]}`;
              }
            }
          }}
          onInputChange={onInputValueChange}
          options={options}
          loading={loading}
          renderInput={(params) => (
              <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                  }}
                  error={error}
                  helperText={helperText}
              />
          )}
      />
  );
}