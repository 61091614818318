import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import {withStyles} from "@material-ui/core/styles";
import {
  generateReport,
  searchCamps, searchDistricts,
  searchVillages,
  searchWards
} from "../../utils/api";
import Button from "@material-ui/core/Button";
import {CircularProgress} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import XLSX from "xlsx";
import {formatTime, getAge} from "../../utils/timeHelper";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import Typography from "@material-ui/core/Typography";
import {formatReportingLevel} from "../../utils/reportingLevelHelper";
import {formatAgentType} from "../../utils/agentTypeHelper";
import {formatGender} from "../../utils/genderHelper";
import {Autocomplete} from "@material-ui/lab";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
});

function CrsReport(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [reportType, setReportType] = useState('LESSON_REPORT');
  const [district, setDistrict] = React.useState(null);
  const [ward, setWard] = React.useState(null);
  const [camp, setCamp] = React.useState(null);
  const [village, setVillage] = React.useState(null);
  const [lesson, setLesson] = React.useState(null);
  const [lessonOptions, setLessonOptions] = React.useState([
    { id: 1, nameLessonType: 'Best start to Life why Nutrition Matters'},
    { id: 4, nameLessonType: 'Handwashing'},
    { id: 5, nameLessonType: 'Sanitation'},
    { id: 6, nameLessonType: 'Water handling,treatment and storage'},
    { id: 7, nameLessonType: 'Food Hygiene'},
    { id: 8, nameLessonType: 'Feeding a child 6 to 23 months- FADDUAH'},
    { id: 9, nameLessonType: 'Food group and diversity'},
  ]);

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleReportType = (event) => {
    setReportType(event.target.value);
  };

  const downloadLessonReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Lesson Report');

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let headerRow = ['FB number', 'Beneficiary', 'Role', 'Status', 'Lessons received'];

    let lessonTypeIds = [];

    data.lessonTypes.map(lt => {
      headerRow.push(lt.nameLessonType);
      lessonTypeIds.push(lt.id);
    });

    let ws_data = [
        ['Lesson Report'],
        [`From: ${data.fromDate}`],
        [`To: ${data.toDate}`],
        [],
        [`Reporting level: ${reportingLevel}`],
        [],
        [`${reportingLevel} name: ${data.reportingLevelName}`],
        [],
        ['Beneficiaries reached', data.trainedBeneficiaries.length],
        [],
        headerRow,
    ];

    data.trainedBeneficiaries.map(t => {
      let beneficiaryRow = [t.fbNumber, t.beneficiary, t.role, t.status, t.lessons.length];

      lessonTypeIds.map(lti => {
        if (t.lessons.find(l => l.lessonTypeId === lti)) {
          beneficiaryRow.push('1');
        } else {
          beneficiaryRow.push('0');
        }
      });

      ws_data.push(beneficiaryRow);
    });

    wb.Sheets['Lesson Report'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `Lesson_Report_${reportingLevel}_${data.reportingLevelName}_${formatTime(data.fromDate, 'yyyy-LL-dd')}-${formatTime(data.toDate, 'yyyy-LL-dd')}.xlsx`);
  };

  const fetchReport =
      async (loading, from, to, district, ward, camp, village, lesson) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          await generateReport({
            'fromDate': from,
            'toDate': to,
            'reportType': reportType,
            'district': district,
            'ward': ward,
            'camp': camp,
            'village': village,
            'lessonType': lesson
          }).then((response) => {
            setIsLoading(false);
            if (response.data && response.data.reportType === 'LESSON_REPORT') {
              downloadLessonReport(response.data);
            }
            if (response.data && response.data.reportType === 'INTERMEDIARY_LIST_REPORT') {
              downloadIntermediaryListReport(response.data);
            }
            if (response.data && response.data.reportType === 'INTERMEDIARY_COUNT_REPORT') {
              downloadIntermediaryCountReport(response.data);
            }
            if (response.data && response.data.reportType === 'BENEFICIARY_LIST_REPORT') {
              downloadBeneficiaryListReport(response.data);
            }
            if (response.data && response.data.reportType === 'BENEFICIARY_COUNT_REPORT') {
              downloadBeneficiaryCountReport(response.data);
            }
            if (response.data && response.data.reportType === 'INDIRECT_BENEFICIARY_LIST_REPORT') {
              downloadIndirectBeneficiaryListReport(response.data);
            }
            if (response.data && response.data.reportType === 'PROJECT_OUTPUT_LIST_REPORT') {
              downloadProjectOutputListReport(response.data);
            }
            if (response.data && response.data.reportType === 'PROJECT_OUTPUT_COUNT_REPORT') {
              downloadProjectOutputCountReport(response.data);
            }
            if (response.data && response.data.reportType === 'LESSON_ALL_NINE_COUNT_REPORT') {
              downloadCountLessonCountReport(response.data, 'All9');
            }
            if (response.data && response.data.reportType === 'LESSON_ALL_NINE_LIST_REPORT') {
              downloadCountLessonListReport(response.data, 'All9');
            }
            if (response.data && response.data.reportType === 'LESSON_AT_LEAST_SIX_COUNT_REPORT') {
              downloadCountLessonCountReport(response.data, 'Min6');
            }
            if (response.data && response.data.reportType === 'LESSON_AT_LEAST_SIX_LIST_REPORT') {
              downloadCountLessonListReport(response.data, 'Min6');
            }
            if (response.data && response.data.reportType === 'LESSON_AT_LEAST_SIX_LIST_REPORT') {
              downloadCountLessonListReport(response.data, 'Min6');
            }
            if (response.data && response.data.reportType === 'SINGLE_LESSON_COUNT_REPORT') {
              downloadSingleLessonTimeframeLocationReport(response.data);
            }
          });
        } catch (error) {
          console.log(error);
        }
      };

  const downloadIntermediaryListReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Intermediary Report (List)');

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let headerRow = ['District', 'Ward', 'Camp', 'Village', 'Name', 'Date of birth', 'Age', 'Gender', 'Type'];

    let ws_data = [
      ['Intermediary Report (List)'],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      ['Total intermediaries:', data.intermediaries.length],
      [],
      headerRow,
    ];

    data.intermediaries.map(i => {
      let intermediaryRow = [
          i.village ? i.village.camp.ward.district.districtName : '',
          i.village ? i.village.camp.ward.wardName : '',
          i.village ? i.village.camp.campName : '',
          i.village ? i.village.villageName : '',
          `${i.person.personalData.firstName} ${i.person.personalData.lastName}`,
          `${formatTime(i.person.personalData.dateOfBirth)}`,
          `${getAge(i.person.personalData.dateOfBirth)}`,
          `${formatGender(i.person.personalData.gender)}`,
          `${formatAgentType(i.agentType)}`,
      ];

      ws_data.push(intermediaryRow);
    });

    wb.Sheets['Intermediary Report (List)'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `Intermediary_List_Report_${reportingLevel}_${data.reportingLevelName}.xlsx`);
  };

  const downloadIntermediaryCountReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Intermediary Report (Count)');

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let headerRow = [`${reportingLevel}`]

    let secondHeader = [''];

    let countRow = [data.reportingLevelName];

    data.intermediaries.map(i => {
      headerRow.push(formatAgentType(i.intermediaryType));
      headerRow.push('');
      secondHeader.push('Female');
      secondHeader.push('Male');
      countRow.push(i.numberFemales);
      countRow.push(i.numberMales);
    });

    let ws_data = [
      ['Intermediary Report (Count)'],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      headerRow,
      secondHeader,
      countRow
    ];

    wb.Sheets['Intermediary Report (Count)'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `Intermediary_Count_Report_${reportingLevel}_${data.reportingLevelName}.xlsx`);
  };

  const downloadBeneficiaryListReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Beneficiary Report (List)');

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let headerRow = ['District', 'Ward', 'Camp', 'Village', 'FB number', 'Name', 'DoB', 'Age', 'Gender', 'Role', 'Status', 'NV', 'Active?'];

    let ws_data = [
      ['Beneficiary Report (List)'],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      ['Total beneficiaries:', data.beneficiaries.length],
      [],
      headerRow,
    ];

    data.beneficiaries.map(b => {
      let beneficiaryRow = [
        b.person.household ? b.person.household.addressData.village.camp.ward.district.districtName : '',
        b.person.household ? b.person.household.addressData.village.camp.ward.wardName : '',
        b.person.household ? b.person.household.addressData.village.camp.campName : '',
        b.person.household ? b.person.household.addressData.village.villageName : '',
        b.person.household ? b.person.household.fbNumber : '',
        `${b.person.personalData.firstName} ${b.person.personalData.lastName}`,
        `${formatTime(b.person.personalData.dateOfBirth)}`,
        `${getAge(b.person.personalData.dateOfBirth)}`,
        `${formatGender(b.person.personalData.gender)}`,
        `${b.relation ? b.relation.nameRelation : ''}`,
        `${b.status ? b.status.nameStatus : ''}`,
        b.person.household && b.person.household.agent ? `${b.person.household.agent.person.personalData.firstName} ${b.person.household.agent.person.personalData.lastName}` : '',
        data.dropoutIds.find(d => d === b.id) ? 'Dropped out' : 'Active'
      ];

      ws_data.push(beneficiaryRow);
    });

    wb.Sheets['Beneficiary Report (List)'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `Beneficiary_List_Report_${reportingLevel}_${data.reportingLevelName}.xlsx`);
  };

  const downloadIndirectBeneficiaryListReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Indirect Beneficiary Report');

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let headerRow = ['District', 'Ward', 'Camp', 'Village', 'FB number', 'Name', 'DoB', 'Age', 'Gender', 'Role', 'Status', 'NV'];

    let ws_data = [
      ['Indirect Beneficiary Report (List)'],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      ['Total beneficiaries:', data.beneficiaries.length],
      [],
      headerRow,
    ];

    data.beneficiaries.map(b => {
      let beneficiaryRow = [
        b.person.household ? b.person.household.addressData.village.camp.ward.district.districtName : '',
        b.person.household ? b.person.household.addressData.village.camp.ward.wardName : '',
        b.person.household ? b.person.household.addressData.village.camp.campName : '',
        b.person.household ? b.person.household.addressData.village.villageName : '',
        b.person.household ? b.person.household.fbNumber : '',
        `${b.person.personalData.firstName} ${b.person.personalData.lastName}`,
        `${formatTime(b.person.personalData.dateOfBirth)}`,
        `${getAge(b.person.personalData.dateOfBirth)}`,
        `${formatGender(b.person.personalData.gender)}`,
        `${b.relation ? b.relation.nameRelation : ''}`,
        `${b.status ? b.status.nameStatus : ''}`,
        b.person.household && b.person.household.agent ? `${b.person.household.agent.person.personalData.firstName} ${b.person.household.agent.person.personalData.lastName}` : '',
      ];

      ws_data.push(beneficiaryRow);
    });

    wb.Sheets['Indirect Beneficiary Report'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `Indirect_Beneficiary_List_Report_${reportingLevel}_${data.reportingLevelName}.xlsx`);
  };

  const downloadBeneficiaryCountReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Beneficiary Report (Count)');

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let headerRow = [`${reportingLevel}`];

    let countRow = [data.reportingLevelName];

    data.beneficiaries.map(b => {
      headerRow.push(b.type);
      countRow.push(b.number);
    });

    let ws_data = [
      ['Beneficiary Report (Count)'],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      headerRow,
      countRow
    ];

    wb.Sheets['Beneficiary Report (Count)'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `Beneficiary_Count_Report_${reportingLevel}_${data.reportingLevelName}.xlsx`);
  };

  const downloadCountLessonCountReport = (data, type = 'Min6') => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push(`Lesson Report ${type} (Count)`);

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let headerRow = [`${reportingLevel}`];

    let subheaderRow = [''];

    let countRow = [data.reportingLevelName];

    headerRow.push(type === 'Min6' ? 'Received at least 6 core lessons' : 'Received all nine core lessons');
    subheaderRow.push('WORA');
    subheaderRow.push('Men');
    countRow.push(data.numberTrainedWoras);
    countRow.push(data.numberTrainedMen);

    let ws_data = [
      [`Lesson Report [${type}] (Count)`],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      headerRow,
      subheaderRow,
      countRow
    ];

    wb.Sheets[`Lesson Report ${type} (Count)`] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `${type}_Lesson_Count_Report_${reportingLevel}_${data.reportingLevelName}.xlsx`);
  };

  const downloadSingleLessonTimeframeLocationReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push(`Single Lesson Report (Count)`);

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let lessonName = data.lessonName;

    let headerRow = [`${reportingLevel}`];

    let countRow = [data.reportingLevelName];

    headerRow.push('WORA');
    headerRow.push('Men');
    countRow.push(data.numberTrainedWoras);
    countRow.push(data.numberTrainedMen);

    let ws_data = [
      [`Single Lesson Report (Count)`],
      [`From: ${data.fromDate}`],
      [`To: ${data.toDate}`],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`Lesson name: ${lessonName}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      headerRow,
      countRow
    ];

    wb.Sheets[`Single Lesson Report (Count)`] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `Single_Lesson_Count_Report_${reportingLevel}_${data.reportingLevelName}_${formatTime(data.fromDate, 'yyyy-LL-dd')}-${formatTime(data.toDate, 'yyyy-LL-dd')}.xlsx`);
  };

  const downloadCountLessonListReport = (data, type = 'Min6') => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push(`${type} Lesson Report (List)`);

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let headerRow = ['FB number', 'Name', 'Role', 'Status', 'No. of lessons'];

    let ws_data = [
      [`${type} Lesson Report (List)`],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      ['Total beneficiaries:', data.trainedBeneficiaries.length],
      [],
      headerRow,
    ];

    data.trainedBeneficiaries.map(b => {
      let beneficiaryRow = [
        b.fbNumber,
        `${b.firstName ? b.firstName : ''} ${b.lastName ? b.lastName : ''}`,
        b.role,
        b.status,
        b.numberLessons
      ];

      ws_data.push(beneficiaryRow);
    });

    wb.Sheets[`${type} Lesson Report (List)`] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `${type}_Lesson_List_Report_${reportingLevel}_${data.reportingLevelName}.xlsx`);
  };

  const downloadProjectOutputCountReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Project Output (Count)');

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let headerRow = ['District', 'Ward', 'Camp', 'Village', 'FB number', 'Project outputs'];

    let ws_data = [
      ['Project Output Report (Count)'],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      ['Households reached', data.householdOutputs.length],
      [],
      headerRow,
    ];

    data.householdOutputs.map(ho => {
      let beneficiaryRow = [ho.districtName, ho.wardName, ho.campName, ho.villageName, ho.fbNumber, ho.outputs.length];
      ws_data.push(beneficiaryRow);
    });

    wb.Sheets['Project Output (Count)'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `Project_Output_Count_Report_${reportingLevel}_${data.reportingLevelName}.xlsx`);
  };

  const downloadProjectOutputListReport = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Project Output (List)');

    let reportingLevel = formatReportingLevel(data.reportingLevel);

    let headerRow = ['District', 'Ward', 'Camp', 'Village', 'FB number', 'Project outputs'];

    let outputTypeIds = [];

    data.outputTypes.map(lt => {
      headerRow.push(lt.nameProjectOutputType);
      outputTypeIds.push(lt.id);
    });

    let ws_data = [
      ['Project Output Report (List)'],
      [],
      [`Reporting level: ${reportingLevel}`],
      [],
      [`${reportingLevel} name: ${data.reportingLevelName}`],
      [],
      ['Households reached', data.householdOutputs.length],
      [],
      headerRow,
    ];

    data.householdOutputs.map(ho => {
      let beneficiaryRow = [ho.districtName, ho.wardName, ho.campName, ho.villageName, ho.fbNumber, ho.outputs.length];

      outputTypeIds.map(oti => {
        if (ho.outputs.find(l => l.outputTypeId === oti)) {
          beneficiaryRow.push('1');
        } else {
          beneficiaryRow.push('0');
        }
      });

      ws_data.push(beneficiaryRow);
    });

    wb.Sheets['Project Output (List)'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    XLSX.writeFile(wb,
        `Project_Output_List_Report_${reportingLevel}_${data.reportingLevelName}.xlsx`);
  };

  const renderData = () => {
    return (
        <>
          <Paper variant="outlined" className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                    disabled={reportType !== 'LESSON_REPORT' && reportType !== 'SINGLE_LESSON_COUNT_REPORT'}
                    autoFocus
                    margin="normal"
                    id="fromDate"
                    label="From"
                    type="date"
                    variant={'outlined'}
                    value={fromDate}
                    onChange={handleFromDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <TextField
                    disabled={reportType !== 'LESSON_REPORT' && reportType !== 'SINGLE_LESSON_COUNT_REPORT'}
                    autoFocus
                    margin="normal"
                    id="toDate"
                    label="To"
                    type="date"
                    variant={'outlined'}
                    value={toDate}
                    onChange={handleToDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <FormControl variant="outlined" style={{marginTop: 16, minWidth: '10rem'}}>
                  <InputLabel htmlFor="unit-select">Type</InputLabel>
                  <Select
                      native
                      value={reportType}
                      label={'Type'}
                      onChange={handleReportType}
                      name="unit"
                      inputProps={{
                        id: 'reportType-select',
                      }}
                  >
                    <optgroup label="General">
                      <option value={'INTERMEDIARY_LIST_REPORT'}>Intermediary Report (List)</option>
                      <option value={'INTERMEDIARY_COUNT_REPORT'}>Intermediary Report (Count)</option>
                      <option value={'BENEFICIARY_LIST_REPORT'}>Beneficiary Report (List)</option>
                      <option value={'BENEFICIARY_COUNT_REPORT'}>Beneficiary Report (Count)</option>
                      <option value={'INDIRECT_BENEFICIARY_LIST_REPORT'}>Indirect Beneficiary Report (List)</option>
                    </optgroup>
                    <optgroup label="Activities">
                      <option value={'LESSON_REPORT'}>Lesson Report (List)</option>
                      <option value={'LESSON_AT_LEAST_SIX_COUNT_REPORT'}>At Least 6 Lessons Report (Count)</option>
                      <option value={'LESSON_AT_LEAST_SIX_LIST_REPORT'}>At Least 6 Lessons Report (List)</option>
                      <option value={'LESSON_ALL_NINE_COUNT_REPORT'}>All 9 Lessons Report (Count)</option>
                      <option value={'LESSON_ALL_NINE_LIST_REPORT'}>All 9 Lessons Report (List)</option>
                      <option value={'SINGLE_LESSON_COUNT_REPORT'}>Single Lesson Report (Count)</option>
                      <option value={'PROJECT_OUTPUT_LIST_REPORT'}>Project Output Report (List)</option>
                      <option value={'PROJECT_OUTPUT_COUNT_REPORT'}>Project Output Report (Count)</option>
                    </optgroup>
                  </Select>
                </FormControl>
              </Grid>
              <Grid hidden={reportType !== 'SINGLE_LESSON_COUNT_REPORT'} item xs={12} md={4} xl={4} style={{paddingTop: '2em'}}>
                { (reportType === 'SINGLE_LESSON_COUNT_REPORT') &&
                    <>
                      <Typography color="inherit" variant="body1" component="div">
                        Please select a lesson for which the report should be generated
                      </Typography>
                      <Autocomplete
                          style={{marginTop: '1rem'}}
                          id="lesson-select"
                          options={lessonOptions}
                          getOptionLabel={(option) =>  `${option.nameLessonType}`}
                          value={lesson}
                          variant={'outlined'}
                          name="lesson"
                          onChange={(event, value) => setLesson(value)}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Lesson"
                              />
                          )}
                      />
                    </>
                }
              </Grid>
              <Grid item xs={12} md={4} xl={4} style={{paddingTop: '2em'}}>
                { (reportType === 'LESSON_REPORT' ||
                reportType === 'INTERMEDIARY_LIST_REPORT' ||
                reportType === 'INTERMEDIARY_COUNT_REPORT' ||
                    reportType === 'BENEFICIARY_LIST_REPORT' ||
                    reportType === 'BENEFICIARY_COUNT_REPORT' ||
                    reportType === 'INDIRECT_BENEFICIARY_LIST_REPORT' ||
                    reportType === 'PROJECT_OUTPUT_LIST_REPORT' ||
                        reportType === 'LESSON_ALL_NINE_COUNT_REPORT' ||
                        reportType === 'LESSON_ALL_NINE_LIST_REPORT' ||
                        reportType === 'LESSON_AT_LEAST_SIX_COUNT_REPORT' ||
                        reportType === 'LESSON_AT_LEAST_SIX_LIST_REPORT' ||
                    reportType === 'PROJECT_OUTPUT_COUNT_REPORT' || (reportType === 'SINGLE_LESSON_COUNT_REPORT' && lesson)) &&
                  <>
                    <Typography color="inherit" variant="body1" component="div">
                      Please specify the specific location for which the report should be generated
                    </Typography>
                    <BasicAutocomplete handleChange={(district) => setDistrict(district)} searchFct={searchDistricts} label={'District'} property={'districtName'} />
                    { district &&
                    <>
                      <BasicAutocomplete handleChange={(ward) => setWard(ward)} searchFct={(searchTerm) => searchWards(searchTerm, district.id)} label={'Ward'} property={'wardName'} />
                      <Typography color="inherit" variant="subtitle2" component="div">
                        Select ward or leave blank to report on entire district
                      </Typography>
                    </>
                    }
                    { ward &&
                      <>
                        <BasicAutocomplete handleChange={(camp) => setCamp(camp)} searchFct={(searchTerm) => searchCamps(searchTerm, ward.id)} label={'Camp'} property={'campName'} />
                        <Typography color="inherit" variant="subtitle2" component="div">
                          Select camp or leave blank to report on entire ward
                        </Typography>
                      </>
                    }
                    { camp &&
                      <>
                        <BasicAutocomplete handleChange={(village) => setVillage(village)} searchFct={(searchTerm) => searchVillages(searchTerm, camp.id)} label={'Village'} property={'villageNameSimple'} />
                        <Typography color="inherit" variant="subtitle2" component="div">
                          Select village or leave blank to report on entire camp
                        </Typography>
                      </>
                    }
                  </>
                }
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Button
                    style={{marginTop: 22}}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => fetchReport(true, fromDate, toDate, district, ward, camp, village, lesson)}>
                  Generate
                </Button>
              </Grid>
            </Grid>
          </Paper>
          { isLoading &&
            <Grid item xs={6} md={2} xl={2}>
              <CircularProgress size={72} color="primary" className={classes.paper}/>
            </Grid>
          }
        </>

    );
  };

  return renderData();
}

export default withStyles(styles)(CrsReport);