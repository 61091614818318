import React, {useContext} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Navigator from './Navigator';
import Header from './Header';
import CrsIntermediaries from "./people/CrsIntermediaries";
import Households from "./people/Households";
import Beneficiaries from "./people/Beneficiaries";
import GizIntermediaries from "./people/GizIntermediaries";
import Trainers from "./people/Trainers";
import Visits from "./activities/crs/Visits";
import ProjectOutputs from "./activities/crs/ProjectOutputs";
import Referrals from "./activities/crs/Referrals";
import GardenInputs from "./activities/crs/GardenInputs";
import Lessons from "./activities/crs/Lessons";
import DistributedIECMaterials from "./activities/crs/DistributedIECMaterials";
import BeneficiaryGroupsCrs from "./activities/crs/BeneficiaryGroupsCrs";
import ProtectedRoute from "./auth/ProtectedRoute";
import Subdivisions from "./settings/Subdivisions";
import GoodsSettings from "./settings/GoodsSettings";
import Goods from "./goods/Goods";
import IecMaterialsSettings from "./settings/IecMaterialsSettings";
import WorkingMaterialTypesSettings from "./settings/WorkingMaterialTypesSettings";
import IntermediaryTypesSettings from "./settings/IntermediaryTypesSettings";
import TrainingLessonSettings from "./settings/TrainingLessonSettings";
import BeneficiaryRoleSettings from "./settings/BeneficiaryRoleSettings";
import BeneficiaryStatusSettings from "./settings/BeneficiaryStatusSettings";
import ReasonsAbolishmentSettings from "./settings/ReasonsAbolishmentSettings";
import ReasonsLeavingSettings from "./settings/ReasonsLeavingSettings";
import CropSettings from "./settings/CropSettings";
import KeyElementSettings from "./settings/KeyOutputSettings";
import PracticeSettings from "./settings/PracticeSettings";
import TrainingOfTrainersSettings from "./settings/TrainingOfTrainersSettings";
import FarmerBusinessSchoolSettings
  from "./settings/FarmerBusinessSchoolSettings";
import TechniqueSettings from "./settings/TechniqueSettings";
import ReportCreation from "./reports/ReportCreation";
import TrainingOfTrainers from "./activities/giz/TrainingOfTrainers";
import VisitsAndObservations from "./activities/giz/VisitsAndObservations";
import BeneficiaryTrainings from "./activities/giz/BeneficiaryTrainings";
import FarmerBusinessSchool from "./activities/giz/FarmerBusinessSchool";
import FarmingGroups from "./activities/giz/FarmingGroups";
import IecMaterials from "./iec-materials/IecMaterialsDistribution";
import WorkingMaterials from "./working-materials/WorkingMaterials";
import Dropouts from "./people/Dropouts";
import PspSupervisoryVisits from "./fbs/PspSupervisoryVisits";
import {UserContext} from "./auth/UserProvider";
import Statistics from "./statistics/Statistics";
import SlfReports from "./reports/SlfReports";
import MyProfileSettings from "./settings/MyProfileSettings";
import CrsSettings from "./settings/CrsSettings";
import Imprint from "./DataProtection";
import DataProtection from "./DataProtection";
import Users from "./settings/Users";
import Duplications from "./settings/Duplications";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <a href={'/imprint'}>Imprint</a> | <a href={'/data-protection'}>Data Protection Policy</a>
    </Typography>
  );
}

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ffffff',
      main: '#C80F0F',
      dark: '#000000',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#ffffff',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#B72A20',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(4, 4),
    background: '#eaeff1',
  },
  mainTabbed: {
    flex: 1,
    background: '#eaeff1',
    padding: 0,
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
};

function Paperbase(props) {
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const authState = useContext(UserContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const query = new URLSearchParams(document.location.search);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          <Hidden smUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator PaperProps={{ elevation: 6, style: { width: drawerWidth } }} />
          </Hidden>
        </nav>
        <div className={classes.app}>
          <BrowserRouter>
            <Switch>
              <ProtectedRoute exact path="/create-report/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Report Creation"}
                        showNavBar={false}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <ReportCreation role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={authState.role === 'ROLE_CEO' ? ["/slf-reporting/:tab", "/"] : "/slf-reporting/:tab"}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"SLF Reporting"}
                        showNavBar={false}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <SlfReports role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={authState.role !== 'ROLE_PSP' && authState.role !== 'ROLE_CEO' ? ["/statistics/:tab", "/"] : "/statistics/:tab"}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Dashboard"}
                        showNavBar={false}
                        elevate={false}   />
                <main className={classes.mainTabbed}>
                  <Statistics role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/households">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Households"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <Households role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/beneficiaries">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Beneficiaries"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <Beneficiaries role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/crs-intermediaries">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"CRS Intermediaries"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <CrsIntermediaries role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/giz-intermediaries">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"GIZ Intermediaries"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <GizIntermediaries role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/trainers">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Trainers"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <Trainers role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/dropouts/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Dropouts"}
                        showNavBar={false}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <Dropouts role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/training-trainers/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Training of Trainers"}
                        showNavBar={true}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <TrainingOfTrainers role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/visits-observations/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Visits and Observations"}
                        showNavBar={true}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <VisitsAndObservations role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path={authState.role === 'ROLE_PSP' ? ["/psp-supervisory-visits", "/"] : "/psp-supervisory-visits"}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Supervisory Visits"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <PspSupervisoryVisits role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/beneficiary-trainings/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Beneficiary Trainings"}
                        showNavBar={true}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <BeneficiaryTrainings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/farming-groups/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Farming Groups"}
                        showNavBar={true}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <FarmingGroups role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/farmer-business-school/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Farmer Business School"}
                        showNavBar={true}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <FarmerBusinessSchool role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/visits">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Visits"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <Visits role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/project-outputs">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Project Outputs"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <ProjectOutputs role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/referrals">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Referrals"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <Referrals role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/garden-inputs">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Garden Inputs"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <GardenInputs role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/lessons">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Lessons"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <Lessons role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/distributed-iec-materials">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Distributed IEC Materials"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <DistributedIECMaterials />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/beneficiary-groups-crs">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Beneficiary Groups"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <BeneficiaryGroupsCrs role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/goods/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Inputs"}
                        showNavBar={false}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <Goods role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/iec-materials">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"IEC Materials"}
                        showNavBar={true}  />
                <main className={classes.main}>
                  <IecMaterials role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/working-materials/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Working Materials"}
                        showNavBar={false}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <WorkingMaterials role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/subdivisions/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Geographic Subdivisions"}
                        showNavBar={false}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <Subdivisions role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/goods-settings/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Input Settings"}
                        showNavBar={false}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <GoodsSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/crs-settings/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"CRS Settings"}
                        showNavBar={false}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <CrsSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/iec-materials-settings/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"IEC Material Settings"}
                        showNavBar={false}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <IecMaterialsSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/working-material-types-settings">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Working Material Settings"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <WorkingMaterialTypesSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/giz-intermediary-settings">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"GIZ Intermediary Settings"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <IntermediaryTypesSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/training-lesson-settings">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Training Lessons"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <TrainingLessonSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/practice-settings">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Practice Settings"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <PracticeSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/key-output-settings">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Key Output Settings"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <KeyElementSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/technique-settings">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Technique Settings"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <TechniqueSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/crop-settings">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Crop Settings"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <CropSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/leaving-reason-settings">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Reasons for Leaving Values"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <ReasonsLeavingSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/abolishment-reason-settings">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Reasons for Abolishment Values"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <ReasonsAbolishmentSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/beneficiary-status-settings">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Beneficiary Status Values"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <BeneficiaryStatusSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/beneficiary-roles-settings">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Beneficiary Role Values"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <BeneficiaryRoleSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/tot-settings/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Training of Trainers Settings"}
                        showNavBar={false}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <TrainingOfTrainersSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/fbs-settings/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Farmer Business School Settings"}
                        showNavBar={false}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <FarmerBusinessSchoolSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/profile">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"My Profile"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <MyProfileSettings role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/users/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"User Settings"}
                        showNavBar={false}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <Users role={authState.role} />
                </main>
              </ProtectedRoute>
              <ProtectedRoute exact path="/duplications/:tab">
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Duplications"}
                        showNavBar={false}
                        elevate={false}  />
                <main className={classes.mainTabbed}>
                  <Duplications role={authState.role} />
                </main>
              </ProtectedRoute>
              <Route exact path={["/imprint"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Imprint"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <Imprint />
                </main>
              </Route>
              <Route exact path={["/data-protection"]}>
                <Header onDrawerToggle={handleDrawerToggle}
                        headline={"Data Protection Policy"}
                        showNavBar={false}  />
                <main className={classes.main}>
                  <DataProtection />
                </main>
              </Route>
              <Route path='/authentication' component={() => {
                window.location.href =
                    `${process.env.REACT_APP_FIREBASE_REDIRECT_URL}?mode=${query.get('mode')}&oobCode=${query.get('oobCode')}&apiKey=${query.get('apiKey')}&lang=${query.get('lang')}`;
                return null;
              }}/>
            </Switch>
          </BrowserRouter>
          <footer className={classes.footer}>
            <Copyright />
          </footer>
        </div>
      </div>
    </ThemeProvider>
  );
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Paperbase);
