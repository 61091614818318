import React, {useContext, useEffect} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {
  getHouseholdBeneficiaries,
  searchHouseholds, searchLessonTypes
} from "../../utils/api";
import {UserContext} from "../auth/UserProvider";
import AutoComplete from "@material-ui/lab/Autocomplete";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Autocomplete} from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {CircularProgress} from "@material-ui/core";
import AddBeneficiaryDialog from "./AddBeneficiaryDialog";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function AddLessonDialog(props) {
  const { classes, confirmationHandler, beneficiaryConfirmationHandler, action = 'create', entity } = props;
  const [household, setHousehold] = React.useState(undefined);
  const [beneficiaries, setBeneficiaries] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [acOpen, setAcOpen] = React.useState(false);
  const [lessonOptions, setLessonOptions] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const authState = useContext(UserContext);

  const LessonSchema = Yup.object().shape({
    dateConducted: Yup.date().min(new Date("2015-01-01"), 'Date cannot be before 2015!').max(new Date(), 'Date cannot be in the future!').required('Required'),
    lessonTypes: Yup.array().min(1, 'Select at least one').required('Required'),
    household: Yup.object().nullable().required('Required'),
  });

  const lessonFormik = useFormik({
    initialValues: {
      dateConducted: '',
      lessonTypes: [],
      household: null,
    },
    validationSchema: LessonSchema,
  });

  const setFormFieldsTouched = async () => {
    await lessonFormik.setFieldTouched('dateConducted', true);
    await lessonFormik.setFieldTouched('lessonTypes', true);
    await lessonFormik.setFieldTouched('household', true);
  }

  useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchLessonTypes('');

      if (active) {
        if (response.data) {
          setLessonOptions(response.data);
        } else {
          setLessonOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    onSelectHousehold();
  }, [household]);

  const handleClickOpen = async () => {
    if (entity) {
      await lessonFormik.setFieldValue('dateConducted', entity.dateConducted);
      await lessonFormik.setFieldValue('lessonTypes', [entity.lessonType]);
      await lessonFormik.setFieldValue('household', entity.beneficiary ? entity.beneficiary.person.household : entity.household);
      setHousehold(entity.beneficiary ? entity.beneficiary.person.household : entity.household);
      setBeneficiaries(entity.beneficiary ? [entity.beneficiary] : []);
      await setFormFieldsTouched();
    }
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    await lessonFormik.resetForm();
    setHousehold(undefined);
    setBeneficiaries([]);
  };

  const handleConfirm = () => {
    action === 'create' ?
        confirmationHandler(lessonFormik.values, beneficiaries) :
        confirmationHandler(entity.id, lessonFormik.values, beneficiaries);
    handleClose();
  };

  const onSelectHousehold = async () => {
    if (household && household.id)
    await getHouseholdBeneficiaries(household.id)
      .then((response) => setOptions(response.data));
  }

  return (
      <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
        {action === 'create' &&
          <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleClickOpen}
              className={classes.fab}
              style={{position: 'fixed', marginBottom: '1rem'}}
          >
            <AddIcon/>
            Add Lesson
          </Fab>
        }
        {action === 'edit' && authState && authState.role && authState.role === 'ROLE_SUPER' &&
          <Tooltip title="Edit">
            <IconButton aria-label="edit"
                        onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} Lesson</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the details of the lesson
            </DialogContentText>
            <div>
              <TextField
                  autoFocus
                  margin="normal"
                  id="dateConducted"
                  label="Date of lesson"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="dateConducted"
                  variant={'outlined'}
                  value={lessonFormik.values.dateConducted}
                  onChange={lessonFormik.handleChange}
                  error={Boolean(lessonFormik.errors.dateConducted)}
                  helperText={lessonFormik.errors.dateConducted}
              />
            </div>
            {action === 'create' &&
              <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id="output-multi-select"
                  options={lessonOptions}
                  getOptionLabel={(option) =>  `${option.nameLessonType}`}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          variant="outlined"
                          label="Lessons..."
                          error={Boolean(lessonFormik.errors.lessonTypes)}
                          helperText={lessonFormik.errors.lessonTypes}
                      />
                  )}
                  name="lessonTypes"
                  variant={'outlined'}
                  value={lessonFormik.values.lessonTypes}
                  onChange={(event, value) => lessonFormik.setFieldValue("lessonTypes", value)}
              />
            }
            {action === 'edit' &&
              <BasicAutocomplete
                  handleChange={(value) => lessonFormik.setFieldValue("lessonTypes", [value])}
                  searchFct={searchLessonTypes}
                  label={'Lesson'}
                  property={'nameLessonType'}
                  currentValue={lessonFormik.values.lessonTypes.length > 0 ? lessonFormik.values.lessonTypes[0] : null} />
            }
            <BasicAutocomplete
                handleChange={(value) => {
                  lessonFormik.setFieldValue("household", value);
                  setHousehold(value);
                  setBeneficiaries([]);
                }}
                searchFct={searchHouseholds}
                label={'Household'}
                property={'fbNumber'}
                currentValue={lessonFormik.values.household}
                error={Boolean(lessonFormik.errors.household)}
                helperText={lessonFormik.errors.household} />
            {household && action === 'create' &&
              <AddBeneficiaryDialog confirmationHandler={beneficiaryConfirmationHandler} action="lessonCreate" preselectHousehold={household} />
            }
            { household && action === 'create' &&
              <AutoComplete
                  style={{ marginTop: 20 }}
                  multiple
                disableCloseOnSelect
                  id="beneficiaries-multi-select"
                  options={options}
                  getOptionLabel={(option) => {
                    return option.person.personalData.firstName + ' ' + option.person.personalData.lastName;
                  }}
                  getOptionSelected={(option, value) => option && value && option.id === value.id}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          variant="outlined"
                          label="Beneficiaries..."
                      />
                  )}
                  name="beneficiaries"
                  variant={'outlined'}
                  value={beneficiaries}
                  fullWidth
                  open={acOpen}
                  onOpen={() => {
                    setAcOpen(true);
                  }}
                  onClose={() => {
                    setAcOpen(false);
                  }}
                  onChange={(event, value) => setBeneficiaries(value)}
              />
            }
            {household && action === 'edit' &&
              <AutoComplete
                  style={{ marginTop: 20 }}
                  value={beneficiaries.length > 0 ? beneficiaries[0] : null}
                  id="autocomplete"
                  fullWidth
                  open={acOpen}
                  onOpen={() => {
                    setAcOpen(true);
                  }}
                  onClose={() => {
                    setAcOpen(false);
                  }}
                  onChange={(e, value) => setBeneficiaries([value])}
                  getOptionSelected={(option, value) => option && value && option.name === value.name}
                  getOptionLabel={(option) => {
                    return option.person.personalData.firstName + ' ' + option.person.personalData.lastName;
                  }}
                  options={options}
                  loading={loading}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label={'Beneficiary'}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                          }}
                      />
                  )}
              />
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={!lessonFormik.dirty || Object.keys(lessonFormik.errors).length !== 0} onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(AddLessonDialog)