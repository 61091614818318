import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {getDuplicateSeniorLeadFarmers} from "../../../utils/api";
import {formatGender} from "../../../utils/genderHelper";
import {formatTime} from "../../../utils/timeHelper";
import MergeIntermediariesDialog from "../../dialogs/MergeIntermediariesDialog";
import debounce from "lodash.debounce";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white',
    height: '88%',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function SlfDuplicates(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedDuplicates, setPaginatedDuplicates] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getDuplicateSeniorLeadFarmers(page, size, searchTerm);
          const fetchedData = result.data;

          if (!cancelRequest.current) {
            if (fetchedData && Object.keys(fetchedData).length > 0) {
              setPaginatedDuplicates(fetchedData);
              setIsLoading(false);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedDuplicates) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Senior lead farmer name"
                id="slf-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedDuplicates.content}
                getRowClassName={(params) => `row-${params.row.deleted === true ? 'deleted' : 'active'}`}
                columns={[
                  {
                    field: "agentType",
                    headerName: "Intermediary type",
                    flex: 1,
                    renderCell: (params) =>
                        (params.row.intermediaryType.nameIntermediaryType)
                  },
                  {
                    field: "firstNames",
                    headerName: "First name(s)",
                    flex: 1,
                    renderCell: (params) => (
                        params.row.person.personalData.firstName
                    )
                  },
                  {
                    field: "lastName",
                    headerName: "Last name",
                    flex: 1,
                    renderCell: (params) => (
                        params.row.person.personalData.lastName
                    )
                  },
                  {
                    field: "gender",
                    headerName: "Gender",
                    flex: 1,
                    renderCell: (params) => (
                        formatGender(params.row.person.personalData.gender)
                    )
                  },
                  {
                    field: "dateOfBirth",
                    headerName: "Date of Birth",
                    flex: 1,
                    renderCell: (params) => (
                        formatTime(params.row.person.personalData.dateOfBirth)
                    )
                  },
                  {
                    field: "phoneNumber",
                    headerName: "Phone number",
                    flex: 1,
                    renderCell: (params) => (
                        params.row.person.personalData.phoneNumber
                    )
                  },
                  {
                    field: "district",
                    headerName: "District",
                    flex: 1,
                    renderCell: (params) => (
                        params.row.ward && params.row.ward.district ? params.row.ward.district.districtName : ''
                    )
                  },
                  {
                    field: "ward1",
                    headerName: "Ward",
                    flex: 1,
                    renderCell: (params) => (
                        params.row.ward ? params.row.ward.wardName : ''
                    )
                  },
                  {
                    field: "observingIntermediary",
                    headerName: "Observed by",
                    flex: 1,
                    renderCell: (params) => (
                        params.value ? params.value.person.personalData.firstName + ' ' + params.value.person.personalData.lastName : ''
                    )
                  },
                  {
                    field: 'actions',
                    headerName: 'Actions',
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                        <MergeIntermediariesDialog intermediaryId={params.row.id} reloadItems={() => fetchData(true)}/>
                      </>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedDuplicates.size}
                rowCount={paginatedDuplicates.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(SlfDuplicates);