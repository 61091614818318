import React from 'react';
import {CircularProgress, Typography} from "@material-ui/core";


function DumpLoadingIndicator() {

  return (
      <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
        <CircularProgress style={{marginTop: '10%'}} color="primary" />
        <Typography style={{marginTop: '1rem'}} color="inherit" variant="subtitle1">Generating huge dataset. This may take a few minutes, please stay on this page until download has finished!</Typography>
      </div>
  );
}

export default DumpLoadingIndicator;