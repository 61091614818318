import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createMultipleGoodDistributions,
  deleteGoodDistribution,
  getGoodDistributions,
  getGoodDistributionsDump,
  updateGoodDistribution
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import AddGoodsDistributionDialog from "../dialogs/AddGoodsDistributionDialog";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/GetApp";
import FileDownload from "js-file-download";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";
import {UserContext} from "../auth/UserProvider";
import debounce from "lodash.debounce";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '15rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function GoodsDistribution(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedGoodsDistributions, setPaginatedGoodsDistributions] = useState('');
  const [distributionBuffer, setDistributionBuffer] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const authState = useContext(UserContext);

  const cancelRequest = useRef();

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getGoodDistributionsDump()
          .then((response) => {
            FileDownload(response.data, 'beneficiary_inputs.csv');
            setIsLoadingDump(false);
          });
        } catch (error) {
          console.log(error);
        }
      };

  const addConfirmationHandler =
      async (quantity, types, intermediary, households, distributionDate) => {

        let completeBody = [];

        types.map(type => {
          households.map(household => {
            completeBody.push({
              'quantity': +quantity,
              'goodType': type,
              'gizIntermediary': intermediary,
              'household': household,
              'dateDistributed': distributionDate,
            });
          });
        });

        await createMultipleGoodDistributions(completeBody);
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, quantity, type, intermediary, household, distributionDate) => {
        await updateGoodsDistributionDataAndDispatch(entityId, {
          'quantity': +quantity,
          'goodType': type,
          'gizIntermediary': intermediary,
          'household': household,
          'dateDistributed': distributionDate,
        });
        await fetchData();
      };

  const updateGoodsDistributionDataAndDispatch = async (distributionId, fields) => {
    try {
      let buffer = {
        ...distributionBuffer
      };

      setDistributionBuffer(buffer);

      const { data: data } = await updateGoodDistribution(
          distributionId,
          fields
      );

      if (!cancelRequest.current) {
        setDistributionBuffer(data);
      }
    } catch (error) {
      setDistributionBuffer(distributionBuffer);
    }
  };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (distributionId, deleteType = 'SOFT') => {
    await deleteGoodDistribution(distributionId, deleteType);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getGoodDistributions(page, size, searchTerm);
          const distributionData = result.data;

          if (!cancelRequest.current) {
            if (distributionData && Object.keys(distributionData).length > 0) {
              setPaginatedGoodsDistributions(distributionData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedGoodsDistributions) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Type, FB number"
                id="distribution-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedGoodsDistributions.content}
                columns={[
                  { field: 'goodType', headerName: 'Type', flex: 1,
                    valueFormatter: (params) =>
                        (params.value.name), },
                  {
                    field: 'gizIntermediary', headerName: 'Intermediary', flex: 1,
                    renderCell: (params) =>
                        (params.row.gizIntermediary ?
                                params.row.gizIntermediary.person.personalData.firstName
                                +
                                ' '
                                + params.row.gizIntermediary.person.personalData.lastName
                                : ' '
                        )
                  },
                  {
                    field: "household",
                    headerName: "Household FB number",
                    flex: 1,
                    renderCell: (params) =>
                        (params.value.fbNumber)
                  },
                  { field: 'quantity', headerName: 'Quantity', flex: 1 },
                  { field: 'dateDistributed', headerName: 'Date distributed', flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateDistributed))
                  },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                        <AddGoodsDistributionDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                        <DataGridActionBar
                            data={params.row}
                            type={'Good distribution'}
                            deleteHandler={removeHandler}
                            relations={['None']}
                        >
                        </DataGridActionBar>
                      </>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedGoodsDistributions.size}
                rowCount={paginatedGoodsDistributions.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
            <Fab
                size="small"
                color="primary"
                aria-label="add"
                onClick={() => fetchDump()}
                className={classes.fab}
                style={{position: 'fixed', marginBottom: '1rem'}}
            >
              <DownloadIcon/>
            </Fab>
          </div>
          <AddGoodsDistributionDialog
              confirmationHandler={addConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(GoodsDistribution);