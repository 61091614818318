import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createPractice,
  deletePractice,
  getPractices,
  updatePractice
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import AddPracticeDialog from "../dialogs/AddPracticeDialog";
import debounce from "lodash.debounce";
import {formatTime} from "../../utils/timeHelper";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});


function PracticeSettings(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedPractices, setPaginatedPractices] = useState('');
  const [practiceBuffer, setPracticeBuffer] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const addConfirmationHandler =
      async (name, explanation) => {
        await createPractice({
          'name': name,
          'explanation': explanation,
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (cropId, name, explanation) => {
        await updatePracticeDataAndDispatch(cropId, {
          'name': name,
          'explanation': explanation,
        });
        await fetchData();
      };

  const cancelRequest = useRef();

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (practiceId, deleteType = 'SOFT') => {
    await deletePractice(practiceId, deleteType);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getPractices(page, size, searchTerm);
          const practicesData = result.data;

          if (!cancelRequest.current) {
            if (practicesData && Object.keys(practicesData).length > 0) {
              setPaginatedPractices(practicesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const updatePracticeDataAndDispatch = async (practiceId, fields) => {
    try {
      let buffer = {
        ...practiceBuffer
      };

      setPracticeBuffer(buffer);

      const { data: practiceData } = await updatePractice(
          practiceId,
          fields
      );

      if (!cancelRequest.current) {
        setPracticeBuffer(practiceData);
      }
    } catch (error) {
      setPracticeBuffer(practiceBuffer);
    }
  };

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedPractices) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Practice name"
                id="practice-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <DataGrid
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={paginatedPractices.content}
              getRowClassName={(params) => `row-${params.row.deleted === true ? 'deleted' : 'active'}`}
              columns={[
                { field: 'name', headerName: 'Name', flex: 1 },
                { field: 'explanation', headerName: 'Explanation', flex: 1 },
                { field: 'dateCreated', headerName: 'Date created',
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated))
                },
                {
                  field: 'actions',
                  headerName: 'Action',
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    return <>
                      <AddPracticeDialog confirmationHandler={updateConfirmationHandler} action={'edit'} entity={params.row}/>
                      <DataGridActionBar
                          data={params.row}
                          type={'Practice'}
                          deleteHandler={removeHandler}
                          relations={[
                            'Practice usage',
                          ]}/>
                    </>;
                  }
                }
              ]}
              density="compact"
              pagination
              pageSize={paginatedPractices.size}
              rowCount={paginatedPractices.totalElements}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
          />
          <AddPracticeDialog
              confirmationHandler={addConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(PracticeSettings);