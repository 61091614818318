import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {CircularProgress, TextField} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import {withStyles} from "@material-ui/core/styles";
import {
  createMultipleKeyOutputsUsed, deleteKeyOutputUsage,
  getKeyOutputsUsed, getKeyOutputsUsedDump, updateKeyElementUsage
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import AddKeyOutputUsage from "../dialogs/AddKeyOutputUsageDialog";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/GetApp";
import {UserContext} from "../auth/UserProvider";
import FileDownload from "js-file-download";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";
import debounce from "lodash.debounce";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '20rem',
  },
  contentWrapper: {
    marginBottom: '1rem',
  },
});

function KeyOutputsUsed(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paginatedTrainingBlocks, setPaginatedGoodsTypes] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [usageBuffer, setUsageBuffer] = useState('');

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleSearchTermChanged = async (event) => {
    debouncedSetSearchTerm(event.target.value);
  }

  const authState = useContext(UserContext);

  const cancelRequest = useRef();

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getKeyOutputsUsedDump().then((response) => {
            FileDownload(response.data, 'key_outputs.csv');
            setIsLoadingDump(false);
          });
        } catch (error) {
          console.log(error);
        }
      };

  const addConfirmationHandler =
      async (keyOutputs, siteVisit) => {
        let body = [];

        keyOutputs.map(keyOutput => {body.push({
          'keyElement': keyOutput,
          'siteVisit': siteVisit,
        })});

        await createMultipleKeyOutputsUsed(body);
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, keyOutput, siteVisit) => {
        await updateDistrictDataAndDispatch(entityId, {
          'keyElement': keyOutput,
          'siteVisit': siteVisit,
        });
        await fetchData();
      };

  const handlePageChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    fetchData(false, params.page, params.pageSize);
  };

  const removeHandler = async (memberId) => {
    await deleteKeyOutputUsage(memberId);
    await fetchData();
  };

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getKeyOutputsUsed(page, size, searchTerm);
          const trainingBlocksData = result.data;

          if (!cancelRequest.current) {
            if (trainingBlocksData && Object.keys(trainingBlocksData).length > 0) {
              setPaginatedGoodsTypes(trainingBlocksData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  const updateDistrictDataAndDispatch = async (usageId, fields) => {
    try {
      let buffer = {
        ...usageBuffer
      };

      setUsageBuffer(buffer);

      const { data: data } = await updateKeyElementUsage(
          usageId,
          fields
      );

      if (!cancelRequest.current) {
        setUsageBuffer(data);
      }
    } catch (error) {
      setUsageBuffer(usageBuffer);
    }
  };

  useEffect(() => {
    fetchData(false, 0);
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isLoading || !paginatedTrainingBlocks) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary" />
            </div>
          </>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{}}>
            <TextField
                label="Output name, FB number"
                id="dropout-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <div style={{ flexGrow: 1, height: '88%', paddingBottom: '2.5rem' }}>
            <DataGrid
                sortingOrder={['asc', 'desc', null]}
                scrollbarSize={15}
                columnTypes={['string', 'number', 'date', 'dateTime']}
                columnBuffer={2}
                headerHeight={56}
                localeText={'enUS'}
                rowHeight={52}
                icons={[]}
                className={classes.dataGrid}
                rows={paginatedTrainingBlocks.content}
                columns={[
                  { field: 'keyElement', headerName: 'Key Output', flex: 1,
                    valueFormatter: (params) =>
                        (params.value.name), },
                  { field: 'household', headerName: 'Household FB number', flex: 1,
                    valueFormatter: (params) =>
                        (params.row.siteVisit && params.row.siteVisit.household ? params.row.siteVisit.household.fbNumber :''), },
                  { field: 'siteVisit', headerName: 'Visit date', flex: 1,
                    valueFormatter: (params) =>
                        params.value ? (formatTime(params.value.dateVisited)) : '', },
                  { field: 'dateCreated', headerName: 'Date created',
                    flex: 1,
                    renderCell: (params) =>
                        (formatTime(params.row.dateCreated))
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    disableClickEventBubbling: true,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params) => {
                      return <>
                          <AddKeyOutputUsage classes={classes} action={'edit'}
                                                       confirmationHandler={updateConfirmationHandler} />
                          <DataGridActionBar data={params.row} deleteHandler={removeHandler}>
                        </DataGridActionBar></>;
                    }
                  }
                ]}
                density="compact"
                pagination
                pageSize={paginatedTrainingBlocks.size}
                rowCount={paginatedTrainingBlocks.totalElements}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
          </div>
          <div hidden={authState && authState.role && authState.role === 'ROLE_READER'}>
            <Fab
                size="small"
                color="primary"
                hidden={!isLoadingDump}
                aria-label="add"
                onClick={() => fetchDump()}
                className={classes.fab}
                style={{position: 'fixed', marginBottom: '1rem'}}
            >
              <DownloadIcon/>
            </Fab>
          </div>
          <AddKeyOutputUsage
              confirmationHandler={addConfirmationHandler} />
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(KeyOutputsUsed);